import React from "react";

import { useTranslation } from "react-i18next";
import Button from "../elements/Button";
import Unsupported from "./Unsupported";
import { truncate } from "lodash";

export default function TemplateMessage(props) {
  const { t } = useTranslation();

  const { template, received, children, className = "" } = props;

  if (!template) return <Unsupported received={received} />;

  const { image_url, title, subtitle, buttons } = template;

  return (
    <div
      className={`w-72 h-auto rounded-3xl bg-white border select-none overflow-hidden flex flex-col ${className}`}
    >
      {image_url && (
        <div className="w-72 h-72 bg-light relative">
          <img
            src={image_url}
            className="w-full h-full object-cover object-center"
          />
        </div>
      )}
      <div className="p-4 space-y-2">
        <div className="flex items-center justify-between space-x-2">
          <div className="text-md font-medium">{title}</div>
        </div>
        {subtitle && <div className="text-sm text-neutral-500">{subtitle}</div>}
        {buttons.map((button, index) => (
          <Button
            key={index}
            label={truncate(button.title, { length: 20 })}
            className={"block w-full"}
            style={button.selected ? "success" : "soft"}
            href={button.url}
          />
        ))}
        {children}
      </div>
    </div>
  );
}
