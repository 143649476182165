import { EyeIcon } from "@heroicons/react/20/solid";
import { DateTime } from "luxon";
import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import InlineTooltip from "~/components/shared/InlineTooltip";
import Button from "~/components/elements/Button";
import { AutomationContext } from "~/contexts/automation-context";
import ExecutionStatusIcon from "./ExecutionStatusIcon";
import { UserContext } from "~/contexts/user-context";
import FolderTag from "../../../inbox/folders/FolderTag";
import { truncate } from "lodash";
import ActionTitle from "./ActionTitle";

export default function ActionExecution(props) {
  const { t } = useTranslation();

  const location = useLocation();

  const { execution, showMessageLink, showOptions, className = "" } = props;

  const [loading, setLoading] = useState(false);
  const [actionExecution, setActionExecution] = useState(execution);
  const { action } = actionExecution;

  const { organization } = useContext(UserContext);
  const { updateActionExecution, retryActionExecution } =
    useContext(AutomationContext);

  const folder = useMemo(
    () =>
      organization.folders?.find(
        (folder) => folder.id === action?.options?.folder_id,
      ),
    [action?.options?.folder_id, organization.folders],
  );

  const handleCancel = async (executionId) => {
    setLoading(true);
    const execution = await updateActionExecution(executionId, {
      status: "interrupted",
    });
    setActionExecution(execution);
    setLoading(false);
  };

  const handleRetry = async (executionId) => {
    setLoading(true);
    const execution = await retryActionExecution(executionId);
    setActionExecution(execution);
    setLoading(false);
  };

  return (
    <div className={`py-3 rounded text-black ${className}`}>
      <div className="flex items-center space-x-2">
        <div className="flex-grow flex items-center justify-start space-x-2">
          <div className="font-medium text-2sm">
            <ActionTitle action={action} />
          </div>
          {showOptions && (
            <>
              {/* Folder */}
              {folder ? <FolderTag folder={folder} /> : null}
              {/* Message */}
              {execution.message_content ? (
                <div className="text-message small">
                  {truncate(execution.message_content, { length: 50 })}
                </div>
              ) : null}
              {/* Delay */}
              {action?.options?.delay_duration &&
              action?.options?.delay_unit ? (
                <div className="text-2xs rounded-xl px-1.5 py-1 bg-yellow-500 text-dark-yellow-500">
                  {action?.options?.delay_duration}{" "}
                  {t(`time.${action?.options?.delay_unit}`)}
                </div>
              ) : null}
            </>
          )}
        </div>
        {actionExecution.message_id && showMessageLink ? (
          <Link
            to={location.pathname + "?messageId=" + actionExecution.message_id}
          >
            <EyeIcon className="w-4 text-neutral-300 opacity-75 hover:opacity-100" />
          </Link>
        ) : null}
        {["started", "scheduled", "waiting", "running"].includes(
          actionExecution.status,
        ) && (
          <Button
            size="extra-small"
            label={t("shared.cancel")}
            loading={loading}
            onClick={() => handleCancel(actionExecution.id)}
          />
        )}
        {["failed"].includes(actionExecution.status) && (
          <Button
            size="extra-small"
            label={t("shared.retry")}
            loading={loading}
            onClick={() => handleRetry(actionExecution.id)}
          />
        )}
        <InlineTooltip
          align="right"
          text={t(`automation.scenarios.executions.status_on`, {
            status: t(
              `automation.scenarios.executions.statuses.${actionExecution.status}`,
            ),
            date: DateTime.fromISO(
              actionExecution.scheduled_for || actionExecution.updated_at,
            ).toLocaleString(DateTime.DATETIME_SHORT),
          })}
        >
          <ExecutionStatusIcon status={actionExecution.status} />
        </InlineTooltip>
      </div>
      {actionExecution?.error_message ? (
        <div className="text-2xs text-neutral-600 rounded p-2 bg-neutral-200 mt-2 font-mono">
          {actionExecution?.error_message}
        </div>
      ) : null}
      {["http_request", "http_response"].map(
        (key) =>
          actionExecution?.details[key] && (
            <div className="space-y-1 mt-1">
              <div className="text-xs">
                {t(`automation.scenarios.action_executions.details.${key}`)}
              </div>
              <div className="text-3xs text-neutral-600 rounded p-2 bg-neutral-200 mt-2 font-mono break-words">
                {JSON.stringify(actionExecution?.details[key], null, 2)}
              </div>
            </div>
          ),
      )}
    </div>
  );
}
