import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { UserContext } from "~/contexts/user-context";

import Button from "../../elements/Button";
import PageHeader from "../../shared/PageHeader";
import FullScreen from "../../utils/FullScreen";
import FolderNotifications from "./FolderNotifications";
import ProfileSectionTitle from "../ProfileSectionTitle";
import PermissionCheck from "./PermissionCheck";

export default function FolderNotificationsPage(props) {
  const { back } = props;

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const { setCreateFolderDialog, organization } = useContext(UserContext);

  return (
    <FullScreen>
      <PageHeader
        title={t("notifications.folders_title")}
        back={back}
        className="mb-4"
      />
      <PermissionCheck />
      <ProfileSectionTitle label={t("notifications.folders.name")} />
      {organization.folders.length === 0 && (
        <div className="px-4">
          <div className="text-2xs text-dark-gray mb-4">
            {t("inbox.folders.manage_notifications_empty")}
          </div>
          <Button
            className="w-full"
            label={t("inbox.folders.create")}
            onClick={() => setCreateFolderDialog(true)}
          />
        </div>
      )}
      {organization.folders.map((folder) => (
        <FolderNotifications folder={folder} key={folder.id} />
      ))}
    </FullScreen>
  );
}
