import React, { useEffect, useMemo, useState } from "react";
import Calendar from "react-calendar";

import Button from "../elements/Button";
import { useTranslation } from "react-i18next";
import Modal from "../shared/Modal";
import Input from "../elements/Input";
import { set } from "lodash";

export default function CalendarModal(props) {
  const {
    title,
    confirmLabel,
    includeTime = false,
    minDate = null,
    initialDate,
    onConfirm,
    onClose,
  } = props;

  const { t, i18n } = useTranslation();

  const [value, setValue] = useState(
    initialDate ? new Date(initialDate) : new Date(),
  );

  const handleSelectDate = (date) => {
    date.setHours(value.getHours(), value.getMinutes());
    setValue(date);
  };

  const handleSelectTime = (time) => {
    const [hours, minutes] = time.split(":");
    const newDate = new Date(value);
    newDate.setHours(hours, minutes, 0);
    setValue(newDate);
  };

  const isValid = useMemo(() => {
    return !minDate || value > new Date(minDate);
  }, [value, minDate]);

  const handleSubmit = () => {
    if (!isValid) return;
    onConfirm(value.toISOString());
    onClose();
  };

  return (
    <Modal zIndex={1000} className="max-w-sm" onClose={onClose}>
      <div className={"w-full flex-grow space-y-3 p-3"}>
        <h2 className="text-center font-medium">{title}</h2>
        <Calendar
          minDate={new Date(minDate)}
          minDetail="month"
          value={value}
          onChange={handleSelectDate}
          locale={i18n.resolvedLanguage}
        />
        {includeTime && (
          <Input
            aria-label="Time"
            value={
              value.getHours().toString().padStart(2, "0") +
              ":" +
              value.getMinutes().toString().padStart(2, "0")
            }
            onChange={handleSelectTime}
            label={t("time.time")}
            type="time"
            step="900"
          />
        )}
        <div className="flex space-x-3">
          <Button
            label={t("shared.cancel")}
            className="flex-grow"
            onClick={onClose}
          />
          <Button
            style="primary"
            label={confirmLabel || t("shared.confirm")}
            className="flex-grow"
            onClick={handleSubmit}
            disabled={!isValid}
          />
        </div>
      </div>
    </Modal>
  );
}
