import React, { useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { SubscriptionContext } from "../contexts/subscription-context";
import headers from "../utils/headers";
import { UserContext } from "../contexts/user-context";

export default function SubscriptionProvider(props) {
  const { organizationId } = props;

  const { setOrganization } = useContext(UserContext);

  const [subscription, setSubscription] = useState({});

  const loadSubscription = async () => {
    const res = await axios.get("/api/subscription");
    setSubscription(res.data);
    return res.data;
  };

  const startSubscription = async () => {
    const res = await axios.post("/api/subscription", {}, headers());
    setSubscription(res.data.subscription);
    setOrganization(res.data.organization);
    return res.data;
  };

  const isFreePlan = useMemo(() => {
    return subscription.plan === "free";
  }, [subscription]);

  const limitReached = useMemo(() => {
    return subscription.contacts_activated >= subscription.current_tier_maximum;
  }, [subscription]);

  useEffect(loadSubscription, [organizationId]);

  const values = {
    subscription,
    loadSubscription,
    startSubscription,
    isFreePlan,
    limitReached,
  };

  return (
    <SubscriptionContext.Provider value={values}>
      {props.children}
    </SubscriptionContext.Provider>
  );
}
