import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../locales/i18n";

import { UserContext } from "~/contexts/user-context";

import FullScreen from "../utils/FullScreen";
import Calendar from "./Calendar";

import helpImageEn from "../../../assets/images/calendar_help_en.png";
import helpImageFr from "../../../assets/images/calendar_help_fr.png";

import { Link, useNavigate } from "react-router-dom";
import Tooltip from "../shared/Tooltip";
import PageTitle from "../shared/PageTitle";
import CalendarSettings from "./CalendarSettings";
import ScreenSlide from "../utils/ScreenSlide";
import EmptyInbox from "../inbox/EmptyInbox";
import Button from "../elements/Button";
import InboxModalError from "../errors/InboxModalError";

export default function CalendarPage(props) {
  const { t } = useTranslation();
  const locale = i18n.language.split("-")[0];

  const navigate = useNavigate();

  const { user } = useContext(UserContext);

  const [emptyState, setEmptyInbox] = useState(user.tooltips?.no_appointments);

  const { showSettings } = props;

  // Manage calendars
  const connectCalendarRef = useRef();

  return (
    <FullScreen className="flex flex-col overflow-hidden sm:!h-screen">
      <div className="p-4 flex items-center space-x-4 flex-shrink-0">
        <PageTitle text={t("calendar.title")} className="flex-grow" />
        <div ref={connectCalendarRef} className="relative">
          <Link
            to="/calendar/settings"
            className="rounded-lg py-1 font-medium text-2sm"
          >
            {t("shared.settings")}
          </Link>
          {user.tooltips?.connect_calendar && (
            <Tooltip
              text={t("calendar.calendars.connect_tooltip")}
              position="bottom"
              align="right"
              offset={-7}
              onClick={() => navigate(`/calendar/settings`)}
              parentRef={connectCalendarRef}
            />
          )}
        </div>
      </div>
      <Calendar eventsActive className="sm:pl-4" />
      {/* Settings */}
      <ScreenSlide visible={showSettings} origin="/calendar">
        <CalendarSettings />
      </ScreenSlide>
      {emptyState && !showSettings && (
        <InboxModalError onBackgroundClick={() => setEmptyInbox(false)}>
          <EmptyInbox
            emoji="🗓️"
            title={t("calendar.empty_state.title")}
            caption={t("calendar.empty_state.description")}
            className="!p-4"
            button={
              <div className="max-w-xs">
                <div className="px-6 py-6">
                  <img
                    src={locale == "fr" ? helpImageFr : helpImageEn}
                    className="rounded-md overflow-hidden"
                  />
                </div>
                <Button
                  className="w-full"
                  label={t("calendar.empty_state.button")}
                  to="/calendar/settings"
                />
              </div>
            }
          />
        </InboxModalError>
      )}
    </FullScreen>
  );
}
