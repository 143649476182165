import React from "react";
import { Handle, Position, useReactFlow, useNodeId } from "reactflow";
import { QuestionMarkCircleIcon, TrashIcon } from "@heroicons/react/20/solid";
import Button from "../../../elements/Button";
import classNames from "../../../../utils/classNames";

export default function CustomNode(props) {
  const {
    first = false,
    last = false,
    children,
    selected,
    help = null,
    noEdit,
  } = props;
  const id = useNodeId();
  const { deleteElements } = useReactFlow();

  return (
    <>
      {first ? null : (
        <Handle
          type="target"
          position={Position.Top}
          className="opacity-0"
          isConnectable={false}
        />
      )}
      <div className="w-[300px] flex flex-col items-center space-y-2 group">
        {children}
        {!noEdit && (
          <div
            className={classNames(
              "flex space-x-2 absolute top-1/2 -translate-y-1/2 left-full pl-14 py-4 !mt-0 transition-opacity opacity-0 group-hover:opacity-100",
              selected && "opacity-100",
            )}
          >
            {help && (
              <Button icon={QuestionMarkCircleIcon} href={help} size="small" />
            )}
            <Button
              icon={TrashIcon}
              onClick={() => deleteElements({ nodes: [{ id }] })}
              size="small"
            />
          </div>
        )}
      </div>
      {last ? null : (
        <Handle
          type="source"
          position={Position.Bottom}
          className="opacity-0"
          isConnectable={false}
        />
      )}
    </>
  );
}
