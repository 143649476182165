import React, { useContext } from "react";
import { UserContext } from "~/contexts/user-context";
import { truncate } from "lodash";

export default function MediaItem(props) {
  const { organization } = useContext(UserContext);

  const { media, onClick } = props;

  return (
    <div
      className="flex-shrink-0 w-48 p-2 rounded-lg hover:bg-lighter-gray transition-colors space-y-2 cursor-pointer"
      onClick={onClick}
    >
      <div className="flex items-center space-x-2">
        <img
          className={`w-6 h-6 rounded-full flex-shrink-0`}
          src={organization?.profile_picture}
        />
        <div className="text-xs font-medium">{organization?.username}</div>
      </div>
      <img
        className="block w-44 h-44 object-cover object-center"
        src={media.url}
      />
      {media.caption ? (
        <div className="text-2xs">
          <span className="font-medium">{organization?.username}</span>{" "}
          {truncate(media.caption, { length: 68 })}
        </div>
      ) : null}
    </div>
  );
}
