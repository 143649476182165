import axios from "axios";
import { delay } from "lodash";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "~/contexts/user-context";
import headers from "../../utils/headers";

import PageHeader from "../shared/PageHeader";
import FullScreen from "../utils/FullScreen";
import InlineInput from "../forms/InlineInput";

export default function BankDetails(props) {
  const { back } = props;

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const { user } = useContext(UserContext);

  const [details, setDetails] = useState({
    account_holder: user.account_holder || "",
    iban: user.iban || "",
    bic: user.bic || "",
  });

  const handleSave = () => {
    setLoading(true);
    axios
      .patch(`/api/user`, { user: { ...details } }, headers())
      .then((res) => {
        delay(() => setLoading(false), 300);
      });
  };

  return (
    <FullScreen>
      <PageHeader
        title={t("profile.bank_details")}
        action={{
          label: t("shared.save"),
          onClick: handleSave,
          loading,
        }}
        back={back}
        className="mb-8"
      />
      <InlineInput
        name="name"
        value={details.account_holder}
        onChange={(value) => setDetails({ ...details, account_holder: value })}
        label={t("profile.account_holder")}
        placeholder="Jane Doe"
        autoComplete="name"
        required
      />
      <InlineInput
        name="iban"
        value={details.iban}
        onChange={(value) => setDetails({ ...details, iban: value })}
        label="IBAN"
        placeholder="FR76 XXXX…"
        required
      />
      <InlineInput
        name="bic"
        value={details.bic}
        onChange={(value) => setDetails({ ...details, bic: value })}
        label="BIC"
        placeholder="CLRYXXXXX"
        className="mb-4"
      />
    </FullScreen>
  );
}
