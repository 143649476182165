import React from "react";
import { FolderIcon } from "@heroicons/react/20/solid";
import classNames from "../../../utils/classNames";

const sizes = {
  sm: "text-xs rounded-2md pl-1 pr-1.5 py-0.5",
  md: "text-xs rounded-2md pl-1.5 pr-2 py-1",
};

const iconSizes = {
  sm: "w-4 mr-0.5",
  md: "w-4 mr-1",
};

export default function FolderTag({ folder, size = "sm", onClick = () => {} }) {
  return (
    <div
      className={classNames(
        "flex items-center font-normal whitespace-nowrap flex-shrink-0 max-w-[160px] truncate",
        sizes[size],
        `text-${folder.color}-800`,
        `bg-${folder.color}-100`,
        onClick ? "cursor-pointer" : "",
      )}
      onClick={onClick}
    >
      <FolderIcon
        className={classNames(
          "flex-shrink-0",
          `text-${folder.color}-700`,
          iconSizes[size],
        )}
      />
      {folder.name}
    </div>
  );
}
