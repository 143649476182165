import React from "react";
import { useTranslation } from "react-i18next";
import { TrashIcon } from "@heroicons/react/20/solid";

import Input from "../../elements/Input";
import Button from "../../elements/Button";

export default function ConversionLinkParam(props) {
  const { param, onEdit, onRemove } = props;

  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-between space-x-2">
      <div className="grid grid-cols-2 gap-2">
        <Input
          type="text"
          value={param.key}
          onChange={(value) => onEdit({ ...param, key: value })}
          placeholder={t("conversion_links.params.key")}
          autoFocus={!param.id}
          required
        />
        <Input
          type="text"
          value={param.value}
          onChange={(value) => onEdit({ ...param, value: value })}
          placeholder={t("conversion_links.params.value")}
          required
        />
      </div>
      <Button icon={TrashIcon} size="small" onClick={onRemove} />
    </div>
  );
}
