import React from "react";
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";

import { useTranslation } from "react-i18next";
import paymentClassName from "../../utils/paymentClassName";
import Button from "../elements/Button";

export default function CheckoutLinkMessage(props) {
  const { t } = useTranslation();

  const { message, className = "" } = props;

  const { checkout_link } = message;

  const { products } = checkout_link;

  const product = products?.[0];

  return (
    <div
      className={`w-72 h-auto rounded-3xl bg-white border select-none overflow-hidden flex flex-col ${className}`}
    >
      <div className="w-72 h-72 bg-light-primary relative">
        <img
          src={product?.image}
          className="w-full h-full object-cover object-center"
        />
        {products.length > 1 && (
          <div className="absolute bottom-4 right-4 rounded-md bg-white py-1 px-2 font-medium text-2sm border">
            {t("checkout.message.products_count", {
              count: products.length - 1,
            })}
          </div>
        )}
      </div>
      <div className="p-4 space-y-2">
        <div className="text-md">{product?.title}</div>
        <div className="text-sm text-neutral-500">{product?.price}</div>
        <Button
          label={t("checkout.message.button")}
          className="w-full block"
          style="soft"
          href={product?.url}
        />
        {/* <div className="mt-4 flex items-center justify-between">
          <div
            className={`inline-block py-2 px-3 text-sm rounded-lg ${paymentClassName()}`}
          >
            Not purchased yet
          </div>
          <button className="block px-2 bg-neutral-200 rounded-lg hover:bg-neutral-300 transition-colors">
            <EllipsisHorizontalIcon className="w-5 h-5 text-neutral-400" />
          </button>
        </div> */}
      </div>
    </div>
  );
}
