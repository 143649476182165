import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ChevronRightIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/20/solid";

import Button from "../elements/Button";
import { useLocation, useNavigate } from "react-router-dom";
import ContactHeader from "../contacts/ContactHeader";
import ContactDetails from "../contacts/ContactDetails";
import BottomDrawer from "../shared/BottomDrawer";
import PaymentDetailsStatus from "../payments/PaymentDetailsStatus";
import ScreenSlide from "../utils/ScreenSlide";
import ConversationDetails from "../inbox/ConversationDetails";
import AppointmentEvent from "../messages/AppointmentEvent";
import { CalendarContext } from "~/contexts/calendar-context";
import { UIContext } from "~/contexts/ui-context";
import { sortBy } from "lodash";

import EditEventModal from "../calendar/EditEventModal";
import { UserContext } from "~/contexts/user-context";
import { DateTime } from "luxon";
import formatEventTime from "../../utils/formatEventTime";

export default function AppointmentDetails(props) {
  const { appointment, onClose } = props;

  const { payment } = appointment;

  const { showMenu } = useContext(UIContext);

  const { user } = useContext(UserContext);

  const {
    events,
    cancelAppointment,
    removeAppointmentPayment,
    confirmEvent,
    removeEvent,
  } = useContext(CalendarContext);

  const appointmentEvents = events.filter(
    (evt) => evt.appointment_id == appointment.id,
  );

  const urlSearchParams = new URLSearchParams(window.location.search);
  const eventId = urlSearchParams.get("eventId");

  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const showDetails = location.pathname.includes("/details");

  const isCancelled = appointment.status == "cancelled";
  const isAwaiting = appointment.status == "awaiting";
  const isConfirmed = appointment.status == "confirmed";

  const [editEvent, setEditEvent] = useState();

  const handleCancel = () => {
    cancelAppointment(appointment.id);
    navigate("/calendar");
  };

  const handleRemovePayment = async () => {
    removeAppointmentPayment(appointment.id);
  };

  const handleRemoveEvent = (eventId) => {
    removeEvent(eventId);
    navigate("/calendar");
  };

  const showEventMenu = (event) => {
    showMenu({
      title: formatEventTime(event),
      actions: [
        isConfirmed
          ? {
              label: t("calendar.details.edit_appointment"),
              action: () => setEditEvent(event),
            }
          : null,
        isConfirmed
          ? {
              label: t("calendar.message.cancel"),
              action: handleCancel,
              className: "text-red-500",
            }
          : null,
        isAwaiting
          ? {
              label: t("calendar.details.confirm_slot"),
              action: () => confirmEvent(appointment.id, event.id),
            }
          : null,
        isAwaiting && appointmentEvents.length > 1
          ? {
              label: t("calendar.details.remove_slot"),
              action: () => handleRemoveEvent(event.id),
            }
          : null,
      ],
    });
  };

  const showAppointmentMenu = () => {
    const confirmedEvent = appointmentEvents.find(
      (evt) => evt.status == "confirmed",
    );
    showMenu({
      title: t("calendar.details.edit_appointment"),
      actions: [
        isConfirmed && confirmedEvent
          ? {
              label: t("calendar.details.edit_appointment"),
              action: () => setEditEvent(confirmedEvent),
            }
          : null,
        payment
          ? {
              label: t("calendar.message.remove_payment"),
              action: handleRemovePayment,
            }
          : null,
        {
          label: t("calendar.message.cancel"),
          action: handleCancel,
          className: "text-red-500",
        },
      ],
    });
  };

  const goToConversation = () => {
    navigate(`/inbox/conversations/${appointment.conversation.id}`, {
      state: { from: location.pathname },
    });
  };

  const differentTimezone = appointmentEvents?.find(
    (evt) => user.time_zone !== evt.time_zone,
  )?.time_zone;

  return (
    <BottomDrawer
      title={t("calendar.details.title")}
      zIndex={100}
      onClose={onClose}
    >
      <div className="w-full flex flex-col overflow-auto justify-start items-stretch pb-4">
        <div className="p-4">
          <ContactHeader
            contact={appointment.conversation?.contact}
            conversation={appointment.conversation}
            showFolder="right"
          />
        </div>
        {appointment.conversation?.contact && (
          <>
            <ContactDetails contact={appointment.conversation?.contact} />
            <div
              className="h-13 flex-shrink-0 flex items-center justify-between space-x-4 px-4 border-b mb-2"
              onClick={() => navigate(`details`)}
            >
              <div className="text-2sm">
                {t("inbox.details.client_details")}
              </div>
              <ChevronRightIcon className="w-5 h-5" />
            </div>
          </>
        )}
        {differentTimezone && (
          <div className="px-4 pt-2">
            <div className="p-3 rounded-lg bg-yellow-500 text-dark-yellow-500 text-2sm">
              {t("calendar.details.timezone_warning", {
                zone: differentTimezone,
              })}
            </div>
          </div>
        )}
        <div className="flex flex-col space-y-2 p-4">
          {sortBy(appointmentEvents, "start_time").map((event) => (
            <AppointmentEvent
              key={event.id}
              appointmentCancelled={isCancelled}
              onClick={() => showEventMenu(event)}
              event={{
                ...event,
                status:
                  isAwaiting && event.id == eventId ? "selected" : event.status,
              }}
            />
          ))}
        </div>
        <div className="flex justify-between items-center px-4">
          <div className="text-2sm text-dark-gray">
            {t("calendar.details.sent_on", {
              date: DateTime.fromISO(appointment.created_at).toLocaleString(
                DateTime.DATE_FULL,
              ),
            })}
          </div>
          <button
            className="block px-2 bg-light-gray rounded-lg transition-colors"
            onClick={showAppointmentMenu}
          >
            <EllipsisHorizontalIcon className="w-5 h-5 text-darker-gray" />
          </button>
        </div>
        {payment ? (
          <PaymentDetailsStatus
            payment={payment}
            canRemove={isAwaiting}
            action={{
              label: t("calendar.details.remove_payment"),
              action: () => removeAppointmentPayment(appointment.id),
            }}
          />
        ) : null}
        {appointment.conversation && (
          <div className="p-4 pb-4">
            <Button
              className="w-full"
              label={t("payments.details.goToConversation")}
              onClick={goToConversation}
            />
          </div>
        )}
      </div>
      <ScreenSlide
        visible={showDetails}
        origin={`/calendar/${appointment.id}`}
        hideTabBar
        stackDepth={2}
      >
        <ConversationDetails conversation={appointment.conversation} />
      </ScreenSlide>
      {editEvent && (
        <EditEventModal event={editEvent} onClose={() => setEditEvent(false)} />
      )}
    </BottomDrawer>
  );
}
