import { ChevronDownIcon } from "@heroicons/react/20/solid";
import React, { useContext, useEffect, useRef, useState } from "react";
import { MIN_PAYMENT_AMOUNT } from "../../constants";
import formatAmount from "../../utils/formatAmount";
import calculateFee from "../../utils/calculateFee";
import { useTranslation } from "react-i18next";
import { UserContext } from "~/contexts/user-context";
import currencySymbol from "../../utils/currencySymbol";
import PaymentFeesDetails from "./PaymentFeesDetails";

export default function PaymentAmountForm(props) {
  const { amount, setAmount, autoFocus = false } = props;

  const { t } = useTranslation();

  const { user, organization } = useContext(UserContext);

  const amountRef = useRef(null);

  const focusInput = () => {
    amountRef.current.focus();
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    if (value === "") {
      setAmount("");
    } else if (value.match(/^\d*$/)) {
      setAmount(value);
    } else {
      setAmount(amount);
    }
  };

  useEffect(() => {
    if (autoFocus) focusInput();
  }, []);

  // Fee details

  const [finalAmount, fee, stripeFee] = calculateFee(amount, user.fee_ratio);

  const [showFeeDetails, setShowFeeDetails] = useState(false);

  return (
    <div className="w-full flex flex-col items-center">
      <div
        className="flex justify-center space-x-2 py-2 px-3 bg-light-primary rounded-lg text-2xl mb-3"
        onClick={focusInput}
      >
        <input
          type="text"
          inputMode="numeric"
          className="outline-none border-none focus:border-none focus:ring-0 p-0 bg-transparent text-lg text-right font-mono [max-width:200px]"
          ref={amountRef}
          style={{ width: (amount?.length || 1) * 15 }}
          placeholder="0"
          value={amount}
          onChange={handleInputChange}
        />
        <div>{currencySymbol(organization.currency)}</div>
      </div>
      {amount >= MIN_PAYMENT_AMOUNT ? (
        <div className="w-full flex flex-col items-center space-y-2">
          <div
            className="text-neutral-500 text-sm"
            onClick={() => setShowFeeDetails(!showFeeDetails)}
          >
            {t("payments.modal.service_fees", {
              total: formatAmount(finalAmount, organization.currency),
            })}
            <ChevronDownIcon
              className={`inline-block w-4 h-4 ml-1 mb-0.25 text-neutral-400 ${
                showFeeDetails && "rotate-180"
              }`}
            />
          </div>
          {showFeeDetails && (
            <PaymentFeesDetails
              amount={finalAmount}
              total={amount}
              fee={fee}
              stripeFee={stripeFee}
              currency={organization.currency}
              estimated
              className="w-full"
            />
          )}
        </div>
      ) : (
        <div className="text-neutral-500 text-sm">
          {formatAmount(MIN_PAYMENT_AMOUNT, organization.currency)} min
        </div>
      )}
    </div>
  );
}
