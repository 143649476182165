import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useTranslation } from "react-i18next";

import ReactFlow, {
  Background,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "reactflow";
import "reactflow/dist/style.css";

import { DirtyContext } from "~/contexts/dirty-context";
import { FlowContext } from "~/contexts/flow-context";
import ActionTypeMenu from "./ActionTypeMenu";
import CustomEdge from "./CustomEdge";
import generateNodesAndEdges from "./generateNodesAndEdges";
import AddActionNode from "./nodes/AddActionNode";
import AudioMessageNode from "./nodes/AudioMessageNode";
import CollectEmailNode from "./nodes/CollectEmailNode";
import CommentNode from "./nodes/CommentNode";
import ConversionLinkNode from "./nodes/ConversionLinkNode";
import DelayNode from "./nodes/DelayNode";
import EngagementConditionNode from "./nodes/EngagementConditionNode";
import FolderNode from "./nodes/FolderNode";
import HttpRequestNode from "./nodes/HttpRequestNode";
import LikeNode from "./nodes/LikeNode";
import MediaMessageNode from "./nodes/MediaMessageNode";
import MessageNode from "./nodes/MessageNode";
import PaymentLinkNode from "./nodes/PaymentLinkNode";
import ScenarioConditionNode from "./nodes/ScenarioConditionNode";
import ShareMediaNode from "./nodes/ShareMediaNode";
import ShopifyNode from "./nodes/ShopifyNode";
import TriggerNode from "./nodes/TriggerNode";
import WaitForReplyNode from "./nodes/WaitForReplyNode";
import SurveyNode from "./nodes/SurveyNode";

export default function Flow(props) {
  const { t } = useTranslation();

  const {
    hasTriggers = false,
    triggers,
    setTriggers,
    actions = [],
    setActions = () => {},
    children,
  } = props;

  const { setDirty } = useContext(DirtyContext);

  // Triggers

  const addTrigger = (trigger) => {
    const newTrigger = {
      ...trigger,
      id: `temp_${triggers?.length + 1}`,
    };
    setTriggers((triggers) => [...triggers, newTrigger]);
    setDirty(true);
  };

  const editTrigger = (id, newTrigger) => {
    setTriggers((triggers) =>
      triggers?.map((trigger) => {
        if (trigger.id == id) {
          return { ...trigger, ...newTrigger };
        } else {
          return trigger;
        }
      }),
    );
    setDirty(true);
  };

  const editTriggerOptions = (id, newOptions) => {
    setTriggers((triggers) =>
      triggers?.map((trigger) => {
        if (trigger.id == id) {
          return { ...trigger, options: { ...trigger.options, ...newOptions } };
        } else {
          return trigger;
        }
      }),
    );
    setDirty(true);
  };

  const removeTrigger = (id) => {
    if (id.toString().includes("temp_")) {
      setTriggers((triggers) =>
        triggers?.filter((trigger) => trigger.id != id),
      );
    } else {
      setTriggers((triggers) =>
        triggers?.map((trigger) => {
          if (trigger.id == id) {
            return { ...trigger, _destroy: true };
          } else {
            return trigger;
          }
        }),
      );
    }
    setDirty(true);
  };

  // Actions

  const updateParentIds = (oldParentId, newParentId) => {
    setActions((actions) =>
      actions.map((action) =>
        action.parent_id == oldParentId
          ? { ...action, parent_id: newParentId }
          : action,
      ),
    );
  };

  const addAction = (action, parentId) => {
    if (parentId == "trigger") parentId = null;
    const newAction = {
      ...action,
      parent_id: parentId,
      id: `temp_${actions.length + 1}`,
    };
    updateParentIds(parentId, newAction.id);
    setActions((actions) => [...actions, newAction]);
    setDirty(true);
  };

  const editAction = (id, newAction, dirty = true) => {
    setActions((actions) =>
      actions.map((action) => {
        if (action.id == id) {
          return { ...action, ...newAction };
        } else {
          return action;
        }
      }),
    );
    dirty && setDirty(true);
  };

  const removeAction = (id) => {
    const removedAction = actions.find((action) => action.id == id);
    if (id.toString().includes("temp_")) {
      setActions((actions) => actions.filter((action) => action.id != id));
    } else {
      setActions((actions) =>
        actions.map((action) => {
          if (action.id == id) {
            return { ...action, _destroy: true, parent_id: null };
          } else {
            return action;
          }
        }),
      );
    }
    updateParentIds(id, removedAction.parent_id);
    setDirty(true);
  };

  // Flow
  const { fitView } = useReactFlow();

  // Nodes

  const [nodes, setNodes, onNodesChange] = useNodesState(
    generateNodesAndEdges(hasTriggers, actions, triggers).nodes,
  );
  const [edges, setEdges, onEdgesChange] = useEdgesState(
    generateNodesAndEdges(hasTriggers, actions, triggers).edges,
  );
  useEffect(() => {
    const { nodes, edges } = generateNodesAndEdges(
      hasTriggers,
      actions,
      triggers,
    );
    setNodes(nodes);
    setEdges(edges);
  }, [hasTriggers, actions, triggers]);

  const handleDoubleClick = (evt, node) => {
    if (["message", "delay", "comment"].includes(node.type)) {
      editAction(node.data.id, { editable: true });
    }
  };

  const handleNodesDelete = (nodes) => {
    nodes.forEach((node) => removeAction(node.data.id));
  };

  const handleEdgeClick = (evt, edge) => {
    showActionTypeMenu({
      onSubmit: (action) => addAction(action, edge.source),
    });
  };

  const nodeTypes = useMemo(
    () => ({
      trigger: TriggerNode,
      message: MessageNode,
      audio_message: AudioMessageNode,
      media_message: MediaMessageNode,
      share_media: ShareMediaNode,
      comment: CommentNode,
      like: LikeNode,
      delay: DelayNode,
      folder: FolderNode,
      survey: SurveyNode,
      wait_for_reply: WaitForReplyNode,
      engagement_condition: EngagementConditionNode,
      scenario_condition: ScenarioConditionNode,
      conversion_link: ConversionLinkNode,
      checkout_link: ShopifyNode,
      collect_email: CollectEmailNode,
      payment_link: PaymentLinkNode,
      http_request: HttpRequestNode,
      newAction: AddActionNode,
    }),
    [],
  );

  const edgeTypes = useMemo(
    () => ({
      custom: CustomEdge,
    }),
    [],
  );

  // Fit view

  const fixedFitView = useCallback(() => {
    fitView({ minZoom: 1, maxZoom: 1 });
  }, [fitView]);

  useEffect(() => {
    window.addEventListener("resize", fixedFitView);
    return () => window.removeEventListener("resize", fixedFitView);
  }, [fitView]);

  const [addActionMenu, showActionTypeMenu] = useState(false);

  const contextValues = {
    hasTriggers,
    triggers,
    addTrigger,
    editTrigger,
    editTriggerOptions,
    removeTrigger,
    showActionTypeMenu,
    actions,
    addAction,
    editAction,
    removeAction,
  };

  return (
    <FlowContext.Provider value={contextValues}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodesDraggable={false}
        // panOnDrag={false}
        edgesFocusable={false}
        selectionMode="partial"
        panOnScroll={true}
        panOnScrollMode="vertical"
        zoomOnDoubleClick={false}
        zoomOnPinch={false}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        connectionRadius={0}
        onNodeDoubleClick={handleDoubleClick}
        onNodesDelete={handleNodesDelete}
        onEdgeClick={handleEdgeClick}
        fitView
        fitViewOptions={{
          minZoom: 1,
          maxZoom: 1,
        }}
      >
        <Background color="#999" variant="dots" />
        {children}
      </ReactFlow>
      {addActionMenu && (
        <ActionTypeMenu
          onSubmit={addActionMenu.onSubmit || addAction}
          onClose={() => showActionTypeMenu(false)}
        />
      )}
    </FlowContext.Provider>
  );
}
