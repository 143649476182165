import React from "react";
import { useTranslation } from "react-i18next";
import ContactsTable from "./ContactsTable";
import FullScreen from "../utils/FullScreen";
import PageTitle from "../shared/PageTitle";
import { useNavigate, useParams } from "react-router-dom";
import Conversation from "../inbox/Conversation";

export default function ContactsPage() {
  const { t } = useTranslation();

  const { conversationId } = useParams();
  const navigate = useNavigate();

  return (
    <FullScreen className="relative sm:!h-screen flex-grow flex flex-col overflow-hidden px-0 sm:px-6 lg:px-8 py-3 sm:pt-6 ">
      <PageTitle text={t("contacts.title")} className="px-3 sm:px-0" />
      <ContactsTable />
      {conversationId && (
        <div className="absolute z-30 inset-0 flex justify-end">
          <div
            className="absolute inset-0 bg-black opacity-20"
            onClick={() => navigate("/contacts")}
          />
          <div className="relative w-full max-w-[800px] h-full sm:ml-20 bg-white border-l shadow-lg">
            <Conversation conversationId={conversationId} />
          </div>
        </div>
      )}
    </FullScreen>
  );
}
