import React from "react";
import { useTranslation } from "react-i18next";
import formatAmount from "../../utils/formatAmount";

export default function ShopifyProductCard(props) {
  const { product, selected, onClick, discount, showOptions = false } = props;

  const { t } = useTranslation();

  const discountedPrice = discount
    ? (parseFloat(product.price.amount) * (100 - discountAmount)) / 100
    : null;

  return (
    <div
      className={`w-44 flex-shrink-0 rounded-lg overflow-hidden cursor-pointer ${
        selected
          ? "ring-2 ring-primary"
          : "ring-1 ring-neutral-200 hover:ring-primary"
      }`}
      onClick={onClick}
    >
      <div className="w-full relative">
        <img
          src={product?.image || "/product_placeholder.png"}
          className="w-full aspect-square object-cover"
        />
        {discount ? (
          <div className="absolute bottom-2 right-2 px-1.5 py-0.5 bg-white rounded text-2sm font-medium opacity-90">
            -{discount}%
          </div>
        ) : null}
      </div>
      <div className="p-2 flex flex-col space-y-1">
        <div className="text-2sm font-medium">{product.title}</div>
        <div className="text-2sm text-dark-gray">
          {discount ? (
            <>
              <span className="line-through text-2xs">
                {formatAmount(product.price.amount, product.price.currency)}
              </span>
              &nbsp;
              <span>
                {formatAmount(discountedPrice, product.price.currency)}
              </span>
            </>
          ) : (
            formatAmount(product.price.amount, product.price.currency)
          )}
        </div>
      </div>
      {showOptions && product.options.length ? (
        <div className="flex flex-col space-y-2 pb-2">
          {product.options.map((option) => (
            <div className="space-y-1">
              <div className="text-4xs text-darker-gray uppercase font-medium px-2">
                {option.name}
              </div>
              <div className="flex flex-nowrap overflow-hidden gap-1 px-2">
                {option.values.map((value) => (
                  <div className="px-1 py-0.5 text-2xs rounded bg-neutral-200 inline-block whitespace-nowrap">
                    {value}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}
