import React from "react";
import { useTranslation } from "react-i18next";
import GrowthRate from "../../utils/GrowthRate";
import InlineTooltip from "../../shared/InlineTooltip";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import Loader from "../../utils/Loader";
import SkeletonLoader from "../../utils/SkeletonLoader";

export default function ScenarioMetrics(props) {
  const { t } = useTranslation();

  const { scenario } = props;

  const { metrics, loaded } = scenario;

  const stats = [
    {
      title: t("automation.scenarios.metrics.last_7_days"),
      value: metrics?.executions?.last_7_days,
      unit: t("automation.scenarios.metrics.executions", {
        count: metrics?.executions?.last_7_days,
      }),
      trend: metrics?.executions?.trend,
    },
    {
      title: t("automation.scenarios.metrics.total"),
      value: metrics?.executions?.total,
      unit: t("automation.scenarios.metrics.executions", {
        count: metrics?.executions?.total,
      }),
    },
    {
      title: t("automation.scenarios.metrics.last_7_days"),
      value: metrics?.conversions?.last_7_days,
      unit: t("automation.scenarios.metrics.conversions", {
        count: metrics?.conversions?.last_7_days,
      }),
      trend: metrics?.conversions?.trend,
      help: t("automation.scenarios.metrics.conversions_help"),
    },
    {
      title: t("automation.scenarios.metrics.total"),
      value: metrics?.conversions?.total,
      unit: t("automation.scenarios.metrics.conversions", {
        count: metrics?.conversions?.total,
      }),
      help: t("automation.scenarios.metrics.conversions_help"),
    },
  ];

  return (
    <dl className="w-full grid grid-cols-1 divide-x sm:grid-cols-2 lg:grid-cols-4 border-b">
      {stats.map((stat, index) => (
        <div key={index} className="items-baseline space-y-2 px-4 py-4 sm:px-6">
          <div className="flex justify-between space-x-4">
            <dt className="text-sm font-medium leading-6 text-neutral-500">
              {stat.title}
            </dt>
            {loaded ? (
              <dd className="text-xs font-medium text-right">
                <GrowthRate value={stat.trend} />
              </dd>
            ) : (
              <SkeletonLoader width={40} height={24} />
            )}
          </div>
          <div className="flex items-end justify-between space-x-4">
            {loaded ? (
              <dd className="flex-none text-2xl font-medium leading-10 text-neutral-900">
                {stat.value}
                <span className="ml-1 text-sm">{stat.unit}</span>
              </dd>
            ) : (
              <SkeletonLoader width={100} height={40} />
            )}
            <dd className="text-right pt-4">
              {stat.help && (
                <InlineTooltip text={stat.help} align="right" position="bottom">
                  <QuestionMarkCircleIcon className="h-4 text-neutral-400" />
                </InlineTooltip>
              )}
            </dd>
          </div>
        </div>
      ))}
    </dl>
  );
}
