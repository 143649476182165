import React, { useEffect, useRef, useState } from "react";
import { PlayCircleIcon, StopCircleIcon } from "@heroicons/react/24/solid";
import Loader from "../utils/Loader";
import renderTime from "../../utils/renderTime";
import { isNumber } from "lodash";

export default function AudioMessage(props) {
  const { received, url } = props;

  const bubbleClass = received ? "bg-white border" : "bg-neutral-100";

  const [duration, setDuration] = useState(null);
  const [time, setTime] = useState(null);
  const [canPlay, setCanPlay] = useState(true);
  const [playing, setPlaying] = useState(false);

  const audioRef = useRef(null);

  useEffect(() => {
    if (audioRef.current || !url) return;
    audioRef.current = new Audio(url);
    audioRef.current.addEventListener("loadedmetadata", (evt) => {
      setDuration(evt.target.duration);
      setCanPlay(true);
    });
    audioRef.current.addEventListener("timeupdate", (evt) =>
      setTime(evt.target.currentTime),
    );
    audioRef.current.addEventListener("playing", () => setPlaying(true));
    audioRef.current.addEventListener("pause", () => setPlaying(false));
    audioRef.current.addEventListener("ended", () => {
      audioRef.current.currentTime = 0;
      setPlaying(false);
    });
  }, [url]);

  const togglePlay = () => {
    if (!audioRef.current) return;
    playing ? audioRef.current.pause() : audioRef.current.play();
  };

  // Progress bar
  const mouseDown = useRef(false);
  const wasPlaying = useRef(false);
  const getPosition = (evt) => {
    const maxWidth = evt.target.offsetWidth;
    const rect = evt.target.getBoundingClientRect();
    const x = evt.clientX - rect.left;
    return x / maxWidth;
  };
  const handleMouseDown = (evt) => {
    mouseDown.current = true;
    wasPlaying.current = playing;
    audioRef.current.pause();
    const position = getPosition(evt);
    audioRef.current.currentTime = position * duration;
  };
  const handleMouseMove = (evt) => {
    if (!mouseDown.current) return;
    const position = getPosition(evt);
    audioRef.current.currentTime = position * duration;
  };
  const handleMouseUp = (evt) => {
    if (!mouseDown.current) return;
    const position = getPosition(evt);
    audioRef.current.currentTime = position * duration;
    if (wasPlaying.current) audioRef.current.play();
    mouseDown.current = false;
    wasPlaying.current = false;
  };

  return (
    <div
      className={`rounded-3xl px-4 h-16 py-4 w-72 flex items-center justify-between space-x-3 text-black ${bubbleClass}`}
    >
      {canPlay ? (
        playing ? (
          <StopCircleIcon
            className="w-6 flex-shrink-0 cursor-pointer"
            onClick={togglePlay}
          />
        ) : (
          <PlayCircleIcon
            className="w-6 flex-shrink-0 cursor-pointer"
            onClick={togglePlay}
          />
        )
      ) : (
        <div className="w-6 h-6 flex items-center justify-center flex-shrink-0">
          <Loader stroke="#000" width={20} strokeWidth={10} />
        </div>
      )}
      <div className="flex-grow h-full flex items-center">
        <div className="w-full h-2 bg-neutral-300 rounded-full pointer-events-none overflow-hidden">
          <div
            className="h-full bg-black pointer-events-none w-0"
            style={{
              width: `${(time / duration) * 100}%`,
            }}
          />
        </div>
      </div>
      <div className="text-2sm">{renderTime(playing ? time : duration)}</div>
    </div>
  );
}
