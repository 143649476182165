import React from "react";
import Loader from "../utils/Loader";
import Netsuke from "./Netsuke";

export default function Loading({ text }) {
  return (
    <div
      className="fixed inset-0 flex flex-col space-y-8 justify-center items-center bg-white"
      style={{
        zIndex: 2000,
      }}
    >
      <Netsuke style="curious" />
      <Loader width={60} strokeWidth={6} />
      {text && <div className="mt-4 text-sm text-neutral-800">{text}</div>}
    </div>
  );
}
