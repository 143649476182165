import React from "react";

import loginInstagram from "../../../assets/images/login_instagram.png";
import { useTranslation } from "react-i18next";
import FacebookIcon from "../shared/icons/FacebookIcon";
import Button from "../elements/Button";

export default function NoInstagram() {
  const { t } = useTranslation();

  return (
    <div className="space-y-6">
      <h2 className="text-lg font-semibold mb-4">
        {t("inbox.permissions.no_instagram.title")}
      </h2>
      <div className="max-w-sm">
        <p className="text-sm mb-6">
          {t("inbox.permissions.no_instagram.text")}
        </p>
        <div className="px-4 mb-6">
          <img src={loginInstagram} className="shadow-md rounded" />
        </div>

        <Button
          href="/users/auth/instagram"
          label={t("inbox.permissions.retry")}
          style="inverse"
          icon={FacebookIcon}
          className="w-full"
        />
      </div>
    </div>
  );
}
