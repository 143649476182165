import React from "react";
import { MagnifyingGlassIcon, XCircleIcon } from "@heroicons/react/20/solid";
import Loader from "../utils/Loader";
import { useTranslation } from "react-i18next";

export default function SearchField(props) {
  const { t } = useTranslation();

  const {
    loading = false,
    showCancel = false,
    placeholder,
    value = "",
    onChange,
    onFocus,
    onClear,
    onCancel,
    className = "",
  } = props;

  const handleClear = () => {
    onChange("");
    onClear && onClear();
  };

  return (
    <div className={`flex-grow flex items-center ${className}`}>
      <div className="rounded-xl bg-neutral-100 flex items-center flex-grow pl-3">
        <div className="w-5 flex items-center justify-center flex-shrink-0">
          {loading ? (
            <Loader stroke="#8D8D8D" width={22} strokeWidth={6} />
          ) : (
            <MagnifyingGlassIcon className="w-5 text-dark-gray" />
          )}
        </div>
        <input
          type="text"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="none"
          spellCheck="false"
          placeholder={placeholder || t("shared.search")}
          className="w-full border-none bg-transparent focus:outline-none py-2 px-2 focus:ring-0"
          value={value}
          onChange={(evt) => onChange(evt.target.value)}
          onFocus={onFocus}
        />
        {value.length ? (
          <div
            className="h-8 flex items-center justify-center px-2 flex-shrink-0"
            onClick={handleClear}
          >
            <XCircleIcon className="w-5 h-5 text-neutral-400" />
          </div>
        ) : null}
      </div>
      {showCancel && (
        <div className="pl-3" onClick={onCancel}>
          {t("shared.cancel")}
        </div>
      )}
    </div>
  );
}
