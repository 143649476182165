import React from "react";
import { useTranslation } from "react-i18next";
import BalanceCard from "./BalanceCard";
import PageHeader from "../shared/PageHeader";
import FullScreen from "../utils/FullScreen";

export default function BalancePage(props) {
  const { back } = props;

  const { t } = useTranslation();

  return (
    <FullScreen className="sm:max-w-lg sm:border-r">
      <PageHeader title={t("profile.balance")} back={back} />
      <BalanceCard />
    </FullScreen>
  );
}
