import React, { useContext } from "react";

import { UserContext } from "~/contexts/user-context";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PaymentsContext } from "~/contexts/payments-context";
import PaymentLinkFullCard from "./PaymentLinkFullCard";

export default function PaymentLinkMessage(props) {
  const { message } = props;

  const { payments, showPaymentMenu, archivePayments } =
    useContext(PaymentsContext);

  const payment = payments.find((p) => p.id == message.payment_id);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { organization } = useContext(UserContext);

  const status = payment?.status;

  const total = parseFloat(payment?.total);

  return (
    <PaymentLinkFullCard
      organization={organization}
      total={total}
      currency={payment?.currency}
      disableStatusInfo={message.pending}
      handleMenu={() =>
        showPaymentMenu(payment, {
          label: t("payments.actions.view_details"),
          action: () => navigate(`/payments/${payment.id}`),
        })
      }
      status={status}
    />
  );
}
