import React, { useContext } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import { DocumentArrowDownIcon, EyeIcon } from "@heroicons/react/20/solid";

import Button from "../elements/Button";
import MenuItem from "../profile/MenuItem";

import ScreenSlide from "../utils/ScreenSlide";
import ManageFolders from "./ManageFolders";
import SpamMessages from "./SpamMessages";
import { InboxContext } from "~/contexts/inbox-context";
import { useTranslation } from "react-i18next";
import { isApp } from "../../utils/environment";
import { UserContext } from "~/contexts/user-context";
import FullScreen from "../utils/FullScreen";
import PageHeader from "../shared/PageHeader";
import ProfileSectionTitle from "../profile/ProfileSectionTitle";
import reactNativeMessage from "../../utils/reactNativeMessage";
import { DateTime } from "luxon";

export default function InboxSettings(props) {
  const { back } = props;

  const { t } = useTranslation();

  const { organization } = useContext(UserContext);
  const { conversations, markAllRead } = useContext(InboxContext);
  const spamConversations = conversations.filter((c) => c.spam == true);

  const handleSync = () => {
    axios.get("/api/inbox/conversations/sync");
  };

  const { path } = useParams();

  const downloadContacts = () => {
    reactNativeMessage({
      download: {
        url: document.location.origin + "/export_contacts",
        filename: `inro_contacts_${DateTime.now().toISODate()}.xlsx`,
      },
    });
  };

  const pages = {
    folders: <ManageFolders />,
    spam: <SpamMessages />,
  };

  return (
    <FullScreen className="sm:h-full sm:flex sm:flex-col">
      <PageHeader
        title={t("inbox.settings.title")}
        back={back}
        className="mb-4"
      />

      <div className="flex-grow overflow-auto pb-8 hide-scrollbar">
        <MenuItem
          label={t("inbox.settings.manage_folders")}
          to="/inbox/settings/folders"
        />
        <MenuItem
          label={t("inbox.settings.folder_notifications")}
          to="/profile/notifications"
        />
        <MenuItem
          label={t("inbox.settings.spam_messages")}
          count={spamConversations.length}
          to="/inbox/settings/spam"
          className="border-b"
        />
        <div className="p-4">
          <Button
            className="w-full"
            label={t("inbox.settings.mark_all_read")}
            onClick={markAllRead}
            icon={EyeIcon}
          />
        </div>

        <ProfileSectionTitle
          label={t("inbox.settings.export_title")}
          subtitle={t("inbox.settings.export_subtitle")}
        />
        <div className="px-4 py-2 pb-12">
          {isApp ? (
            <Button
              onClick={downloadContacts}
              label={t("inbox.settings.export_cta")}
              className="w-full"
              icon={DocumentArrowDownIcon}
            />
          ) : (
            <Button
              href="/export_contacts"
              label={t("inbox.settings.export_cta")}
              className="w-full"
              icon={DocumentArrowDownIcon}
            />
          )}
        </div>

        <div className="mt-8 p-4">
          <Button
            label={t("inbox.settings.sync_inbox")}
            className="w-full"
            loading={!organization.instagram_synced}
            onClick={handleSync}
          />
        </div>
      </div>
      {/* Pages */}
      <ScreenSlide
        visible={!!pages[path]}
        origin="/inbox/settings"
        stackDepth={2}
      >
        {pages[path]}
      </ScreenSlide>
    </FullScreen>
  );
}
