export default function validateTrigger(trigger) {
  if (trigger?._destroy) return true;

  switch (trigger?.trigger_type) {
    case "referral_ad":
      return {
        keywords: trigger.options.keywords?.length > 0,
        specific: trigger.options.referral_ad_id?.length > 0,
        any: true,
      }[trigger.options.referral_ad_option];
    case "referral":
      return {
        ref_code:
          trigger.options.referral_code?.length > 0 &&
          trigger.options.icebreaker.length > 0,
        any: trigger.options.icebreaker.length > 0,
      }[trigger.options.referral_option];
    default:
      return true;
  }
}

// messages
// referral_ad
// story_reply
// story_mention
// comment
// mention_comment
// mention_media
// outgoing_message
// referral
