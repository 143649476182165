import { ArrowPathIcon } from "@heroicons/react/20/solid";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "~/contexts/user-context";
import Button from "../elements/Button";
import PageHeader from "../shared/PageHeader";
import FullScreen from "../utils/FullScreen";
import LabeledInput from "../forms/LabeledInput";
import { UIContext } from "~/contexts/ui-context";
import axios from "axios";
import headers from "../../utils/headers";
import { isEmpty, reverse, sortBy } from "lodash";
import ProfileSectionTitle from "./ProfileSectionTitle";
import moment from "moment";

export default function PromoCodesPage(props) {
  const { back } = props;

  const { t } = useTranslation();

  const { organization, activatePromoCode } = useContext(UserContext);
  const { showDialog } = useContext(UIContext);

  const showCodeDialog = () => {
    showDialog({
      label: t("promo_codes.label"),
      placeholder: "ABC123",
      forceUppercase: true,
      onSubmit: activatePromoCode,
    });
  };

  return (
    <FullScreen>
      <PageHeader title={t("promo_codes.title")} back={back} className="mb-4" />
      <div className="p-4">
        <Button
          onClick={showCodeDialog}
          label={t("promo_codes.activate")}
          className="w-full"
        />
      </div>
      <ProfileSectionTitle label={t("promo_codes.activated_codes")} />
      {reverse(sortBy(organization.promo_codes, "starts_on")).map((code) => (
        <div
          className="px-4 py-3 border-b flex justify-between items-center"
          key={code.id}
        >
          <div className={`${code.active ? "opacity-100" : "opacity-60"}`}>
            <div className="font-medium text-base">{code.name}</div>
            {code.promo_fee && (
              <div className="text-2sm text-dark-gray mt-0.5">
                {t("promo_codes.custom_fee", { fee: code.promo_fee * 100 })}
              </div>
            )}
          </div>
          {code.active ? (
            code.ends_on ? (
              <div className="px-2 py-1 rounded-md bg-light-green text-dark-green font-medium text-2xs">
                {t("promo_codes.active_until", {
                  date: moment(code.ends_on).format("LL"),
                })}
              </div>
            ) : (
              <div className="px-2 py-1 rounded-md bg-light-green text-dark-green font-medium text-2xs">
                {t("shared.active")}
              </div>
            )
          ) : code.future ? (
            <div className="px-2 py-1 rounded-md text-darker-gray bg-light-gray font-medium text-2xs">
              {t("promo_codes.starts_on", {
                date: moment(code.starts_on).format("LL"),
              })}
            </div>
          ) : (
            <div className="px-2 py-1 rounded-md text-darker-gray bg-light-gray font-medium text-2xs">
              {t("promo_codes.expired_on", {
                date: moment(code.ends_on).format("LL"),
              })}
            </div>
          )}
        </div>
      ))}
    </FullScreen>
  );
}
