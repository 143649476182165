import React, { useState } from "react";
import classNames from "../../utils/classNames";
import {
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
} from "@heroicons/react/20/solid";

export default function LinesSelect(props) {
  const {
    label,
    value,
    onChange = () => {},
    options = [],
    foldable = false,
    closed = false,
  } = props;

  const [open, setOpen] = useState(!closed);

  const handleSelectOption = (option) => {
    // close selecta if option is selected for the first time
    if (!value) setOpen(false);
    onChange(option.value);
  };

  return (
    <div>
      <div
        className={classNames(
          "flex items-center justify-between pb-2",
          foldable && "cursor-pointer",
        )}
        onClick={() => foldable && setOpen(!open)}
      >
        {label ? (
          <div className="text-base font-semibold leading-6 text-gray-900">
            {label}
          </div>
        ) : (
          <div />
        )}
        {foldable ? (
          <div className="flex items-center space-x-4">
            {!open && (
              <div className="text-sm text-gray-500">
                {options.find((option) => option.value == value)?.title}
              </div>
            )}
            <div className="py-2 pl-2">
              {open ? (
                <ChevronDownIcon className="w-6" />
              ) : (
                <ChevronLeftIcon className="w-6" />
              )}
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>
      {foldable && !open ? null : (
        <div className={"relative -space-y-px rounded-md bg-white"}>
          {options.map((option, index) => (
            <div key={option.value}>
              <div
                className={classNames(
                  index === 0 ? "rounded-tl-md rounded-tr-md" : "",
                  index === options.length - 1
                    ? "rounded-bl-md rounded-br-md"
                    : "",
                  option.value == value
                    ? "z-10 border-primary-border bg-light-primary"
                    : "border-gray-200",
                  "relative flex flex-col cursor-pointer border p-4 focus:outline-none md:grid md:grid-cols-3 md:pl-4 md:pr-6",
                )}
                onClick={() => handleSelectOption(option)}
              >
                <div className="flex items-center text-sm">
                  <div
                    className={classNames(
                      option.value == value
                        ? "bg-primary border-transparent"
                        : "bg-white border-gray-300",
                      "flex-shrink-0 h-4 w-4 rounded-full border flex items-center justify-center",
                    )}
                    aria-hidden="true"
                  >
                    <div className="rounded-full bg-white w-1.5 h-1.5" />
                  </div>
                  <div
                    className={classNames(
                      option.value == value ? "text-black" : "text-gray-900",
                      "ml-3 font-medium",
                    )}
                  >
                    {option.title}
                  </div>
                </div>
                {option.label ? (
                  <div
                    className={classNames(
                      option.value == value
                        ? "text-primary-border"
                        : "text-neutral-300",
                      "text-sm md:text-right",
                    )}
                  >
                    {option.label}
                  </div>
                ) : (
                  <div />
                )}
                {option.description && (
                  <div
                    className={classNames(
                      option.value == value ? "text-primary" : "text-gray-500",
                      "ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right",
                    )}
                  >
                    {option.description}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
