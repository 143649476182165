import React, { useEffect, useRef, useState } from "react";
import axios from "axios";

import PaymentLinkCard from "./PaymentLinkCard";
import StatusBadge from "../shared/StatusBadge";
import PublicBackground from "../shared/PublicBackground";
import Button from "../elements/Button";
import { useTranslation } from "react-i18next";
import { DocumentArrowDownIcon } from "@heroicons/react/20/solid";
import PublicFormInput from "../forms/PublicFormInput";
import headers from "../../utils/headers";

export default function RequestInvoicePage(props) {
  const { payment } = props;

  const { t } = useTranslation();

  const containerRef = useRef();
  const scrollToBottom = () =>
    containerRef.current?.scrollTo(0, containerRef.current.scrollHeight);

  // Get payment payment & payment intent
  useEffect(scrollToBottom, []);

  const [company, setCompany] = useState(payment?.invoice_company || "");
  const [address, setAddress] = useState(payment?.invoice_address || "");
  const [extra, setExtra] = useState(payment?.invoice_extra || "");

  const handleSubmit = () => {
    if (!address.length) return;

    axios
      .patch(
        `/public/payments/${payment.id}`,
        {
          invoice_company: company,
          invoice_address: address,
          invoice_extra: extra,
        },
        headers(),
      )
      .then(() => {
        window.location.replace(`/public/payments/${payment.id}/invoice`);
      });
  };

  if (!payment) return null;

  return (
    <PublicBackground containerRef={containerRef}>
      <div className="flex-grow" />
      <PaymentLinkCard
        organization={payment.organization}
        total={payment.total}
        badge={
          payment.status == "paid" && (
            <StatusBadge label="Payment confirmed" status="success" small />
          )
        }
      />
      <div className="bg-white p-4 rounded-xl flex flex-col space-y-4 w-full max-w-sm">
        <div className="text-md font-medium">
          {t("payments.page.invoice_form_title")}
        </div>
        <PublicFormInput
          label={t("payments.page.invoice_company")}
          autoComplete="organization"
          value={company}
          onChange={setCompany}
        />
        <PublicFormInput
          label={t("payments.page.invoice_address")}
          type="textarea"
          autoComplete="street-address"
          value={address}
          onChange={setAddress}
          required
        />
        <PublicFormInput
          label={t("payments.page.invoice_extra")}
          value={extra}
          onChange={setExtra}
        />
      </div>
      <Button
        label={t("payments.page.download_invoice")}
        icon={DocumentArrowDownIcon}
        className="w-full max-w-sm  text-base font-medium "
        disabled={!address.length}
        onClick={handleSubmit}
      />
    </PublicBackground>
  );
}
