import React from "react";

import ContactHeader from "../contacts/ContactHeader";

import formatAmount from "../../utils/formatAmount";
import { useTranslation } from "react-i18next";

export default function PaymentLinkCard(props) {
  const { organization, currency, total, badge, date } = props;

  const { t } = useTranslation();

  return (
    <div className="w-full max-w-sm flex flex-col items-stretch justify-center py-4 bg-white rounded-lg">
      <div className="px-4">
        <ContactHeader contact={organization} className="mb-4" right={badge} />
      </div>
      {date ? (
        <div className="text-md font-medium text-center p-4 border-t">
          {date}
        </div>
      ) : null}
      <div className="text-md flex flex-col pt-3 border-t">
        <div className="flex justify-between font-medium px-4">
          <div>{t("payments.image.amount")}</div>
          <div>{formatAmount(total, currency)}</div>
        </div>
      </div>
    </div>
  );
}
