import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AutomationContext } from "~/contexts/automation-context";
import Button from "../../elements/Button";
import PageTitle from "../../shared/PageTitle";

import Scenario from "./Scenario";
import NewScenarioDialog from "./NewScenarioDialog";
import ScenarioListItem from "./ScenarioListItem";
import classNames from "../../../utils/classNames";
import Modal from "../../shared/Modal";
import Loader from "../../utils/Loader";

import { sortBy } from "lodash";
import Netsuke from "../../shared/Netsuke";
import { PlusIcon } from "@heroicons/react/20/solid";
import ScenarioResults from "./ScenarioResults";

const scenarioColumns = [
  "name",
  "executions",
  "conversions",
  "last_updated",
  "status",
];

export default function ScenariosPage(props) {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  if (location.pathname == "/automation") navigate("/automation/scenarios");

  const { scenarioId } = useParams();
  const { showResults } = props;

  const { scenarios, loadScenarios, loading } = useContext(AutomationContext);

  const [newScenario, showNewScenario] = useState();

  useEffect(loadScenarios, []);

  const sortedScenarios = useMemo(
    () => sortBy(scenarios, (scenario) => scenario.title.toLowerCase()),
    [scenarios],
  );

  return (
    <div className="w-full h-full flex flex-col sm:h-screen sm:overflow-y-auto px-4 sm:px-6 lg:px-8 py-3 sm:py-6">
      <div className="space-y-4 sm:space-y-6 flex-shrink-0">
        <PageTitle text={t("automation.scenarios.title")} />
        <div className="flex items-center justify-between">
          <div className="text-md">
            {t("automation.scenarios.count", {
              count: scenarios.length,
            })}
          </div>
          <Button
            style="primary"
            onClick={() => showNewScenario(true)}
            label={t("automation.scenarios.new")}
            icon={PlusIcon}
          />
        </div>
      </div>
      {scenarios.length > 0 ? (
        <div className="mt-2 flow-root flex-grow">
          <div className="-mx-4 -my-2 min-h-full overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    {scenarioColumns.map((column, index) => (
                      <th
                        key={column}
                        scope="col"
                        className={classNames(
                          "py-3.5 text-left text-sm font-semibold text-gray-900",
                          index == 0 ? "pl-4 sm:pl-0 pr-3" : "px-3",
                        )}
                      >
                        {t(`automation.scenarios.table.columns.${column}`)}
                      </th>
                    ))}
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                    >
                      <span className="sr-only">{t("shared.edit")}</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {sortedScenarios.map((scenario) => (
                    <ScenarioListItem key={scenario.id} scenario={scenario} />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : loading ? (
        <div className="h-full flex items-center justify-center p-6">
          <Loader width={30} strokeWidth={6} />
        </div>
      ) : (
        <div className="flex flex-col h-full items-center space-y-8 p-6 pt-24">
          <Netsuke style="cool" />
          <div className="font-medium text-lg">
            {t("automation.scenarios.no_scenarios")}
          </div>
          <Button
            label={t("automation.scenarios.create_first")}
            onClick={() => showNewScenario(true)}
            size="xl"
          />
        </div>
      )}
      {scenarioId ? (
        showResults ? (
          <Modal
            onClose={() => navigate("/automation/scenarios")}
            className="h-full !w-full max-w-5xl !p-0"
          >
            <ScenarioResults scenarioId={scenarioId} />
          </Modal>
        ) : (
          <Modal
            onClose={() => navigate("/automation/scenarios")}
            className="h-full !w-full max-w-5xl !p-0"
            checkDirty
          >
            <Scenario scenarioId={scenarioId} />
          </Modal>
        )
      ) : null}
      {newScenario ? (
        <NewScenarioDialog onClose={() => showNewScenario(false)} />
      ) : null}
    </div>
  );
}
