import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import PageHeader from "../shared/PageHeader";
import FullScreen from "../utils/FullScreen";
import ContactHeader from "./ContactHeader";
import { InboxContext } from "~/contexts/inbox-context";
import LabeledInput from "../forms/LabeledInput";

export default function ContactInformation(props) {
  const { back, conversation } = props;

  const { contact } = conversation;

  const { updateContact } = useContext(InboxContext);

  const { t } = useTranslation();

  const [name, setName] = useState(contact?.real_name);
  const [email, setEmail] = useState(contact?.email);
  const [phone, setPhone] = useState(contact?.phone);

  const [loading, setLoading] = useState(false);
  const handleSave = () => {
    setLoading(true);
    updateContact(
      contact.id,
      {
        real_name: name,
        email,
        phone,
      },
      () => setTimeout(() => setLoading(false), 500),
    );
  };

  return (
    <FullScreen hideTabBar>
      <PageHeader
        title={t("inbox.details.information")}
        action={{
          label: t("shared.save"),
          onClick: handleSave,
          loading,
        }}
        back={back}
        className="mb-4"
      />
      <div className="px-4 py-2 border-b">
        <ContactHeader
          contact={contact}
          conversation={conversation}
          showFolder
        />
      </div>
      <LabeledInput
        name="name"
        autoComplete="name"
        value={name}
        onChange={setName}
        label={t("inbox.details.real_name")}
        placeholder={t("inbox.details.real_name_placeholder")}
        canCopy
      />
      <LabeledInput
        name="email"
        autoComplete="email"
        type="email"
        value={email}
        onChange={setEmail}
        label={t("inbox.details.email_address")}
        placeholder={t("inbox.details.email_placeholder")}
        canCopy
      />
      <LabeledInput
        name="phone"
        autoComplete="tel"
        type="tel"
        value={phone}
        onChange={setPhone}
        label={t("inbox.details.phone_number")}
        placeholder={t("inbox.details.phone_placeholder")}
        canCopy
      />
    </FullScreen>
  );
}
