import {
  CalendarDaysIcon,
  PaperAirplaneIcon,
  FunnelIcon,
  ArrowTopRightOnSquareIcon,
  UsersIcon,
  ExclamationCircleIcon,
  FireIcon,
} from "@heroicons/react/20/solid";
import { delay, every, set } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { Panel } from "reactflow";
import { useDebounce } from "react-use";

import { CampaignsContext } from "~/contexts/campaigns-context";
import { ContactsContext } from "~/contexts/contacts-context";
import { DirtyContext } from "~/contexts/dirty-context";
import { UIContext } from "~/contexts/ui-context";
import { UserContext } from "~/contexts/user-context";

import Flow from "../automation/flow/Flow";
import FlowWrapper from "../automation/flow/FlowWrapper";
import validateAction from "../automation/flow/validateAction";
import Button from "../elements/Button";
import Input from "../elements/Input";
import LinesSelect from "../elements/LinesSelect";
import FolderTag from "../inbox/folders/FolderTag";
import Toggle from "../shared/Toggle";
import CalendarModal from "../settings/CalendarModal";
import { DateTime } from "luxon";
import Badge from "../elements/Badge";
import Table from "../elements/table/Table";
import ContactPicture from "../shared/ContactPicture";
import { Link } from "react-router-dom";
import InputCheckbox from "../elements/InputCheckbox";
import Loader from "../utils/Loader";
import { SubscriptionContext } from "../../contexts/subscription-context";

export default function EditCampaign(props) {
  const { t } = useTranslation();

  const { initialValues, onSend = () => {}, handleClose } = props;

  const isNew = !initialValues?.id;

  const { showAlert } = useContext(UIContext);
  const { organization } = useContext(UserContext);
  const { allContactsCount, segments, loadContacts, loadContact } =
    useContext(ContactsContext);
  const {
    loadCampaign,
    saveCampaign,
    sendCampaign,
    scheduleCampaign,
    deleteCampaign,
    campaignContactsCount,
  } = useContext(CampaignsContext);
  const { limitReached } = useContext(SubscriptionContext);

  const { dirty, setDirty } = useContext(DirtyContext);

  const [loading, setLoading] = useState(false);
  const [campaign, setCampaign] = useState({
    destination: initialValues.destination ?? "",
    ...initialValues,
  });

  const handleCampaignChange = (field) => (value) => {
    setCampaign({ ...campaign, [field]: value });
  };

  const [contacts, setContacts] = useState(initialValues.contacts ?? []);

  const handleLoadContact = async (id) => {
    const res = await loadContact(id);
    setContacts((contacts) =>
      contacts.map((contact) =>
        contact.id === id ? { ...contact, ...res } : contact,
      ),
    );
  };

  useEffect(async () => {
    if (campaign.selected_contact_ids?.length > 0) {
      const contacts = await loadContacts({
        ids: campaign.selected_contact_ids,
      });
      setContacts(contacts);
    }
  }, [campaign.selected_contact_ids]);

  // Contacts
  const contactColumns = useMemo(
    () => [
      {
        label: t("contacts.columns.username"),
        sort_name: "username",
        accessor: (contact) => (
          <div className="flex items-center font-medium text-black">
            <ContactPicture
              contact={contact}
              className="w-6 h-6 mr-2"
              linkToProfile
            />
            {contact.username}
            <Link
              to={
                contact.conversation_id &&
                `/inbox/conversations/${contact.conversation_id}`
              }
            >
              <ArrowTopRightOnSquareIcon className="w-4 ml-1 text-dark-gray hover:text-darker-gray" />
            </Link>
          </div>
        ),
      },
      {
        label: t("contacts.columns.name"),
        sort_name: "name",
        accessor: (contact) => contact.real_name || contact.name,
      },
      {
        label: t("contacts.columns.email"),
        sort_name: "email",
        accessor: (contact) => contact.email,
      },
    ],
    [],
  );
  // Contacts count

  const [contactsCount, setContactsCount] = useState();
  const [loadingContactsCount, setLoadingContactsCount] = useState(true);

  const getContactsCount = async () => {
    if (!campaign.loaded && campaign.id) return;
    setLoadingContactsCount(true);
    const res = await campaignContactsCount({
      destination: campaign.destination,
      excluded_folder_id: campaign.excluded_folder_id,
      options: campaign.options,
    });
    if (res.contacts !== undefined) setContactsCount(res.contacts);
    setLoadingContactsCount(false);
  };

  useDebounce(getContactsCount, 500, [
    campaign.destination,
    campaign.excluded_folder_id,
    campaign.options,
  ]);

  // Destinations
  const destinations = useMemo(
    () => [
      {
        title: (
          <div className="font-semibold text-black">
            {t("campaigns.destinations.all_contacts.title")}
          </div>
        ),
        description: t("campaigns.destinations.contacts_count", {
          count: allContactsCount,
        }),
        value: "all_contacts",
      },
      ...organization.folders
        .filter((f) => f.id !== campaign.excluded_folder_id)
        .map((f) => ({
          title: <FolderTag folder={f} size="md" />,
          value: `folder_${f.id}`,
          label: t("campaigns.destinations.folder.label"),
          description: t("campaigns.destinations.contacts_count", {
            count: f.count,
          }),
        })),
      ...segments.map((s) => ({
        title: <Badge label={s.title} color="purple" icon={FunnelIcon} />,
        value: `segment_${s.id}`,
        label: t("campaigns.destinations.segment.label"),
        description: t("campaigns.destinations.contacts_count", {
          count: s.count,
        }),
      })),
    ],
    [
      allContactsCount,
      segments,
      organization.folders,
      campaign.excluded_folder_id,
    ],
  );

  // Excluded folders
  const excludedFolders = useMemo(
    () => [
      {
        title: (
          <div className="whitespace-nowrap">
            {t("campaigns.dont_exclude_folder")}
          </div>
        ),
        value: null,
      },
      ...organization.folders
        .filter((f) => `folder_${f.id}` !== campaign.destination)
        .map((f) => ({
          title: <FolderTag folder={f} size="md" />,
          value: f.id,
          label: t("campaigns.destinations.folder.label"),
          description: t("campaigns.destinations.contacts_count", {
            count: f.count,
          }),
        })),
    ],
    [organization.folders, campaign.destination],
  );

  // Actions
  const [initialActions, setInitialActions] = useState(
    initialValues.actions ?? [],
  );
  const [actions, setActions] = useState(initialValues.actions ?? []);
  const resetActions = () => {
    setActions(initialActions);
    setDirty(false);
  };

  // Save & send

  const isDraftValid = useMemo(
    () => every([campaign.title?.length > 0, actions?.every(validateAction)]),
    [campaign, actions],
  );

  const isSendValid = useMemo(
    () =>
      every([
        campaign.title?.length > 0,
        campaign.destination || campaign.contacts?.length > 0,
        contactsCount > 0,
        actions?.length > 0,
        actions?.every(validateAction),
      ]),
    [campaign, actions, contactsCount],
  );

  const campaignPayload = useMemo(() => {
    const contactIds = campaign.contacts?.map((c) => c.id);
    return {
      ...campaign,
      actions,
      selected_contact_ids: contactIds,
    };
  }, [campaign, actions]);

  const handleSave = async () => {
    if (!isDraftValid) return;

    setLoading(true);

    const res = await saveCampaign(campaignPayload);

    setLoading(false);
    setCampaign(res);
    setInitialActions(res.actions);
    setActions(res.actions);
    setDirty(false);
  };

  const handleSend = () => {
    if (!isSendValid) return;

    showAlert({
      title: t("campaigns.send_campaign_confirm"),
      onSubmit: async () => {
        setLoading(true);
        sendCampaign(campaignPayload);

        setLoading(false);
        setDirty(false);

        onSend();

        handleClose();
      },
    });
  };

  const handleSchedule = (date) => {
    if (!isSendValid) return;

    setLoading(true);
    scheduleCampaign(campaignPayload, date);

    setLoading(false);
    handleClose();
  };

  const [scheduleDialog, setScheduleDialog] = useState(false);

  const secondaryActions = useMemo(
    () => [
      {
        label: t("campaigns.schedule_campaign"),
        icon: CalendarDaysIcon,
        onClick: () => setScheduleDialog(true),
        disabled: !isSendValid,
      },
    ],
    [setScheduleDialog, isSendValid],
  );

  const renderOptions = useCallback(
    () => (
      <>
        {["interrupt_on_message"].map((field) => (
          <div className="flex items-center space-x-2" key={field}>
            <Toggle
              value={campaign[field]}
              onChange={handleCampaignChange(field)}
            />
            <div className="text-md">{t("campaigns.settings." + field)}</div>
          </div>
        ))}
      </>
    ),
    [campaign, handleCampaignChange],
  );

  // Load campaign if not loaded yet
  useEffect(async () => {
    if (!campaign.loaded && campaign.id) {
      const res = await loadCampaign(campaign.id);
      setCampaign(res);
      setInitialActions(res.actions);
      setActions(res.actions);
    }
  }, [campaign.loaded, campaign.id, loadCampaign]);

  return (
    <>
      <div className={`w-full flex-grow flex flex-col sm:overflow-hidden`}>
        <div className="flex-grow sm:grid grid-cols-7 overflow-hidden">
          <div className="col-span-3 flex-grow overflow-auto py-4 px-4 space-y-4">
            <div className="font-medium text-lg">
              {isNew
                ? t("campaigns.new_campaign")
                : t("campaigns.edit_campaign")}
            </div>
            <Input
              placeholder={t("campaigns.fields.title")}
              className="w-full"
              name="title"
              value={campaign?.title}
              onChange={handleCampaignChange("title")}
              autoFocus={isNew}
            />

            {contacts?.length > 0 ? (
              <Table
                items={contacts}
                columns={contactColumns}
                onRowRender={(contact) => handleLoadContact(contact.id)}
                defaultPerPage={10}
              />
            ) : (
              <>
                <LinesSelect
                  label={t("campaigns.destinations.label")}
                  value={campaign?.destination}
                  onChange={handleCampaignChange("destination")}
                  options={destinations}
                  foldable
                  closed={!!campaign?.destination}
                />

                <LinesSelect
                  label={t("campaigns.exclude_folder")}
                  value={campaign?.excluded_folder_id}
                  onChange={handleCampaignChange("excluded_folder_id")}
                  options={excludedFolders}
                  foldable
                  closed
                />

                <InputCheckbox
                  label={t("campaigns.exclude_contacts_time")}
                  value={campaign?.options?.exclude_time ?? false}
                  onClick={() =>
                    setCampaign((campaign) => ({
                      ...campaign,
                      options: {
                        ...campaign.options,
                        exclude_time: !campaign?.options?.exclude_time,
                      },
                    }))
                  }
                />

                {loadingContactsCount ? (
                  <div className="flex items-center justify-center p-4 rounded-lg bg-light-gray">
                    <Loader width={20} />
                  </div>
                ) : (
                  <div className="flex items-center space-x-2 p-4 rounded-lg bg-light-gray">
                    <UsersIcon className="w-5 flex-shrink-0" />
                    <div className="text-md">
                      {t("campaigns.contacts_count_estimation")}{" "}
                      <strong className="font-semibold">
                        {t("campaigns.contacts_count", {
                          count: contactsCount || 0,
                        })}
                      </strong>
                    </div>
                  </div>
                )}

                {limitReached && (
                  <div className="bg-warning flex items-start space-x-2 p-4 rounded-lg">
                    <ExclamationCircleIcon className="w-5 flex-shrink-0" />
                    <div className="space-y-2">
                      <div className="text-md">
                        {t("campaigns.limit_reached_warning")}
                      </div>
                      <Button
                        size="small"
                        icon={FireIcon}
                        label={t("subscription.upgrade_to_pro")}
                        href="/stripe/checkout_session"
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="col-span-4 border-l">
            <FlowWrapper>
              <Flow actions={actions} setActions={setActions}>
                <Panel
                  position="top-left"
                  className="hidden sm:block p-4 rounded-lg bg-white border space-y-3"
                >
                  {renderOptions()}
                </Panel>
                {dirty && (
                  <Panel
                    position={isMobile ? "bottom-right" : "top-right"}
                    className="hidden sm:flex space-x-2"
                  >
                    <Button
                      label={t("shared.cancel_changes")}
                      onClick={resetActions}
                    />
                  </Panel>
                )}
              </Flow>
            </FlowWrapper>
          </div>
        </div>
        <div className="border-t p-4 flex-shrink-0 flex flex-col sm:flex-row items-center justify-between">
          <div className="flex space-x-3">
            <Button label={t("shared.cancel")} onClick={handleClose} />
            {isNew ? null : (
              <Button
                label={t("shared.delete")}
                style="danger"
                onClick={() => deleteCampaign(campaign.id)}
              />
            )}
          </div>
          <div className="flex space-x-3">
            <Button
              label={t("campaigns.save_as_draft")}
              loading={loading}
              disabled={!isDraftValid}
              onClick={handleSave}
            />
            {/* <ButtonWithDropdown
              icon={PaperAirplaneIcon}
              label={t("campaigns.send_campaign")}
              style="primary"
              onClick={handleSend}
              disabled={!isSendValid}
              loading={loading}
              actions={secondaryActions}
              direction="top"
            /> */}
            <Button
              icon={PaperAirplaneIcon}
              label={t("campaigns.send_campaign")}
              style="primary"
              onClick={handleSend}
              disabled={!isSendValid}
              loading={loading}
            />
            <Button
              icon={CalendarDaysIcon}
              label={t("campaigns.schedule_campaign")}
              onClick={() => setScheduleDialog(true)}
              disabled={!isSendValid}
              loading={loading}
            />
          </div>
        </div>
      </div>
      {scheduleDialog && (
        <CalendarModal
          title={t("campaigns.schedule_campaign")}
          confirmLabel={t("campaigns.schedule_campaign")}
          minDate={DateTime.now().toISO()}
          initialDate={
            campaign?.scheduled_at ||
            DateTime.now()
              .plus({ days: 1 })
              .set({ hour: 8, minute: 30, second: 0 })
              .toISO()
          }
          includeTime
          onConfirm={handleSchedule}
          onClose={() => setScheduleDialog(false)}
        />
      )}
    </>
  );
}
