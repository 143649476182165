import React, { useContext, useEffect, useState } from "react";
import { sum, sortBy } from "lodash";
import axios from "axios";

import { ArrowsUpDownIcon, ClockIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import {
  ArrowTopRightOnSquareIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/20/solid";

import { UserContext } from "~/contexts/user-context";

import Button from "../elements/Button";

import headers from "../../utils/headers";
import formatAmount from "../../utils/formatAmount";
import { useTranslation } from "react-i18next";
import RelativeDate from "../utils/RelativeDate";
import { PaymentsContext } from "~/contexts/payments-context";
import Loader from "../utils/Loader";

export default function BalanceCard() {
  const { t } = useTranslation();

  const { organization } = useContext(UserContext);
  const { payments } = useContext(PaymentsContext);

  const [balance, setBalance] = useState(null);
  const loadBalance = () => {
    axios
      .get("/api/stripe/balance", headers())
      .then((res) => setBalance(res.data));
  };
  useEffect(loadBalance, [organization?.stripe_account_id]);

  const [payouts, setPayouts] = useState([]);
  const loadPayouts = () => {
    axios
      .get("/api/stripe/payouts", headers())
      .then((res) => setPayouts(res.data));
  };
  useEffect(loadPayouts, [organization?.stripe_account_id]);

  const awaitingAmount = sum(
    payments
      .filter((payment) => payment.status === "awaiting" && payment.connect)
      .map((payment) => payment.amount),
  );

  const payoutClass = (status) => {
    switch (status) {
      case "paid":
        return "opacity-70";
      case "canceled":
        return "opacity-30";
      case "failed":
        return "opacity-30";
      default:
        return null;
    }
  };

  const payoutIcon = (status) => {
    switch (status) {
      case "pending":
        return <ClockIcon className="w-6 h-6" />;
      case "in_transit":
        return <ClockIcon className="w-6 h-6" />;
      case "paid":
        return <CheckCircleIcon className="w-6 h-6 text-green-500" />;
      case "canceled":
        return <XCircleIcon className="w-6 h-6 text-red-500" />;
      case "failed":
        return <XCircleIcon className="w-6 h-6 text-red-500" />;
      default:
        return null;
    }
  };

  const sortedPayouts = sortBy(payouts, "created_at").reverse();

  if (!organization.stripe_account_id) {
    return (
      <div className="p-4 space-y-8">
        <div className="p-4 rounded-lg bg-light-secondary text-center text-md">
          {t("payments.balance.setup_notice")}
        </div>
        <Button
          className="w-full mb-8"
          label={t("payments.actions.set_up")}
          to="/profile/payment_settings"
          icon={<ArrowTopRightOnSquareIcon className="w-5 h-5 mr-1" />}
        />
      </div>
    );
  }

  return (
    <div className="pt-4 pb-12">
      {awaitingAmount > 0 && (
        <div className="flex justify-between items-center text-2sm text-dark-gray px-4">
          <div>{t("payments.balance.awaiting")}</div>
          <div className="flex">
            <div>{formatAmount(awaitingAmount, organization.currency)}</div>
          </div>
        </div>
      )}
      <div className="flex justify-center">
        {balance ? (
          <>
            <div className="p-8 flex flex-col items-center justify-center space-y-3">
              <div className="text-3xl font-medium">
                {formatAmount(
                  balance?.available?.amount,
                  balance?.available?.currency,
                )}
              </div>
              <div className="text-2sm">
                {t("payments.balance.available_now")}
              </div>
            </div>
            <div className="p-8 flex flex-col items-center justify-center space-y-3 text-dark-gray">
              <div className="text-3xl font-medium">
                {formatAmount(
                  balance?.pending?.amount,
                  balance?.pending?.currency,
                )}
              </div>
              <div className="text-2sm">
                {t("payments.balance.available_soon")}
              </div>
            </div>
          </>
        ) : (
          <div className="p-8">
            <Loader strokeWidth={6} className="w-8" />
          </div>
        )}
      </div>
      <div className="px-4 mb-4">
        <Button
          className="w-full mb-8"
          label={t("payments.balance.dashboard")}
          href="/stripe_connect/dashboard"
          icon={<ArrowTopRightOnSquareIcon className="w-5 h-5 mr-1" />}
          disabled={!organization?.stripe_account_id}
        />
        <div className="flex items-center justify-center space-x-2 text-dark-gray">
          <QuestionMarkCircleIcon className="w-6" />
          <div className="text-left text-sm max-w-[220px]">
            {t("payments.balance.transfer_info")}
          </div>
        </div>
      </div>
      {payouts.length > 0 ? (
        <>
          <h3 className="text-md p-4">{t("payments.balance.transfers")}</h3>
          {sortedPayouts.map((payout) => (
            <div
              className={`flex justify-between items-center p-4 border-b ${payoutClass(
                payout.status,
              )}`}
              key={payout.id}
            >
              <div className="flex space-x-4 items-center">
                {payoutIcon(payout.status)}
                <div>
                  <div className="text-md mb-1">{payout.account?.name}</div>
                  <div className="text-2sm text-dark-gray">
                    •••• {payout.account?.last4}
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-end">
                <div className="text-md font-medium mb-1">
                  {formatAmount(payout.amount, organization.currency)}
                </div>
                <div className="text-2sm text-dark-gray">
                  {payout.status != "paid" && <span>~&nbsp;</span>}
                  <RelativeDate date={payout.arrival_date} />
                </div>
              </div>
            </div>
          ))}
        </>
      ) : null}
    </div>
  );
}
