import React, { useContext, useMemo } from "react";
import { UserContext } from "../../../contexts/user-context";
import FolderTag from "../../inbox/folders/FolderTag";

export default function FolderAction(props) {
  const { action } = props;

  const { organization } = useContext(UserContext);

  const folder = useMemo(
    () =>
      organization.folders?.find(
        (folder) => folder.id === action.options?.folder_id,
      ),
    [organization.folders, action.options?.folder_id],
  );

  return (
    <div className="flex flex-col items-start">
      <FolderTag folder={folder} size="md" />
    </div>
  );
}
