import {
  ArrowPathIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
} from "@heroicons/react/20/solid";
import { DateTime } from "luxon";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { CampaignsContext } from "~/contexts/campaigns-context";
import ActionExecution from "../automation/scenarios/executions/ActionExecution";
import ExecutionStatusIcon from "../automation/scenarios/executions/ExecutionStatusIcon";
import { SubscriptionContext } from "../../contexts/subscription-context";
import Button from "../elements/Button";

export default function CampaignExecution(props) {
  const { execution, setExecution, opened, onOpen } = props;

  const { campaign_id, status, created_at } = execution;

  const { t } = useTranslation();

  const { limitReached } = useContext(SubscriptionContext);
  const { campaigns } = useContext(CampaignsContext);

  const campaign = campaigns.find((s) => s.id === campaign_id);

  return (
    <div
      className={`max-w-80 w-full rounded-lg border shadow ${
        opened ? "" : "opacity-75 transition-opacity hover:opacity-100"
      }`}
    >
      <div
        className="p-2 flex justify-between items-center cursor-pointer"
        onClick={onOpen}
      >
        <div className="flex items-center space-x-1.5">
          <ExecutionStatusIcon status={status} />
          <div className="font-medium text-2sm flex-grow">
            {campaign
              ? campaign.title
              : t("automation.scenarios.executions.not_found")}
          </div>
        </div>

        {campaign ? (
          opened ? (
            <ChevronDownIcon className="w-5" />
          ) : (
            <ChevronLeftIcon className="w-5" />
          )
        ) : null}
      </div>
      {opened ? (
        <div className="px-2">
          <div
            className="text-xs mb-2"
            title={DateTime.fromISO(created_at).toLocaleString(
              DateTime.DATETIME_FULL,
            )}
          >
            {t("automation.scenarios.executions.started", {
              date: DateTime.fromISO(created_at).toLocaleString(
                DateTime.DATETIME_SHORT,
              ),
            })}
          </div>
          {status == "limited" && (
            <div className="bg-warning p-2 rounded mb-2 space-y-2">
              <div className="text-xs font-medium">
                {t("campaigns.executions.limited")}
              </div>
            </div>
          )}
          <div className="relative">
            {execution.action_executions.map((actionExecution) => (
              <ActionExecution
                className="relative z-10"
                execution={actionExecution}
                key={actionExecution.id}
                showMessageLink
              />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}
