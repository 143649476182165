import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import PaymentLinkCard from "./PaymentLinkCard";
import PaymentForm from "./PaymentForm";
import StatusBadge from "../shared/StatusBadge";
import PublicBackground from "../shared/PublicBackground";
import Button from "../elements/Button";
import { useTranslation } from "react-i18next";
import { DocumentArrowDownIcon } from "@heroicons/react/20/solid";
import paymentClassName from "../../utils/paymentClassName";
import paymentLabel from "../../utils/paymentLabel";

export default function PublicPaymentPage(props) {
  const { id, eventDate, onSubmit = () => {} } = props;

  const [payment, setPayment] = useState();

  const { t } = useTranslation();

  const containerRef = useRef();
  const scrollToBottom = () =>
    containerRef.current?.scrollTo(0, containerRef.current.scrollHeight);

  // Get payment payment & payment intent
  useEffect(() => {
    axios
      .get(`/public/payments/${id}.json`)
      .then((res) => setPayment(res.data));
    scrollToBottom();
  }, [id]);

  const total = parseFloat(payment?.total);

  // Stripe
  let stripePromise = useMemo(
    () =>
      payment?.organization
        ? loadStripe(
            payment?.organization?.stripe_public_key ||
              process.env.STRIPE_PUBLIC_KEY,
            {
              stripeAccount: payment?.organization?.stripe_account_id,
            },
          )
        : null,
    [payment?.organization],
  );

  if (!payment) return null;

  const stripeOptions = {
    clientSecret: payment.payment_secret,
    appearance: {
      theme: "stripe",
      variables: {
        fontSizeBase: "14",
        colorPrimary: "#FFB344",
        colorDanger: "#f15158",
      },
    },
  };

  const statusBadge = (status) => {
    return (
      <div
        className={`inline-block py-2 px-3 text-sm rounded-lg ${paymentClassName(
          status,
        )}`}
      >
        {t("payments.labels." + status)}
      </div>
    );
  };

  return (
    <PublicBackground containerRef={containerRef}>
      <PaymentLinkCard
        organization={payment.organization}
        total={total}
        date={eventDate}
        badge={statusBadge(payment.status)}
      />
      {["awaiting", "attempted", "failed"].includes(payment.status) && (
        <Elements options={stripeOptions} stripe={stripePromise}>
          <PaymentForm
            organization={payment.organization}
            payment={payment}
            showPhone={payment.organization?.appointment_phone_required}
            onReady={scrollToBottom}
            onSubmit={onSubmit}
          />
        </Elements>
      )}
      {payment.status == "paid" && (
        <>
          {payment.organization?.stripe_account_business_type == "company" ? (
            <Button
              label={t("payments.page.request_invoice")}
              icon={DocumentArrowDownIcon}
              className="w-full max-w-sm  text-base font-medium "
              href={document.location.href + "/request_invoice"}
            />
          ) : null}
          <div className="w-full p-4 text-center bg-white rounded-lg max-w-sm text-sm">
            {t("payments.page.thank_you")}
            <br />
            {t("payments.page.can_close")}
          </div>
        </>
      )}
    </PublicBackground>
  );
}
