import React, { useState } from "react";

import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import Loader from "../utils/Loader";
import { truncate } from "lodash";
import { appInset } from "../../utils/environment";

export default function PageHeader(props) {
  const { title, action, back, className = "" } = props;

  return (
    <div
      className={
        "flex flex-shrink-0 items-center justify-center p-4 bg-white sticky z-10 inset-x-0 " +
        className
      }
      style={{
        top: appInset.top || 0,
      }}
    >
      <button
        onClick={back}
        className="w-8 h-9 flex justify-items-center items-center absolute left-3 inset-y-0 m-auto"
      >
        <ChevronLeftIcon className="h-6 w-6" />
      </button>
      <h1>{truncate(title, { length: 22 })}</h1>
      {action ? (
        action.loading ? (
          <Loader
            
            width={18}
            strokeWidth={8}
            className="absolute right-4 top-5"
          />
        ) : (
          <button
            className={`text-md ${
              action.primary && "text-primary"
            } active:text-primary disabled:text-light-gray absolute h-6 right-4 inset-y-0 m-auto`}
            onClick={action?.onClick}
          >
            {action.label}
          </button>
        )
      ) : null}
    </div>
  );
}
