import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageHeader from "../shared/PageHeader";
import FullScreen from "../utils/FullScreen";
import ContactHeader from "./ContactHeader";
import axios from "axios";
import headers from "../../utils/headers";
import ContactMedium from "./ContactMedium";
import { sortBy } from "lodash";
import FullscreenAttachments from "../inbox/FullscreenAttachments";

export default function ContactAllMedia(props) {
  const { back, conversation, messages } = props;

  const contact = { conversation };

  const { t } = useTranslation();

  const [media, setMedia] = useState(
    messages.filter(
      (m) => m.message_type == "image" || m.message_type == "video"
    )
  );
  const [loaded, setLoaded] = useState(false);
  const [showAttachment, setShowAttachment] = useState(false);

  useEffect(() => {
    axios
      .get(`/api/inbox/messages/media?conversation_id=${conversation.id}`, headers())
      .then((res) => {
        setMedia(res.data);
        setLoaded(true);
      });
  }, []);

  const orderedMedia = sortBy(media, "received_at");
  const fullscreenMedia = orderedMedia.map((m) => ({
    url: m.message_type == "video" ? m.video_url : m.image_url,
    type: m.message_type,
  }));

  return (
    <FullScreen className="flex flex-col overflow-hidden" hideTabBar>
      <PageHeader
        title={t("inbox.details.media")}
        back={back}
        className="mb-4"
      />
      <div className="px-4 pb-4 flex-shrink-0">
        <ContactHeader
          contact={contact}
          conversation={conversation}
          showFolder
        />
      </div>
      <div className="flex-grow overflow-auto pb-24 grid grid-cols-3 gap-1 auto-rows-min">
        {orderedMedia.map((medium, index) => (
          <ContactMedium
            key={medium.id}
            medium={medium}
            onClick={() => setShowAttachment(index)}
          />
        ))}
      </div>
      {loaded && media.length == 0 && (
        <div className="p-4 text-center text-md text-dark-gray">
          {t("inbox.details.media_empty")}
        </div>
      )}
      {showAttachment !== false && (
        <FullscreenAttachments
          media={fullscreenMedia}
          index={showAttachment}
          onClose={() => setShowAttachment(false)}
        />
      )}
    </FullScreen>
  );
}
