import React from "react";
import paymentClassName from "../../utils/paymentClassName";
import formatAmount from "../../utils/formatAmount";
import { useNavigate } from "react-router-dom";
import readableDate from "../../utils/readableDate";
import paymentStatusIcon from "../../utils/paymentStatusIcon";
import RelativeDate from "../utils/RelativeDate";
import CheckCircle from "../shared/CheckCircle";
import { useTranslation } from "react-i18next";
import ContactPicture from "../shared/ContactPicture";
import AppointmentStatusIcon from "../appointments/AppointmentStatusIcon";
import classNames from "../../utils/classNames";

export default function PaymentsListItem(props) {
  const { payment, selecting, selected, onSelect } = props;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleClick = () => {
    if (selecting) {
      onSelect();
    } else {
      navigate(`/payments/${payment.id}`);
    }
  };

  return (
    <button
      onClick={handleClick}
      className="w-full overflow-hidden flex items-center justify-between px-3 py-2.5 border-b border-neutral-100 select-none text-left"
    >
      {selecting ? <CheckCircle checked={selected} className="mr-3" /> : null}
      <div className="flex-grow flex space-x-4 items-center overflow-hidden">
        <div
          className={classNames(
            "w-[76px] flex space-x-1 justify-center items-center px-1 py-1.5 rounded-lg text-sm",
            payment.archived && "opacity-50",
            paymentClassName(payment.status),
          )}
        >
          {paymentStatusIcon(payment.status)}
          <div>{formatAmount(payment.total, payment.currency)}</div>
        </div>
        <div
          className={classNames(
            "flex flex-grow items-center space-x-2 overflow-hidden",
            payment.archived && "opacity-50",
          )}
        >
          <ContactPicture contact={payment.conversation?.contact} />
          <div className="overflow-hidden flex-grow">
            <div className="text-sm truncate">
              {payment.conversation?.contact?.name ||
                payment.conversation?.contact?.username ||
                t("payments.no_contact")}
            </div>
            {payment.paid && payment.date ? (
              <div className="text-xs text-dark-gray">
                {t("payments.paid_on", { date: readableDate(payment.date) })}
              </div>
            ) : null}
          </div>
          {payment.appointment_status ? (
            <AppointmentStatusIcon
              status={payment.appointment_status}
              date={payment.appointment_date}
            />
          ) : null}
        </div>
      </div>
      <div className="flex-shrink-0 ml-2 text-2sm font-light text-neutral-400 whitespace-nowrap">
        <RelativeDate date={payment.created_at} />
      </div>
    </button>
  );
}
