import React, { useCallback, useContext, useEffect, useState } from "react";
import { CalendarContext } from "~/contexts/calendar-context";
import { forEach, groupBy, throttle, unionBy } from "lodash";
import moment from "moment";
import axios from "axios";
import { UserContext } from "~/contexts/user-context";
import headers from "../utils/headers";
import { UIContext } from "~/contexts/ui-context";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import subscribeToChannel from "../utils/subscribeToChannel";
import reactNativeMessage from "../utils/reactNativeMessage";

export default function CalendarProvider(props) {
  const { organization } = useContext(UserContext);
  const { showAlert } = useContext(UIContext);

  const { t } = useTranslation();

  // Appointments
  const [appointments, setAppointments] = useState([]);

  const initializeAppointments = () => {
    if (!organization.features.calendar) return;
    loadAppointments();
    subscribeToChannel("AppointmentsChannel", (appointment) =>
      upsertAppointments([appointment]),
    );
  };

  const loadAppointments = useCallback(
    throttle(
      () =>
        axios
          .get("/api/calendar/appointments")
          .then((res) => setAppointments(res.data)),
      5000,
    ),
    [],
  );

  useEffect(initializeAppointments, [organization.id]);

  const upsertAppointments = (newAppointments) => {
    setAppointments((appointments) =>
      unionBy(newAppointments, appointments, "id"),
    );
  };

  const createAppointment = async (conversationId, events, amount) => {
    const { data: appointment } = await axios.post(
      "/api/calendar/appointments",
      {
        conversation_id: conversationId,
        events,
        amount,
      },
      headers(),
    );
    reactNativeMessage({ track: "key_app_usage" });
    upsertAppointments([appointment]);
    upsertEvents(appointment.calendar_events);
    return appointment;
  };

  const cancelAppointment = async (appointmentId) => {
    const { data: appointment } = await axios.delete(
      `/api/calendar/appointments/${appointmentId}`,
      headers(),
    );
    upsertAppointments([appointment]);
    upsertEvents(appointment.calendar_events);
    return appointment;
  };

  const removeAppointmentPayment = async (appointmentId) => {
    const { data: appointment } = await axios.patch(
      `/api/calendar/appointments/${appointmentId}/remove_payment`,
      null,
      headers(),
    );
    upsertAppointments([appointment]);
    return appointment;
  };

  // Events
  const [events, setEvents] = useState([]);

  const initializeEvents = () => {
    loadEvents();
    subscribeToChannel("CalendarEventsChannel", (event) =>
      upsertEvents([event]),
    );
  };

  const loadEvents = useCallback(
    throttle(() => {
      axios.get("/api/calendar/events").then((res) => setEvents(res.data));
    }, 5000),
    [],
  );

  useEffect(initializeEvents, [organization.id]);

  const upsertEvents = (newEvents) => {
    setEvents((events) => unionBy(newEvents, events, "id"));
  };

  const confirmEvent = async (appointmentId, eventId) => {
    const { data: appointment } = await axios
      .patch(
        `/api/calendar/appointments/${appointmentId}`,
        { event_id: eventId },
        headers(),
      )
      .catch(() => {
        showAlert({
          title: t("calendar.message.cannot_confirm"),
        });
      });

    if (!appointment) return;

    upsertAppointments([appointment]);
    upsertEvents(appointment.calendar_events);
    return appointment;
  };

  const updateEvent = async (eventId, params) => {
    const { data: event } = await axios.patch(
      `/api/calendar/events/${eventId}`,
      params,
      headers(),
    );
    upsertEvents([event]);
    return event;
  };

  const removeEvent = async (eventId) => {
    const { data: event } = await axios.delete(
      `/api/calendar/events/${eventId}`,
      headers(),
    );
    upsertEvents([event]);
    return event;
  };

  // Connected events

  const [connectedEvents, setConnectedEvents] = useState({});

  const upsertConnectedEvents = (newEvents) => {
    const eventsPerWeek = groupBy(newEvents, (evt) =>
      moment(evt.start_time).format("YYYY-WW"),
    );
    setConnectedEvents((events) => ({
      ...events,
      ...eventsPerWeek,
    }));
  };

  const loadConnectedEvents = (weekId) => {
    const visibleConnectedCalendars = organization.connected_calendars.filter(
      (c) => c.visible,
    );
    visibleConnectedCalendars.forEach((calendar) => {
      axios
        .get(`/api/calendar/calendars/${calendar.id}/events?week_id=${weekId}`)
        .then((res) => {
          setConnectedEvents((events) => ({
            ...events,
            [weekId]: unionBy(events[weekId] || [], res.data, "id"),
          }));
        });
    });
  };

  // Load events for the current week
  useEffect(() => {
    const firstDay = DateTime.now().startOf("week");
    loadConnectedEvents(firstDay.toISO(), firstDay.plus({ weeks: 1 }).toISO());
  }, []);

  const calendarValues = {
    appointments,
    loadAppointments,
    createAppointment,
    cancelAppointment,
    removeAppointmentPayment,
    events,
    loadEvents,
    connectedEvents,
    loadConnectedEvents,
    confirmEvent,
    updateEvent,
    removeEvent,
  };

  return (
    <CalendarContext.Provider value={calendarValues}>
      {props.children}
    </CalendarContext.Provider>
  );
}
