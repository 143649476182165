import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function TableHeader(props) {
  const { columns } = props;
  const [searchParams] = useSearchParams();
  const [sortDirection, setSortDirection] = useState(
    searchParams.get("sort_direction") || "",
  );
  const [sortColumn, setSortColumn] = useState(
    searchParams.get("sort_by") || "",
  );

  const sortByColumn = (column) => {
    if (!column.sort) return;
    if (column.sort_name === sortColumn) {
      if (sortDirection === "asc") {
        setSortDirection("desc");
        column.sort && column.sort("desc");
      } else if (sortDirection === "desc") {
        setSortDirection("");
        setSortColumn("");
        column.sort && column.sort("");
      } else {
        setSortDirection("asc");
        column.sort && column.sort("asc");
      }
    } else {
      setSortColumn(column.sort_name);
      setSortDirection("asc");
      column.sort("asc");
    }
  };
  return (
    <thead className="bg-gray-50 sticky top-0 z-20">
      <tr>
        {columns.map((column) => (
          <th
            key={column.label}
            scope="col"
            className={`group px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-gray-900 ${column.className} ${column.sort && "cursor-pointer"}`}
            onClick={() => sortByColumn(column)}
          >
            <span className="flex">
              {column.label}
              {column.sort ? (
                <span
                  className={`ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible ${column.sort_name !== sortColumn && "transition-opacity opacity-0 group-hover:opacity-100 group-focus:opacity-100 "} ${column.sort_name === sortColumn ? "opacity-100" : ""}`}
                >
                  {sortDirection == "desc" ? (
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  ) : (
                    <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                  )}
                </span>
              ) : null}
            </span>
          </th>
        ))}
      </tr>
    </thead>
  );
}
