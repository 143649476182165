import { uniq } from "lodash";
import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from "react";
import {
  MagnifyingGlassIcon,
  PaperAirplaneIcon,
  PlusCircleIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ContactsContext } from "~/contexts/contacts-context";
import Button from "../elements/Button";
import TableCellCheckbox from "../elements/table/TableCellCheckbox";

import TableSelection from "../elements/table/TableSelection";

import ContactFilter from "./filters/ContactFilter";

import Table from "../elements/table/Table";

import FilterTypeMenu from "./filters/FilterTypeMenu";
import Input from "../elements/Input";
import { InboxContext } from "~/contexts/inbox-context";
import Tabs from "../elements/Tabs";
import contactsTableColumns from "./contactsTableColumns";
import Modal from "../shared/Modal";
import EditCampaign from "../campaigns/EditCampaign";

export default function ContactsTable(props) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [activeSegmentId, setActiveSegmentId] = useState(
    searchParams.has("active_segment_id")
      ? searchParams.get("active_segment_id")
      : null,
  );

  const [dirty, setDirty] = useState(false);
  const {
    loading,
    loadContact,
    loadContacts,
    addToFolder,
    removeFromFolder,
    segments,
    createSegment,
    deleteSegment,
    updateSegment,
  } = useContext(ContactsContext);

  const { editConversationsFolder } = useContext(InboxContext);

  const [contacts, setContacts] = useState([]);

  const handleLoadContacts = async (params) => {
    const res = await loadContacts(params);
    setContacts(res);
  };

  const updateContact = (contact) => {
    setContacts((contacts) =>
      contacts.map((c) => (c.id === contact.id ? { ...c, ...contact } : c)),
    );
  };

  const handleLoadContact = async (contact) => {
    if (contact.loaded) return;
    const res = await loadContact(contact?.id);
    updateContact(res);
  };

  // Filters

  const [filterLogic, setFilterLogic] = useState(
    searchParams.get("filter_logic") || "and",
  );

  const [filters, setFilters] = useState(
    searchParams.has("filters") ? JSON.parse(searchParams.get("filters")) : [],
  );

  const [filterTypeMenuVisible, showFilterTypeMenu] = useState(false);

  const addFilter = (filter) => {
    setFilters((filters) => [...filters, filter]);
    showFilterTypeMenu(false);
  };

  const removeFilter = (index, id) => {
    setFilters((filters) => [
      ...filters.slice(0, index),
      ...filters.slice(index + 1),
    ]);
  };
  const updateFilter = (index, filter) =>
    setFilters((filters) => [
      ...filters.slice(0, index),
      filter,
      ...filters.slice(index + 1),
    ]);

  const clearFilters = () => {
    setSearchQuery("");
    setFilters([]);
  };
  const setFiltersToActiveSegment = () => {
    if (!activeSegment) return;
    setSearchQuery("");
    setFilters(activeSegment?.segment_filters);
    setFilterLogic(activeSegment?.filter_logic);
  };

  // Segments

  const createEmptySegment = () => {
    createSegment(
      {
        title: "segment-" + new Date().getTime(),
      },
      (segment) => {
        setActiveSegmentId(segment.id);
      },
    );
  };

  const createSegmentFromFilters = () => {
    createSegment({
      title: "segment-" + new Date().getTime(),
      filter_logic: filterLogic,
      segment_filters_attributes: filters,
    });
  };

  const updateSegmentFromFilters = () => {
    if (!activeSegment) return;
    const filterAttributes = filters.map((filter) => ({
      ...filter,
      id: null,
    }));
    updateSegment(activeSegment.id, {
      filter_logic: filterLogic,
      segment_filters_attributes: filterAttributes,
    });
  };

  const toggleActiveSegment = (segment) => {
    if (activeSegmentId === segment.id) {
      setActiveSegmentId(null);
    } else {
      setActiveSegmentId(segment.id);
    }
  };

  const handleSegmentDelete = () => {
    if (!activeSegment) return;
    deleteSegment(activeSegment.id, () => {
      setActiveSegmentId(null);
    });
  };

  const segmentTabs = [
    {
      id: "all_contacts",
      title: t("contacts.all_contacts"),
      active: !activeSegmentId,
      onClick: () => setActiveSegmentId(null),
    },
    ...segments.map((segment) => ({
      id: segment.id,
      title: segment.title,
      count: segment.count,
      active: segment.id.toString() === activeSegmentId?.toString(),
      onClick: () => toggleActiveSegment(segment),
    })),
    {
      id: "create_segment",
      title: t("contacts.segments.create_segment"),
      icon: PlusIcon,
      onClick: createEmptySegment,
    },
  ];

  const activeSegment = useMemo(
    () => segments.find((segment) => segment.id == activeSegmentId),
    [activeSegmentId, segments],
  );

  // Search
  const [searchQuery, setSearchQuery] = useState(
    searchParams.get("search") || "",
  );

  // Selection

  const [selectedContactIds, setSelectedContactIds] = useState([]);

  const isSelectedContactId = (contactId) =>
    selectedContactIds.indexOf(contactId) > -1;

  const toggleSelectContactId = (contactId) => {
    if (isSelectedContactId(contactId)) {
      setSelectedContactIds((ids) =>
        uniq(ids.filter((id) => id !== contactId)),
      );
    } else {
      setSelectedContactIds((ids) => uniq([...ids, contactId]));
    }
  };

  const selectedContacts = useMemo(
    () => contacts.filter((contact) => isSelectedContactId(contact.id)),
    [selectedContactIds, contacts],
  );

  const allSelected = useMemo(
    () => contacts.length > 0 && selectedContactIds.length >= contacts.length,
    [selectedContactIds, contacts],
  );

  const handleSelectAll = (evt) => {
    if (allSelected) {
      setSelectedContactIds([]);
    } else {
      setSelectedContactIds(contacts.map((c) => c.id));
    }
  };

  const clearSelection = () => setSelectedContactIds([]);

  const [newCampaign, showNewCampaign] = useState(null);

  const handleSendCampaign = () => {
    showNewCampaign({ contacts: selectedContacts });
  };

  const handleEditFolders = async () => {
    const selectedConversations = selectedContacts.map((contact) => ({
      id: contact.conversation_id,
      folder_ids: contact.folder_ids,
    }));
    editConversationsFolder(selectedConversations, (conversations) => {
      selectedContacts.forEach((contact) => {
        const folder_ids = conversations.find(
          (c) => c.id === contact.conversation_id,
        ).folder_ids;
        updateContact({ ...contact, folder_ids });
      });
      clearSelection();
    });
  };

  const handleAddToFolder = () => {
    addToFolder(selectedContactIds, (contacts) => {
      clearSelection();
      handleLoadContacts({
        filters,
        filter_logic: filterLogic,
        search: searchQuery,
      });
    });
  };

  const handleRemoveFromFolder = () => {
    removeFromFolder(selectedContactIds, (contacts) => {
      clearSelection();
      handleLoadContacts({
        filters,
        filter_logic: filterLogic,
        search: searchQuery,
      });
    });
  };

  const selectionActions = [
    {
      label: t("contacts.clear_selection"),
      action: clearSelection,
    },
    {
      label: t("campaigns.send_campaign"),
      count: selectedContactIds.length,
      action: handleSendCampaign,
    },
    {
      label: t("inbox.conversation.add_to_folder"),
      count: selectedContactIds.length,
      action: handleAddToFolder,
    },
    {
      label: t("inbox.conversation.remove_from_folder"),
      count: selectedContactIds.length,
      action: handleRemoveFromFolder,
    },
  ];

  // Columns

  const handleSort = useCallback(
    (sortLabel) => {
      return (sortDirection) => {
        const newSearchParams = new URLSearchParams(searchParams);

        if (sortDirection === "") {
          newSearchParams.delete("sort_by");
          newSearchParams.delete("sort_direction");
        } else {
          newSearchParams.set("sort_by", sortLabel);
          newSearchParams.set("sort_direction", sortDirection);
        }
        setSearchParams(newSearchParams);
      };
    },
    [filters, filterLogic, searchQuery],
  );

  const columns = [
    {
      label: (
        <TableCellCheckbox
          onClick={handleSelectAll}
          checked={allSelected}
          readOnly
        />
      ),
      accessor: (contact) => (
        <TableCellCheckbox
          onClick={(evt) => {
            evt.stopPropagation();
            toggleSelectContactId(contact.id);
          }}
          checked={isSelectedContactId(contact.id)}
          readOnly
        />
      ),
      className: "w-12 !p-0",
    },
    ...contactsTableColumns(handleSort),
  ];

  // Effects

  // Load contacts on filter change
  useEffect(() => {
    if (loading) return;
    handleLoadContacts({
      filters,
      filter_logic: filterLogic,
      search: searchQuery,
    });
    if (activeSegment) {
      const tmpdirty =
        activeSegment.segment_filters !== filters ||
        activeSegment.filter_logic !== filterLogic;
      setDirty(tmpdirty);
    }
  }, [filters, filterLogic, searchQuery]);

  // Update url search params
  useEffect(() => {
    let params = {};
    if (activeSegmentId) {
      params.active_segment_id = activeSegmentId;
    } else {
      if (filters.length) params.filters = JSON.stringify(filters);
      if (filterLogic) params.filter_logic = filterLogic;
    }
    if (searchQuery) params.search = searchQuery;
    if (searchParams.has("sort_by"))
      params.sort_by = searchParams.get("sort_by");
    if (searchParams.has("sort_direction"))
      params.sort_direction = searchParams.get("sort_direction");

    setSearchParams(params);
  }, [activeSegmentId, filters, filterLogic, searchQuery]);

  useEffect(() => {
    const params = {
      filters,
      filter_logic: filterLogic,
      search: searchQuery,
    };

    if (searchParams.has("sort_by") && searchParams.has("sort_direction")) {
      params.sort_by = searchParams.get("sort_by");
      params.sort_direction = searchParams.get("sort_direction");
    }
    handleLoadContacts(params);
  }, [searchParams.get("sort_by"), searchParams.get("sort_direction")]);

  // Change segment tab
  const firstRender = useRef(true);
  useEffect(() => {
    if (activeSegmentId) {
      setFiltersToActiveSegment();
    } else {
      if (!firstRender.current) clearFilters();
    }
    firstRender.current = false;
  }, [activeSegment]);

  // clear selection on view change
  useEffect(clearSelection, [
    activeSegmentId,
    filters,
    filterLogic,
    searchQuery,
  ]);

  return (
    <>
      <div className="flex-grow flex flex-col overflow-hidden sm:px-1 sm:pb-1 space-y-4">
        <div className="relative border-b border-gray-200 pb-5 sm:pb-0">
          <div className="md:flex md:items-center md:justify-between"></div>
          <div className="mt-4">
            <div className="sm:hidden">
              <label htmlFor="current-tab" className="sr-only">
                Select a segment
              </label>
              <select
                id="current-tab"
                name="current-tab"
                className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                defaultValue={
                  segments.find((segment) => segment.id == activeSegment?.id)
                    ?.title || t("contacts.all_contacts")
                }
                onChange={(evt) => setActiveSegmentId(evt.target.value)}
              >
                <option>{t("contacts.all_contacts")}</option>
                {segments.map((segment) => (
                  <option key={segment.id} value={segment.id}>
                    {segment.title}
                  </option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <Tabs tabs={segmentTabs} />
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start space-y-3">
          {filters.map((filter, index) => (
            <div className="flex items-baseline space-x-2" key={index}>
              {index > 0 ? (
                <select
                  className="input-select"
                  disabled={loading}
                  value={filterLogic}
                  onChange={(evt) => setFilterLogic(evt.target.value)}
                >
                  {["and", "or"].map((logic, index) => (
                    <option value={logic} key={index}>
                      {t(`contacts.filters.logic.${logic}`)}
                    </option>
                  ))}
                </select>
              ) : (
                <div className="text-sm text-darker-gray">
                  {t("contacts.filters.logic.users_who")}
                </div>
              )}
              <ContactFilter
                filter={filter}
                onChange={(filter) => updateFilter(index, filter)}
                onRemove={() => removeFilter(index)}
                disabled={loading}
              />
            </div>
          ))}
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center space-x-3">
              <Input
                placeholder={t("contacts.search_contacts")}
                className="w-48"
                icon={MagnifyingGlassIcon}
                value={searchQuery}
                onChange={setSearchQuery}
                clearButton
                debounce
              />
              <Button
                label={t("contacts.filters.add_filter")}
                disabled={loading}
                onClick={() => showFilterTypeMenu(true)}
              />
              {!activeSegment && filters.length > 0 && (
                <Button
                  label={t("contacts.segments.create_segment")}
                  icon={PlusCircleIcon}
                  disabled={loading}
                  onClick={createSegmentFromFilters}
                />
              )}
            </div>
            <div className="flex space-x-3">
              {activeSegment && (
                <>
                  {activeSegment && dirty ? (
                    <>
                      <Button
                        label={t("contacts.segments.update_segment")}
                        style="primary"
                        onClick={() => updateSegmentFromFilters()}
                      />
                      <Button
                        label={t("contacts.segments.cancel_changes")}
                        onClick={setFiltersToActiveSegment}
                      />
                    </>
                  ) : (
                    <Button
                      label={t("contacts.segments.delete_segment")}
                      style="danger"
                      onClick={handleSegmentDelete}
                    />
                  )}
                  {contacts.length ? (
                    <Button
                      style="primary"
                      icon={PaperAirplaneIcon}
                      label={t("campaigns.send_campaign")}
                      count={contacts.length}
                      onClick={() =>
                        showNewCampaign({
                          destination: `segment_${activeSegment.id}`,
                        })
                      }
                    />
                  ) : null}
                </>
              )}
            </div>
          </div>
        </div>
        <Table
          loading={loading}
          columns={columns}
          items={contacts}
          selectedItemIds={selectedContactIds}
          emptyState={
            <div className="absolute inset-0 flex flex-col items-center justify-center">
              <div className="text-gray-500 text-xl font-medium">
                {t("contacts.no_contacts")}
              </div>
              <Button
                label={t("contacts.filters.remove_filters")}
                className="mt-4"
                onClick={clearFilters}
              />
            </div>
          }
          onRowClick={(contact) =>
            navigate(`/contacts/${contact.conversation_id}`)
          }
          onRowRender={handleLoadContact}
          isSelected={(contact) =>
            selectedContactIds.indexOf(parseInt(contact.id)) > -1
          }
        >
          {selectedContactIds.length > 0 && (
            <TableSelection
              selectedItems={selectedContactIds}
              actions={selectionActions}
            />
          )}
        </Table>
      </div>
      {filterTypeMenuVisible && (
        <FilterTypeMenu
          onClose={() => showFilterTypeMenu(false)}
          onSubmit={addFilter}
        />
      )}
      {newCampaign && (
        <Modal
          onClose={() => showNewCampaign(false)}
          className="h-full !w-full max-w-6xl !p-0"
        >
          <EditCampaign
            initialValues={newCampaign}
            onSend={() => navigate("/campaigns")}
          />
        </Modal>
      )}
    </>
  );
}
