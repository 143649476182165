import React, { useContext, useEffect, useRef, useState } from "react";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import Tooltip from "../../shared/Tooltip";
import { UserContext } from "~/contexts/user-context";

export default function CreateFolder(props) {
  const { onClick } = props;

  const { user } = useContext(UserContext);

  const { t } = useTranslation();

  const ref = useRef();

  return (
    <button
      className={`flex-grow h-full flex items-center px-4 py-2 border-b-2 whitespace-nowrap text-dark-gray`}
      ref={ref}
      onClick={onClick}
    >
      <PlusCircleIcon className="w-4 mr-1.5 mt-0.25" />
      <div>{t("inbox.folders.create")}</div>
      {user.tooltips?.create_folder && (
        <Tooltip
          text={t("inbox.folders.create_tooltip")}
          onClick={onClick}
          position="bottom"
          align="right"
          offset={10}
          parentRef={ref}
        />
      )}
    </button>
  );
}
