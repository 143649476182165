import React, { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  CalendarIcon,
  CurrencyDollarIcon,
  EnvelopeIcon,
  ArrowRightOnRectangleIcon,
} from "@heroicons/react/24/outline";
import {
  CalendarIcon as CalendarIconSolid,
  CurrencyDollarIcon as CurrencyDollarIconSolid,
  EnvelopeIcon as EnvelopeIconSolid,
} from "@heroicons/react/24/solid";
import { identity } from "lodash";

import { appInset, isApp } from "../../utils/environment";
import { UserContext } from "~/contexts/user-context";
import { UIContext } from "~/contexts/ui-context";

export default function MobileTabBar() {
  const location = useLocation();
  const { t } = useTranslation();

  const { user, organization } = useContext(UserContext);
  const { isMediaSm } = useContext(UIContext);

  const tabs = [
    {
      label: t("navigation.inbox"),
      path: "/inbox",
      icon: <EnvelopeIcon className="w-6" />,
      iconActive: <EnvelopeIconSolid className="w-6" />,
      onClick: () => window.dispatchEvent(new CustomEvent("inboxTabClick")),
    },
    {
      label: t("navigation.calendar"),
      path: "/calendar",
      icon: <CalendarIcon className="w-6" />,
      iconActive: <CalendarIconSolid className="w-6" />,
      onClick: () => window.dispatchEvent(new CustomEvent("calendarTabClick")),
    },
    {
      label: t("navigation.payments"),
      path: "/payments",
      icon: <CurrencyDollarIcon className="w-6" />,
      iconActive: <CurrencyDollarIconSolid className="w-6" />,
    },
    {
      label: t("navigation.profile"),
      path: "/profile",
      icon: (
        <img
          className="w-6 h-6 rounded-full mb-0.5"
          src={organization.profile_picture}
        />
      ),
      iconActive: (
        <img
          className="w-6 h-6 rounded-full ring-2 ring-primary mb-0.5"
          src={organization.profile_picture}
        />
      ),
    },
  ].filter(identity);
  // Filter null values

  const activeTab = tabs.findIndex((tab) =>
    location.pathname.startsWith(tab.path),
  );

  return (
    <div
      className={`fixed z-20 inset-x-0 bottom-0 bg-white ${
        isApp ? "sm:mt-8 rounded-tr-xl" : ""
      } sm:static sm:w-20 sm:flex-shrink-0 sm:hidden sm:flex-col sm:justify-between  sm:bg-black`}
      style={{
        paddingBottom: appInset.bottom,
      }}
    >
      <div className="flex relative sm:flex-col sm:h-full sm:justify-start sm:space-y-5 sm:py-5">
        {tabs.map((tab) => (
          <NavLink
            key={tab.label}
            to={tab.path}
            className="h-16 sm:!w-auto"
            onClick={tab.onClick}
            style={{
              width: `${Math.round(100 / tabs.length, 2)}%`,
            }}
          >
            {({ isActive }) => (
              <div
                className={`h-16 w-full flex flex-col items-center justify-center ${
                  isActive
                    ? "text-primary sm:text-white"
                    : "text-neutral-500 sm:text-neutral-400"
                }`}
              >
                {isActive ? tab.iconActive : tab.icon}
                <div className="[font-size:10px] sm:mt-0.5">{tab.label}</div>
              </div>
            )}
          </NavLink>
        ))}
        <div
          className="sm:hidden absolute top-0 left-0 h-0.5 bg-primary transition-transform rounded-sm"
          style={{
            width: `${Math.round(100 / tabs.length, 2)}%`,
            transform: `translateX(${
              (activeTab * window.innerWidth) / tabs.length
            }px)`,
          }}
        />
      </div>
      <a
        href="/sign_out"
        className="hidden sm:flex h-16 flex-col items-center justify-center text-neutral-400 mb-5"
      >
        <ArrowRightOnRectangleIcon className="w-6" />
        <div className="[font-size:10px] mt-0.5">{t("navigation.signout")}</div>
      </a>
    </div>
  );
}
