import React, { useContext, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FlowContext } from "~/contexts/flow-context";
import AutoDelay from "./AutoDelay";
import CustomNode from "./CustomNode";
import { MediaFilesContext } from "~/contexts/media-files-context";
import ImageMessage from "../../../messages/ImageMessage";
import VideoMessage from "../../../messages/VideoMessage";
import Button from "../../../elements/Button";
import { PhotoIcon, ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import Loader from "../../../utils/Loader";

export default function MediaMessageNode(props) {
  const { t } = useTranslation();
  const { editAction } = useContext(FlowContext);
  const { data, selected } = props;
  const { id, options, dimensions } = data;
  const { getMedia, createMedia } = useContext(MediaFilesContext);

  const { media_file_id } = options;
  const [mediaFile, setMedia] = useState(null);

  const fileInputRef = useRef(null);

  useEffect(() => {
    if (media_file_id) {
      getMedia(media_file_id).then((media) => {
        setMedia(media);
        editAction(
          id,
          {
            dimensions: media.media_dimensions,
          },
          false,
        );
      });
    }
  }, [media_file_id]);

  const handleMediaChange = async () => {
    const input = fileInputRef.current;

    if (input.files.length == 0) {
      input.value = null;
      return;
    }

    const file = input.files.item(0);

    const fileType = file.type.split("/")[0];

    if (!["image", "video", "audio"].includes(fileType)) {
      window.alert(t("inbox.conversation.invalid_file_type"));
      input.value = null;
      return;
    }

    const size = Math.round(file.size / 1024);

    const maxSize = {
      image: 8192,
      video: 24576,
      audio: 24576,
    }[fileType];

    if (size > maxSize) {
      window.alert(
        t("inbox.conversation.max_file_size." + fileType, { size: maxSize }),
      );
      input.value = null;
      return;
    }

    const media = await createMedia(file, fileType);
    editAction(id, {
      dimensions: media.media_dimensions,
      options: {
        media_file_id: media.id,
      },
    });

    input.value = null;
  };

  return (
    <CustomNode selected={selected}>
      <div className="relative">
        {media_file_id ? (
          <div
            className={`bg-white rounded-3xl ring-1 ring-indigo-400 ring-offset-1 ring-opacity-0 hover:ring-opacity-100 transition-all ${selected ? "z-50 ring-opacity-100" : ""}`}
          >
            {mediaFile ? (
              mediaFile.media_type === "image" ? (
                <ImageMessage
                  url={mediaFile.media_url}
                  dimensions={dimensions}
                />
              ) : (
                <VideoMessage
                  url={mediaFile.media_url}
                  preview_url={mediaFile.media_preview_url}
                  className="w-40 h-40 max-h-55"
                />
              )
            ) : (
              <div className="py-3 px-4 bg-white border-2 border-medium-gray text-dark-gray border-dashed rounded-2xl cursor-pointer nodrag opacity-75 hover:opacity-100 transition-opacity flex space-x-2 items-center">
                <Loader width={24} strokeWidth={8} />
                <div className="text-md font-medium text-center">
                  {t("automation.scenarios.actions.media_message.loading")}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div
            className={`bg-white rounded-3xl border-2 border-medium-gray border-dashed flex flex-col items-center justify-center p-4 space-y-4 transition-colors hover:border-indigo-500 ${selected ? "z-50 border-indigo-500" : ""}`}
          >
            <PhotoIcon className="w-12 h-12 mx-auto text-gray-300" />
            <p className="text-dark-gray font-xl text-center">
              {t("automation.scenarios.actions.media_message.no_file_uploaded")}
            </p>
            <label>
              <Button
                label={t(
                  "automation.scenarios.actions.media_message.upload_media",
                )}
                icon={ArrowUpTrayIcon}
                onClick={() => fileInputRef.current.click()}
              />
              <input
                type="file"
                accept="image/*,video/*"
                className="hidden"
                name="attachment_file"
                onChange={handleMediaChange}
                ref={fileInputRef}
              />
            </label>
          </div>
        )}
      </div>
    </CustomNode>
  );
}
