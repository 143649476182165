import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { ContactsContext } from "~/contexts/contacts-context";
import headers from "../utils/headers";
import { UIContext } from "~/contexts/ui-context";
import { useTranslation } from "react-i18next";
import { UserContext } from "~/contexts/user-context";
import FolderTag from "../components/inbox/folders/FolderTag";
import { add } from "lodash";

export default function ContactsProvider(props) {
  const { t } = useTranslation();

  const { showDialog, showPrompt, showMenu } = useContext(UIContext);
  const { organization } = useContext(UserContext);

  const [loading, setLoading] = useState(false);

  // Contacts

  const [allContactsCount, setAllContactsCount] = useState(0);
  const loadAllContactsCount = () => {
    axios.get(`/api/contacts/contacts/count`).then((res) => {
      setAllContactsCount(res.data);
    });
  };
  useEffect(loadAllContactsCount, [organization.id]);

  const loadContacts = async (params = {}) => {
    setLoading(true);
    const res = await axios.get(`/api/contacts/contacts`, { params });
    setLoading(false);
    return res.data;
  };

  const loadContactsInfos = async (ids) => {
    if (!ids.length) return;
    setLoading(true);
    const res = axios.get("/api/contacts/contacts/infos", { params: { ids } });
    setLoading(false);
    return res.data;
  };

  const loadContact = async (id) => {
    if (!id) return;
    const res = await axios.get(`/api/contacts/contacts/${id}`);
    setLoading(false);
    return res.data;
  };

  const loadContactInsights = async (id) => {
    const res = await axios.get(`/api/contacts/contacts/${id}/insights`);
    return res.data;
  };

  const loadContactMedias = async (id) => {
    const res = await axios.get(`/api/contacts/contacts/${id}/medias`);
    return res.data;
  };

  // Languages
  const [languages, setLanguages] = useState(["en"]);
  const loadLanguages = () => {
    axios.get("/api/contacts/languages").then((res) => {
      setLanguages(res.data);
    });
  };

  // Folders

  const addToFolder = async (contactIds, callback = (contacts) => {}) => {
    showMenu({
      title: t("inbox.folders.add_to_folder"),
      actions: organization.folders.map((folder) => ({
        label: <FolderTag folder={folder} />,
        action: async () => {
          setLoading(true);
          const res = await axios.patch(
            "/api/contacts/contacts/folder",
            { folder_id: folder.id, ids: contactIds },
            headers(),
          );
          setLoading(false);
          callback(res.data);
        },
      })),
    });
  };

  const removeFromFolder = async (contactIds, callback = (contacts) => {}) => {
    showMenu({
      title: t("inbox.folders.remove_from_folder"),
      actions: organization.folders.map((folder) => ({
        label: <FolderTag folder={folder} />,
        action: async () => {
          setLoading(true);
          const res = await axios.patch(
            "/api/contacts/contacts/folder",
            { folder_id: folder.id, ids: contactIds, remove: true },
            headers(),
          );
          setLoading(false);
          callback(res.data);
        },
      })),
    });
  };

  // Segments

  const [segments, setSegments] = useState([]);

  const loadSegments = () => {
    setLoading(true);
    axios
      .get(`/api/contacts/segments`)
      .then((res) => {
        setLoading(false);
        setSegments(res.data);
      })
      .catch((err) => console.log(err));
  };

  const createSegment = (segment, callback = () => {}) => {
    showDialog({
      label: t("contacts.segments.create_segment"),
      placeholder: "Ambassadors, VIPs, etc.",
      onSubmit: (title) => {
        setLoading(true);
        axios
          .post(
            `/api/contacts/segments`,
            {
              ...segment,
              title,
            },
            headers(),
          )
          .then((res) => {
            setLoading(false);
            setSegments([...segments, res.data]);
            callback(res.data);
          })
          .catch((err) => console.log(err));
      },
    });
  };

  const deleteSegment = (segmentId, callback = () => {}) => {
    const segment = segments.find((s) => s.id === segmentId);
    if (!segment) return;
    showPrompt(
      t("contacts.segments.delete_segment_confirm", { segment: segment.title }),
      () => {
        setLoading(true);
        axios
          .delete(`/api/contacts/segments/${segmentId}`, headers())
          .then((res) => {
            setLoading(false);
            setSegments(res.data);
            callback();
          })
          .catch((err) => console.log(err));
      },
    );
  };

  const updateSegment = (segmentId, data) => {
    const segment = segments.find((s) => s.id === segmentId);
    if (!segment) return;
    setLoading(true);
    axios
      .put(`/api/contacts/segments/${segmentId}`, data, headers())
      .then((res) => {
        setLoading(false);
        const newSegments = segments.map((s) =>
          s.id === segmentId ? res.data : s,
        );
        setSegments(newSegments);
      })
      .catch((err) => console.log(err));
  };

  useEffect(loadSegments, [organization.id]);

  const contextValues = {
    loading,
    allContactsCount,
    loadContacts,
    loadContactsInfos,
    loadContact,
    loadContactInsights,
    loadContactMedias,
    languages,
    loadLanguages,
    addToFolder,
    removeFromFolder,
    segments,
    loadSegments,
    createSegment,
    deleteSegment,
    updateSegment,
  };

  return (
    <ContactsContext.Provider value={contextValues}>
      {props.children}
    </ContactsContext.Provider>
  );
}
