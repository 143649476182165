import React, { useState, useEffect, useContext } from "react";
import { createPortal } from "react-dom";

import Button from "../elements/Button";
import { useTranslation } from "react-i18next";
import PaymentAmountForm from "./PaymentAmountForm";
import { MIN_PAYMENT_AMOUNT } from "../../constants";
import { UserContext } from "~/contexts/user-context";

export default function PaymentModal(props) {
  const { onClose, onConfirm } = props;

  const { t } = useTranslation();

  const { organization } = useContext(UserContext);

  const [visible, setVisible] = useState(false);
  const [inputAmount, setInputAmount] = useState("");

  // animate appear
  useEffect(() => {
    setTimeout(() => setVisible(true), 50);
  }, []);

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 150);
  };

  const handleConfirm = () => {
    onConfirm(inputAmount);
    handleClose();
  };

  const amountValid =
    !!inputAmount && parseFloat(inputAmount) >= MIN_PAYMENT_AMOUNT;

  return createPortal(
    <div
      className={`fixed inset-0 flex flex-col justify-center p-4 select-none ${
        visible ? "pointer-events-auto" : "pointer-events-none"
      }`}
      style={{
        zIndex: 1000,
      }}
    >
      <div
        className={`absolute inset-0 bg-black/30 transition-opacity ${
          visible ? "opacity-100" : "opacity-0"
        }`}
        onClick={handleClose}
      />
      <div
        className={`w-full max-w-md m-auto bg-white p-4 flex flex-col items-center rounded-xl relative transform-gpu transition-all ${
          visible ? "scale-100 opacity-100" : "scale-95 opacity-0"
        }`}
      >
        <div className="mb-5 self-start text-md font-medium">
          {t("payments.modal.title")}
        </div>
        <PaymentAmountForm
          amount={inputAmount}
          setAmount={setInputAmount}
          autoFocus
        />
        {!organization.stripe_charges_enabled && (
          <div className="mt-4 p-4 rounded-lg bg-light-secondary text-center text-md">
            {t("payments.modal.set_up_notice")}
          </div>
        )}
        <div className="w-full flex justify-center space-x-4 mt-6">
          <Button
            label={t("shared.cancel")}
            style="soft"
            onClick={handleClose}
            className="flex-grow"
          />
          {organization.stripe_charges_enabled ? (
            <Button
              label={t("payments.modal.send")}
              style="primary"
              disabled={!amountValid}
              onClick={handleConfirm}
              className="flex-grow"
            />
          ) : (
            <Button
              label={t("payments.modal.set_up_cta")}
              to="/profile/payment_settings"
              className="flex-grow"
            />
          )}
        </div>
      </div>
    </div>,
    document.body,
  );
}
