import React, { useContext } from "react";
import ContactPicture from "../shared/ContactPicture";
import { ChatBubbleBottomCenterIcon } from "@heroicons/react/20/solid";
import RelativeDate from "../utils/RelativeDate";
import Button from "../elements/Button";
import { useTranslation } from "react-i18next";
import { InboxContext } from "~/contexts/inbox-context";
import { useNavigate } from "react-router-dom";
import RepliedIcon from "../inbox/RepliedIcon";
import CommentBubble from "./CommentBubble";

export default function CommentItem(props) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { comment } = props;

  const {
    id,
    contact,
    received_at,
    text,
    message_id,
    conversation_id,
    reply_id,
    reply_text,
    private_repliable,
  } = comment;

  const { commentReplyDialog, privateReplyDialog } = useContext(InboxContext);

  return (
    <div className="w-full px-3 py-3 flex-shrink-0 border-b border-neutral-100 overflow-hidden flex space-x-3 flex-grow items-start justify-between group select-none">
      <ContactPicture contact={contact} />
      {/* Infos */}
      <div className="flex-grow flex flex-col items-start">
        <div
          className={`truncate text-sm font-medium leading-none mb-2`}
          dangerouslySetInnerHTML={{ __html: contact.username }}
        />
        <CommentBubble text={text} />
        {reply_text ? (
          <div className="flex items-center space-x-2 mt-2">
            <RepliedIcon className="rotate-180" />
            <CommentBubble text={reply_text} />
            <div className="text-xs text-neutral-300">
              {t("mentions.reply.your_reply")}
            </div>
          </div>
        ) : null}
      </div>
      {/* Date & status */}
      <div
        className={`flex flex-col flex-shrink-0 justify-between items-end ${
          reply_id ? "" : "group-hover:hidden"
        }`}
      >
        <div className="text-sm font-light text-neutral-300 whitespace-nowrap ml-2 mb-1">
          <RelativeDate date={received_at} />
        </div>
        {message_id ? (
          <RepliedIcon className="w-3 h-3 text-neutral-300 mt-1" />
        ) : null}
      </div>
      {/* Comment reply button */}
      {reply_id ? null : (
        <Button
          label={t("shared.reply")}
          className="hidden group-hover:flex"
          icon={ChatBubbleBottomCenterIcon}
          onClick={() => commentReplyDialog(id)}
          size="small"
        />
      )}
    </div>
  );
}
