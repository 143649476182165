import React, { useMemo } from "react";
import { times } from "lodash";
import PaginationPages from "../../shared/PaginationPages";

export default function TablePagination(props) {
  const {
    currentPage,
    setCurrentPage,
    perPage,
    setPerPage,
    perPageOptions,
    total,
  } = props;

  const numberOfPages = useMemo(
    () => Math.ceil(total / perPage),
    [total, perPage],
  );

  return (
    <div className="flex-shrink-0 flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <button
          onClick={() => setCurrentPage(Math.max(currentPage - 1, 1))}
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Previous
        </button>
        <button
          onClick={() =>
            setCurrentPage(Math.min(currentPage + 1, numberOfPages))
          }
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Next
        </button>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div className="flex items-center space-x-4">
          <p className="text-sm text-gray-700">
            Showing{" "}
            <span className="font-medium">
              {total > 0 ? (currentPage - 1) * perPage + 1 : 0}
            </span>{" "}
            to{" "}
            <span className="font-medium">
              {Math.min(currentPage * perPage, total)}
            </span>{" "}
            of <span className="font-medium">{total}</span> results
          </p>
          <div>
            <select
              className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6"
              onChange={(evt) => setPerPage(evt.target.value)}
              defaultValue={perPage}
            >
              {perPageOptions.map((option) => (
                <option key={option} value={option}>
                  {option} per page
                </option>
              ))}
            </select>
          </div>
        </div>
        {numberOfPages > 1 && (
          <PaginationPages
            onClick={setCurrentPage}
            currentPage={currentPage}
            numberOfPages={numberOfPages}
          />
        )}
      </div>
    </div>
  );
}
