import React, { useContext, useState } from "react";
import FullScreen from "../utils/FullScreen";
import SearchField from "../shared/SearchField";
import Loader from "../utils/Loader";
import { reverse, sortBy } from "lodash";
import { InboxContext } from "~/contexts/inbox-context";
import { useTranslation } from "react-i18next";
import InboxTabs from "../inbox/InboxTabs";
import MentionItem from "./MentionItem";

export default function MentionsInbox() {
  const { loading, mentions } = useContext(InboxContext);

  const { t } = useTranslation();

  // Search
  const [searchQuery, setSearchQuery] = useState("");
  const searchedMentions = searchQuery.length
    ? mentions.filter((mention) =>
        mention.text.toLowerCase().includes(searchQuery.toLowerCase()),
      )
    : mentions;

  return (
    <div className="sm:h-screen sm:flex sm:flex-grow">
      <FullScreen className="sm:min-w-[20rem] sm:max-w-sm sm:flex-grow sm:flex-shrink-0 sm:border-r flex flex-col">
        <div className="bg-white flex items-center space-x-2 px-3 pt-4">
          <SearchField
            value={searchQuery}
            onChange={setSearchQuery}
            onClear={() => setSearchQuery("")}
          />
        </div>
        <div className="px-3 py-3">
          <InboxTabs />
        </div>
        {loading ? (
          <div className="flex items-center justify-center p-6">
            <Loader width={30} strokeWidth={6} />
          </div>
        ) : (
          <div className="flex-grow overflow-auto">
            {reverse(sortBy(searchedMentions, "received_at")).map((mention) => (
              <MentionItem key={mention.id} mention={mention} />
            ))}
          </div>
        )}
      </FullScreen>
    </div>
  );
}
