import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useCopyToClipboard } from "react-use";
import { UIContext } from "~/contexts/ui-context";
import { useTranslation } from "react-i18next";
import Input from "react-phone-number-input/input";
import { isEmpty } from "lodash";
import { DateTime } from "luxon";

export default forwardRef(function LabeledInput(
  {
    name,
    value,
    onChange,
    onFocus,
    label,
    placeholder,
    type = "text",
    autoComplete = "off",
    required = false,
    canCopy = false,
    disabled = false,
    ...props
  },
  ref,
) {
  const { t } = useTranslation();

  const [state, copyToClipboard] = useCopyToClipboard();
  const { showTooltip } = useContext(UIContext);

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  // date field

  const dateRef = useRef([]);

  const handleDateChange = (e, i) => {
    const split = value?.split("/") || ["", "", ""];
    split[i] = e.target.value;
    onChange(split.join("/"));
    if (i < 2 && e.target.value.length >= 2) {
      dateRef.current[i + 1].focus();
    }
  };

  // copy

  const handleCopy = () => {
    copyToClipboard(value);
    showTooltip(t("shared.copied"));
  };

  const renderInput = () => {
    if (type == "tel") {
      return (
        <Input
          ref={ref}
          className="text-md px-4 pt-3 pb-4 border-none focus:ring-transparent focus:border-none outline-none placeholder:text-neutral-400 flex-grow bg-white autofill:appearance-none autofill:!bg-none"
          value={value || ""}
          onChange={onChange}
          placeholder={placeholder}
          onFocus={onFocus}
          autoComplete={autoComplete}
          disabled={disabled}
        />
      );
    } else if (type == "date") {
      return (
        <div className="px-4">
          {["DD", "MM", "YYYY"].map((n, i) => (
            <>
              <input
                name="n"
                ref={(inputRef) => {
                  dateRef.current[i] = inputRef;
                  if (i == 0) ref.current = inputRef;
                }}
                className="text-md pt-3 pb-4 text-center border-none focus:ring-transparent focus:border-none outline-none placeholder:text-neutral-400 bg-white autofill:appearance-none autofill:!bg-none"
                type="text"
                defaultValue={value?.split("/")[i] || ""}
                placeholder={n}
                onChange={(e) => handleDateChange(e, i)}
                disabled={disabled}
                inputMode="numeric"
                style={{
                  width: i == 2 ? "2.8rem" : i == 1 ? "2rem" : "1.5rem",
                }}
                {...props}
              />
              {i < 2 && (
                <span className="text-md mx-0.5 text-neutral-400">/</span>
              )}
            </>
          ))}
        </div>
      );
    } else {
      return (
        <input
          ref={ref}
          name={name}
          id={name}
          className="text-md px-4 pt-3 pb-4 border-none focus:ring-transparent focus:border-none outline-none placeholder:text-neutral-400 flex-grow bg-white autofill:appearance-none autofill:!bg-none"
          value={value || ""}
          type={type}
          placeholder={placeholder}
          onChange={handleChange}
          onFocus={onFocus}
          autoComplete={autoComplete}
          disabled={disabled}
          {...props}
        />
      );
    }
  };

  return (
    <div className="border-b pt-4">
      <label htmlFor={name} className="block px-4 text-2sm">
        {label}
      </label>
      <div className="flex w-full items-center">
        {renderInput()}
        {canCopy && (
          <div className="p-4 flex-shrink-0" onClick={handleCopy}>
            <DocumentDuplicateIcon className="w-5 text-dark-gray" />
          </div>
        )}
      </div>
    </div>
  );
});
