import { sortBy } from "lodash";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { UIContext } from "~/contexts/ui-context";
import Button from "../elements/Button";
import PaymentAmountForm from "../payments/PaymentAmountForm";
import { UserContext } from "~/contexts/user-context";
import { DateTime } from "luxon";

export default function AppointmentConfirm(props) {
  const { slots, handleSubmit, paymentAmount, setPaymentAmount } = props;

  const { t } = useTranslation();

  const { organization } = useContext(UserContext);

  const { keyboardHeight } = useContext(UIContext);

  const [addPayment, setAddPayment] = useState(false);

  const cancelPayment = () => {
    setPaymentAmount(null);
    setAddPayment(false);
  };

  return (
    <div
      className="p-4 transition-all duration-250 ease-in-out space-y-4"
      style={{
        paddingBottom: keyboardHeight,
      }}
    >
      <div className="text-md">{t("calendar.slots.confirm.description")}</div>
      {sortBy(slots, "start_time").map((slot, index) => (
        <div
          key={index}
          className="relative text-center p-2 text-2sm font-medium rounded-lg bg-light-gray mb-2"
        >
          {DateTime.fromISO(slot.start_time).toFormat("EEEE, MMMM d - HH:mm")}
        </div>
      ))}
      {!addPayment && (
        <>
          <Button
            label={t("calendar.slots.confirm.add_payment")}
            className="w-full"
            style="primary"
            onClick={() => setAddPayment(true)}
          />
          <Button
            label={t("calendar.slots.confirm.no_payment")}
            style="soft"
            className="w-full"
            onClick={handleSubmit}
          />
        </>
      )}
      {addPayment && (
        <>
          <div className="bg-light-background rounded-lg p-4 space-y-4">
            <div className="text-md font-medium">
              {t("calendar.slots.confirm.payment_title")}
            </div>
            <div className="text-md">
              {t("calendar.slots.confirm.payment_description")}
            </div>
            <PaymentAmountForm
              amount={paymentAmount}
              setAmount={setPaymentAmount}
              autoFocus
            />
          </div>
          {!organization.stripe_charges_enabled && (
            <div className="p-4 rounded-lg bg-light-secondary text-center text-md">
              {t("payments.modal.set_up_notice")}
            </div>
          )}
          {organization.stripe_charges_enabled ? (
            <Button
              label={t("calendar.slots.confirm.send")}
              style="primary"
              className="w-full"
              onClick={handleSubmit}
            />
          ) : (
            <Button
              label={t("payments.modal.set_up_cta")}
              style="primary"
              to="/profile/payment_settings"
              className="w-full"
            />
          )}
          <Button
            label={t("calendar.slots.confirm.cancel_payment")}
            className="w-full"
            onClick={cancelPayment}
          />
        </>
      )}
      <div className="text-2sm text-center text-dark-gray pb-4">
        {t("calendar.slots.confirm.later_notice")}
      </div>
    </div>
  );
}
