import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";

import Button from "../elements/Button";
import { useTranslation } from "react-i18next";
import formatAmount from "../../utils/formatAmount";
import ShopifyProductPicker from "../ecommerce/ShopifyProductPicker";

export default function CheckoutLinkModal(props) {
  const { onClose, onConfirm, confirmLabel } = props;

  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  const [selectedProducts, setSelectedProducts] = useState([]);

  const [discountForm, setDiscountForm] = useState(false);
  const [discountAmount, setDiscountAmount] = useState();

  const handleChangeAmount = (value) => {
    const amount = parseInt(value);
    if (amount > 100) {
      setDiscountAmount(100);
    } else if (amount < 0) {
      setDiscountAmount(0);
    } else {
      setDiscountAmount(amount);
    }
  };

  // animate appear
  useEffect(() => {
    setTimeout(() => setVisible(true), 50);
  }, []);

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 150);
  };

  const handleConfirm = () => {
    const products = selectedProducts.map((product) => ({
      ...product,
      id: null, // remove shopify id to avoid conflicts
      price: formatAmount(product.price.amount, product.price.currency),
    }));
    onConfirm(products);
    handleClose();
  };

  return createPortal(
    <div
      className={`fixed inset-0 flex flex-col justify-center p-4 select-none ${
        visible ? "pointer-events-auto" : "pointer-events-none"
      }`}
      style={{
        zIndex: 1000,
      }}
    >
      <div
        className={`absolute inset-0 bg-black/30 transition-opacity ${
          visible ? "opacity-100" : "opacity-0"
        }`}
        onClick={handleClose}
      />
      <div
        className={`w-full max-w-lg m-auto bg-white flex flex-col items-stretch rounded-xl relative transform-gpu transition-all ${
          visible ? "scale-100 opacity-100" : "scale-95 opacity-0"
        }`}
      >
        <div className="self-start text-md font-medium p-4">
          {t("checkout.modal.title")}
        </div>
        <ShopifyProductPicker
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
          discount={discountAmount}
        />
        <div className="w-full flex justify-center px-4 pb-4 space-x-4">
          <Button
            label={t("shared.cancel")}
            style="soft"
            onClick={handleClose}
            className="flex-grow"
          />
          <Button
            label={confirmLabel || t("checkout.modal.send")}
            style="primary"
            disabled={!selectedProducts.length}
            onClick={handleConfirm}
            className="flex-grow"
          />
        </div>
      </div>
    </div>,
    document.body,
  );
}
