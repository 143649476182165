import React, { useContext, useState } from "react";
import PageTitle from "../../shared/PageTitle";
import { useTranslation } from "react-i18next";
import { AutomationContext } from "~/contexts/automation-context";
import Button from "../../elements/Button";
import { useNavigate, useParams } from "react-router-dom";
import ConversionLinkDialog from "./ConversionLinkDialog";
import ConversionLinkItem from "./ConversionLinkItem";

export default function ConversionLinksPage() {
  const { t } = useTranslation();

  const { conversionLinks } = useContext(AutomationContext);

  const [newLink, showNewLink] = useState(false);

  const { linkId } = useParams();
  const navigate = useNavigate();

  const activeLink = conversionLinks.find((link) => link.id == linkId);

  return (
    <div className="min-h-screen sm:min-h-0 flex-grow py-3 sm:p-10 overflow-y-auto relative">
      <div className="max-w-3xl space-y-4 sm:space-y-6">
        <div className="px-3 sm:px-0 space-y-4 sm:space-y-6">
          <PageTitle text={t("conversion_links.title")} />
          <div className="flex items-center justify-between">
            <div className="text-md">
              {t("conversion_links.count", { count: conversionLinks.length })}
            </div>
            <Button
              label={t("conversion_links.create")}
              onClick={() => showNewLink(true)}
            />
          </div>
        </div>
        <div className="border-t sm:border-t-0 sm:space-y-3">
          {conversionLinks.map((conversionLink) => (
            <ConversionLinkItem
              key={conversionLink.id}
              conversionLink={conversionLink}
            />
          ))}
        </div>
      </div>
      {newLink || activeLink ? (
        <ConversionLinkDialog
          conversionLink={activeLink}
          onClose={() => {
            showNewLink(false);
            navigate("/automation/conversion_links");
          }}
        />
      ) : null}
    </div>
  );
}
