import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";

import { appInset } from "../../utils/environment";

export default function BottomDrawer(props) {
  const {
    title,
    forceClose = false,
    onClose,
    header = null,
    zIndex = 1000,
    ref,
    children,
    className
  } = props;

  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  const drawerRef = useRef();
  const touchStartRef = useRef();

  const handleTouchStart = (evt) => {
    drawerRef.current.classList.remove("transition-transform");
    drawerRef.current.classList.remove("duration-300");
    touchStartRef.current = evt.changedTouches[0].pageY;
  };

  const handleTouchMove = (evt) => {
    if (!touchStartRef.current) return;
    const touchPosition = evt.changedTouches[0].pageY;
    if (touchPosition > touchStartRef.current) {
      // down
      const distance = touchPosition - touchStartRef.current;
      if (distance < 300) {
        drawerRef.current.style.transform = `translateY(${distance}px)`;
      }
    }
  };

  const handleTouchEnd = (evt) => {
    if (!touchStartRef.current) return;
    drawerRef.current.classList.add("transition-transform");
    drawerRef.current.classList.add("duration-300");
    drawerRef.current.style.transform = null;
    const touchPosition = evt.changedTouches[0].pageY;
    if (touchPosition > touchStartRef.current + 50) {
      handleClose();
    }
    touchStartRef.current = null;
  };

  // animate appear
  useEffect(() => {
    setTimeout(() => setVisible(true), 50);
  }, []);

  // Force clsoe
  useEffect(() => {
    if (forceClose) handleClose();
  }, [forceClose]);

  const handleClose = () => {
    if (!visible) return;
    setVisible(false);
    setTimeout(onClose, 300);
  };

  return createPortal(
    <div
      className="fixed inset-0 flex flex-col justify-end items-center"
      style={{
        zIndex,
        paddingTop: appInset.top + 20,
      }}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div
        className={`absolute inset-0 bg-black/30 transition-opacity duration-300 ${
          visible ? "opacity-100" : "opacity-0"
        }`}
        onClick={handleClose}
      />
      <div
        className={`max-w-md w-full bg-white rounded-t-2xl flex flex-col pt-4 overflow-y-auto transform-gpu transition-transform duration-300 ${
          visible ? "translate-y-0" : "translate-y-full"
        } ${className}`}
        ref={drawerRef}
        style={{
          paddingBottom: appInset.bottom,
        }}
      >
        {header || (
          <div className="flex justify-between items-center px-6 mb-4">
            <div className="text-md font-medium">{title}</div>
            <button onClick={handleClose} className="text-md font-medium">
              {t("shared.close")}
            </button>
          </div>
        )}
        {children}
      </div>
    </div>,
    document.body
  );
}
