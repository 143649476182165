import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import InputCheckbox from "../../../elements/InputCheckbox";
import Button from "../../../elements/Button";
import FacebookIcon from "../../../shared/icons/FacebookIcon";

export default function ConnectInstagramChecklist() {
  const { t } = useTranslation();

  const [checkInstagram, setCheckInstagram] = useState(false);
  const [checkFacebookPage, setCheckFacebookPage] = useState(false);

  return (
    <div className="space-y-6">
      <div className="space-y-2">
        <div className="text-lg leading-6 font-medium">
          {t("onboarding.connect_instagram.connect_title")}
        </div>
        <p className="text-base leading-5">
          <Trans i18nKey="onboarding.connect_instagram.connect_text" />
        </p>
      </div>

      <div className="space-y-4">
        <InputCheckbox
          label={
            <Trans i18nKey="onboarding.connect_instagram.connect_insta_check" />
          }
          value={checkInstagram}
          onClick={setCheckInstagram}
        />
        <InputCheckbox
          label={
            <Trans i18nKey="onboarding.connect_instagram.connect_facebook_check" />
          }
          value={checkFacebookPage}
          onClick={setCheckFacebookPage}
        />
      </div>

      <Button
        href="/users/auth/instagram"
        label={t("onboarding.connect_instagram.connect_cta")}
        style="primary"
        icon={FacebookIcon}
        className="w-full"
        disabled={!checkInstagram || !checkFacebookPage}
      />

      <div className="text-2sm leading-4 text-muted whitespace-pre-line">
        <Trans i18nKey="onboarding.connect_instagram.disclaimer">
          <a
            href="https://developers.facebook.com/docs/development/release/tech-providers"
            target="_blank"
            className="underline"
          >
            Tech Provider
          </a>
          <a
            href="https://developers.facebook.com/terms"
            target="_blank"
            className="underline"
          >
            Developer Platform Terms
          </a>
        </Trans>
      </div>
    </div>
  );
}
