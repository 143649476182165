import React, { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { PlusCircleIcon } from "@heroicons/react/20/solid";

import { UserContext } from "~/contexts/user-context";
import { InboxContext } from "~/contexts/inbox-context";

import FolderTag from "./FolderTag";
import SkeletonLoader from "~/components/utils/SkeletonLoader";

export default function ConversationFolders(props) {
  const { short = false, conversation = {}, loading, className } = props;

  const { t } = useTranslation();

  const { organization } = useContext(UserContext);
  const { editConversationsFolder } = useContext(InboxContext);

  const [localConversation, setLocalConversation] = useState(conversation);

  useEffect(() => {
    setLocalConversation((localConversation) => ({
      ...localConversation,
      folder_ids: conversation?.folder_ids || [],
    }));
  }, [conversation?.folder_ids]);

  useEffect(() => {
    setLocalConversation(conversation);
  }, [conversation?.id]);

  const editFolders = () => {
    editConversationsFolder([localConversation], (conversations) => {
      setLocalConversation((localConversation) => ({
        ...localConversation,
        ...conversations[0],
      }));
    });
  };

  const folders = organization.folders.filter((f) =>
    localConversation?.folder_ids?.includes(f.id),
  );

  if (short) {
    return (
      <button
        className={`flex items-center space-x-2 mt-2 flex-nowrap ${className}`}
        onClick={editFolders}
      >
        {folders.length > 0 ? (
          <FolderTag folder={folders[0]} />
        ) : (
          <div className="flex items-center text-2xs text-dark-gray bg-light-gray rounded-full px-2 py-1 whitespace-nowrap flex-shrink-0">
            <PlusCircleIcon className="w-3.5 mr-1" />
            <div>{t("inbox.conversation.add_to_folder")}</div>
          </div>
        )}
        {folders.length > 1 ? (
          <div
            className={`text-2xs font-normal text-dark-gray bg-light-gray rounded-full px-2 py-1 whitespace-nowrap flex-shrink-0`}
          >
            +{folders.length - 1}
          </div>
        ) : null}
      </button>
    );
  } else {
    return (
      <button
        className={`flex items-center space-x-2 mt-2 flex-nowrap overflow-auto scrollbar-hide ${className}`}
        onClick={editFolders}
      >
        {loading ? (
          <SkeletonLoader width={160} height={22} />
        ) : (
          <>
            {folders.map((folder) => (
              <FolderTag key={folder.id} folder={folder} />
            ))}
            {(organization.folders.length || 1) > folders.length ? (
              <div className="flex items-center text-2xs text-dark-gray bg-light-gray rounded-full px-2 py-1 whitespace-nowrap flex-shrink-0">
                <PlusCircleIcon className="w-3.5 mr-1" />
                <div>{t("inbox.conversation.add_to_folder")}</div>
              </div>
            ) : null}
          </>
        )}
      </button>
    );
  }
}
