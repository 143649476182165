import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "~/contexts/user-context";
import Modal from "../../shared/Modal";
import filterTypes from "./filterTypes";

export default function FilterTypeMenu(props) {
  const { t } = useTranslation();
  const { onSubmit, onClose } = props;
  const [visible, setVisible] = useState(false);
  const { organization } = useContext(UserContext);
  const { features } = organization;

  useEffect(() => {
    const escapeKey = (evt) => {
      if (evt.key == "Escape") handleClose(evt);
    };
    setTimeout(() => setVisible(true), 50);
    window.addEventListener("keydown", escapeKey);
    return () => window.removeEventListener("keydown", escapeKey);
  }, []);

  const handleClose = (evt) => {
    evt.preventDefault();
    setVisible(false);
    setTimeout(onClose, 150);
  };

  const renderFilter = (filter) => {
    const Icon = filter.icon;
    return (
      <button
        key={filter.filter_type}
        className={`border-2 border-black border-opacity-20 hover:border-opacity-40 rounded-lg flex items-center space-x-3 px-3 py-2.5 w-60 transition-all disabled:opacity-30 disabled:pointer-events-none`}
        onClick={() => handleClickFilter(filter)}
        disabled={filter.disabled}
      >
        <div className="flex-shrink-0">
          <Icon className="w-4" />
        </div>
        <div className="font-medium text-left">
          {t(`contacts.filters.categories.${filter.filter_type}.title`)}
        </div>
      </button>
    );
  };

  const handleClickFilter = (filter) => {
    // remove icon from filter passed to onSubmit
    const { icon, disabled, ...filterValues } = filter;
    onSubmit(filterValues);
    onClose();
  };

  return (
    <Modal onClose={onClose}>
      <div className="flex flex-col space-y-4 p-3 pb-6 sm:p-4">
        <div>
          <div className="font-medium text-md mb-3">Engagement</div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
            {filterTypes.engagementHistory.map(renderFilter)}
          </div>
        </div>
        <div>
          <div className="font-medium text-md mb-3">Transaction</div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
            {filterTypes.transactionHistory.map(renderFilter)}
          </div>
        </div>
        <div>
          <div className="font-medium text-md mb-3">Automation</div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
            {filterTypes.automationHistory.map(renderFilter)}
          </div>
        </div>
        <div>
          <div className="font-medium text-md mb-3">Contact Info</div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
            {filterTypes.contactInfo.map(renderFilter)}
          </div>
        </div>
      </div>
    </Modal>
  );
}
