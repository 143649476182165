import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isApp } from "../../utils/environment";

import {
  ArrowPathRoundedSquareIcon,
  ShareIcon,
} from "@heroicons/react/20/solid";

import {
  ChatBubbleLeftEllipsisIcon,
  StarIcon,
} from "@heroicons/react/24/outline";

import { UserContext } from "~/contexts/user-context";

import ContactHeader from "../contacts/ContactHeader";
import FullScreen from "../utils/FullScreen";
import MenuTitle from "./MenuTitle";
import MenuItem from "./MenuItem";

import NotificationsPage from "./NotificationsPage";
import PersonalAccountPage from "./PersonalAccountPage";
import OrganizationAccountPage from "./OrganizationAccountPage";

import PaymentSettings from "../payments/settings/PaymentSettings";
import BalancePage from "../payments/BalancePage";

import reactNativeMessage from "../../utils/reactNativeMessage";
import ScreenSlide from "../utils/ScreenSlide";
import PromoCodesPage from "./PromoCodesPage";
import BankDetails from "../payments/BankDetails";
import CalendarSettings from "../calendar/CalendarSettings";
import OrganizationUsersPage from "./OrganizationUsersPage";

const SHARE_URL = "https://www.inro.social?utm_source=share&utm_medium=app";

export default function ProfilePage() {
  const { t } = useTranslation();

  const { user, organization, switchOrganization } = useContext(UserContext);

  const { path } = useParams();

  const pages = {
    personal_account: <PersonalAccountPage />,
    organization_account: <OrganizationAccountPage />,
    organization_users: <OrganizationUsersPage />,
    notifications: <NotificationsPage />,
    payment_settings: <PaymentSettings />,
    calendar_settings: <CalendarSettings />,
    bank_details: <BankDetails />,
    promo_codes: <PromoCodesPage />,
    balance: <BalancePage />,
  };

  const handleShare = () => {
    if (isApp) {
      reactNativeMessage({ share: SHARE_URL + "&utm_medium=app" });
    } else if (navigator.share) {
      navigator.share({ url: SHARE_URL + "&utm_medium=website" });
    }
  };

  return (
    <div className="sm:h-screen sm:flex sm:flex-grow">
      <FullScreen className="sm:w-96 sm:border-r flex flex-col">
        <div className="p-4 flex-shrink-0">
          {/* <PageTitle text={t("profile.title")} className="mb-4" /> */}
          <ContactHeader contact={user} large />
        </div>
        <div className="flex-grow overflow-auto scrollbar-hide pb-20">
          {/* Account */}
          <MenuTitle title={t("profile.account")} className="mb-6" />
          <MenuItem
            label={t("profile.personal_account")}
            to="/profile/personal_account"
          />
          <MenuItem
            label={t("profile.organization_account")}
            to="/profile/organization_account"
          />
          <MenuItem
            label={t("profile.organization_users.title")}
            to="/profile/organization_users"
            className="border-b"
          />
          {isApp && (
            <MenuItem
              label={t("notifications.title")}
              to="/profile/notifications"
              className="border-b border-t-0"
            />
          )}

          <MenuItem
            label={t("profile.switch_organization")}
            onClick={switchOrganization}
            icon={<ArrowPathRoundedSquareIcon className="w-4 h-4 mr-2" />}
            className="mt-12 border-b"
          />

          {/* Payments */}

          {organization.features.calendar || organization.features.payments ? (
            <MenuTitle title={t("profile.business")} className="mt-8 mb-6" />
          ) : null}
          {organization.features.calendar && (
            <MenuItem
              label={t("calendar.settings.title")}
              to="/profile/calendar_settings"
            />
          )}
          {organization.features.payments && (
            <>
              <MenuItem
                label={t("payments.settings.title")}
                to="/profile/payment_settings"
              />
              <MenuItem
                label={t("profile.balance")}
                to="/profile/balance"
                className="border-b"
              />
            </>
          )}
          {/* Contact us */}
          <MenuItem
            label={t("profile.contact_us")}
            href="https://ig.me/m/inroapp"
            icon={<ChatBubbleLeftEllipsisIcon className="w-4 mr-1.5" />}
            className="mt-12"
          />
          {/* Share */}
          {isApp && (
            <MenuItem
              label={t("profile.share")}
              onClick={handleShare}
              icon={<ShareIcon className="w-4 h-4 mr-2" />}
              arrow={false}
            />
          )}
          {isApp && (
            <MenuItem
              label={t("shared.request_review_action")}
              icon={<StarIcon className="w-4 h-4 mr-2" />}
              onClick={() => reactNativeMessage({ requestStoreReview: true })}
              arrow={false}
            />
          )}
          <MenuItem
            label={t("profile.privacy_policy")}
            href="https://www.iubenda.com/privacy-policy/66567077"
            arrow={false}
            external
            className="border-b"
          />
          <a href="/sign_out" className="mt-12 block p-4 text-2sm underline">
            {t("profile.log_out")}
          </a>
        </div>
      </FullScreen>
      {/* Pages */}
      <ScreenSlide
        visible={!!pages[path]}
        origin="/profile"
        desktopInline
        stackDepth={1}
        className="sm:border-r sm:max-w-md"
      >
        {pages[path]}
      </ScreenSlide>
    </div>
  );
}
