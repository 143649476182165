import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { UIContext } from "~/contexts/ui-context";
import { UserContext } from "~/contexts/user-context";

import reactNativeMessage from "../../utils/reactNativeMessage";

import Button from "../elements/Button";
import PageHeader from "../shared/PageHeader";
import FullScreen from "../utils/FullScreen";
import { CogIcon } from "@heroicons/react/24/outline";
import MenuItem from "./MenuItem";
import { useParams } from "react-router-dom";
import BusinessNotificationsPage from "./notifications/BusinessNotificationsPage";
import FolderNotificationsPage from "./notifications/FolderNotificationsPage";
import ScreenSlide from "../utils/ScreenSlide";
import InboxNotificationsPage from "./notifications/InboxNotificationsPage";
import PermissionCheck from "./notifications/PermissionCheck";

export default function NotificationsPage(props) {
  const { back } = props;

  const { t } = useTranslation();

  const { user } = useContext(UserContext);

  const { subpath } = useParams();

  const pages = {
    inbox: <InboxNotificationsPage />,
    folders: <FolderNotificationsPage />,
    business: <BusinessNotificationsPage />,
  };

  return (
    <FullScreen>
      <PageHeader
        title={t("notifications.title")}
        back={back}
        className="mb-4"
      />
      <PermissionCheck />
      <MenuItem
        label={t("notifications.inbox_title")}
        description={t("notifications.inbox_description")}
        to="/profile/notifications/inbox"
      />
      <MenuItem
        label={t("notifications.folders_title")}
        description={t("notifications.folders_description")}
        to="/profile/notifications/folders"
      />
      <MenuItem
        label={t("notifications.business_title")}
        description={t("notifications.business_description")}
        to="/profile/notifications/business"
      />
      {/* Pages */}
      <ScreenSlide
        visible={!!pages[subpath]}
        origin="/profile/notifications"
        stackDepth={2}
      >
        {pages[subpath]}
      </ScreenSlide>
    </FullScreen>
  );
}
