import {
  ArrowPathIcon,
  EllipsisHorizontalIcon,
  LinkIcon,
  PlusCircleIcon,
} from "@heroicons/react/20/solid";

import { timeZones } from "@ultirequiem/timezones";

import axios from "axios";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { UIContext } from "~/contexts/ui-context";
import { UserContext } from "~/contexts/user-context";
import headers from "../../utils/headers";
import Button from "../elements/Button";
import ProfileSectionTitle from "../profile/ProfileSectionTitle";
import PageHeader from "../shared/PageHeader";
import FullScreen from "../utils/FullScreen";
import ConnectedCalendar from "./ConnectedCalendar";
import { groupBy } from "lodash";
import { useLocation } from "react-router-dom";
import ProfileSelect from "../profile/ProfileSelect";
import ProfileToggle from "../profile/ProfileToggle";
import InputField from "../shared/InputField";

export default function CalendarSettings(props) {
  const { back } = props;

  const { organization, updateOrganization, setOrganization } =
    useContext(UserContext);
  const { showMenu, showAlert } = useContext(UIContext);

  const { t } = useTranslation();

  const handleSave = () => {
    updateOrganization({
      appointment_reminder: reminder,
      appointment_phone_required: phoneRequired,
      terms_active: termsActive,
      terms_url: termsUrl,
      time_zone: timeZone,
    });
  };

  const handleBack = () => {
    if (
      reminder != organization.appointment_reminder ||
      phoneRequired != organization.appointment_phone_required ||
      termsActive != organization.terms_active ||
      termsUrl != organization.terms_url
    ) {
      showAlert({
        title: t("shared.unsaved_changes"),
        confirm: t("shared.discard_changes"),
        confirmClassName: "text-red-500",
        cancelClassName: "text-black",
        onSubmit: back,
      });
    } else {
      back();
    }
  };

  // Calendars

  const [calendars, setCalendars] = useState(organization.connected_calendars);

  const showAccountMenu = (account) => {
    showMenu({
      title: account.google_calendar_email,
      actions: [
        {
          label: t("calendar.settings.google_calendar.refresh"),
          action: refreshCalendars,
        },
        {
          label: t("calendar.settings.google_calendar.remove"),
          action: () => removeAccount(account.id),
          className: "text-red-500",
        },
      ],
    });
  };

  const removeAccount = (calendarId) => {
    axios
      .delete(`/api/calendar_accounts/${calendarId}`, headers())
      .then((res) => {
        setOrganization(res.data);
        setCalendars(res.data.connected_calendars);
      });
  };

  const [refreshing, setRefreshing] = useState(false);
  const refreshCalendars = () => {
    setRefreshing(true);
    axios.get("/api/calendar/calendars/refresh").then((res) => {
      setOrganization(res.data);
      setCalendars(res.data.connected_calendars);
      setRefreshing(false);
    });
  };

  const updateCalendar = (calendar) => {
    setCalendars((calendars) =>
      calendars.map((c) => (c.id == calendar.id ? calendar : c)),
    );
    axios
      .patch("/api/calendar/calendars/" + calendar.id, { calendar }, headers())
      .then((res) => {
        setOrganization(res.data);
        setCalendars(res.data.connected_calendars);
      });
  };

  const toggleDefault = (id) => {
    showAlert({
      title: t("calendar.calendars.make_primary_title"),
      message: t("calendar.calendars.make_primary_description"),
      onSubmit: () => {
        setCalendars((calendars) =>
          calendars.map((c) => ({ ...c, primary: c.id == id })),
        );
        axios
          .patch(
            "/api/calendar/calendars/" + id + "/set_primary",
            null,
            headers(),
          )
          .then((res) => {
            setOrganization(res.data);
            setCalendars(res.data.connected_calendars);
          });
      },
    });
  };

  const groupedCalendars = groupBy(calendars, "calendar_account_id");

  // Time zone

  const [timeZone, setTimeZone] = useState(organization?.time_zone);
  const timeZoneOptions = timeZones.map((f) => ({
    label: f,
    value: f,
  }));

  // Reminder

  const [reminder, setReminder] = useState(organization?.appointment_reminder);
  const reminderOptions = [
    "disabled",
    "morning",
    "yesterday",
    "four_days_before",
  ].map((f) => ({
    label: t("calendar.settings.reminder." + f),
    value: f,
    disabled: f == "disabled",
  }));

  // Phone number

  const [phoneRequired, setPhoneRequired] = useState(
    organization?.appointment_phone_required,
  );

  // Terms
  const [termsActive, setTermsActive] = useState(organization?.terms_active);
  const [termsUrl, setTermsUrl] = useState(organization?.terms_url || "");

  return (
    <FullScreen>
      <PageHeader
        title={t("calendar.settings.title")}
        back={handleBack}
        className="mb-4"
        action={{
          label: t("shared.save"),
          onClick: handleSave,
        }}
      />
      <ProfileSectionTitle
        label={t("calendar.settings.google_calendar.title")}
        subtitle={t("calendar.settings.google_calendar.description")}
      />
      {organization.calendar_accounts.length > 0 ? (
        <div className="px-4 space-y-4">
          {organization.calendar_accounts.map((account) => (
            <div key={account.id}>
              <div className="flex items-center justify-between mb-2">
                <div className="text-sm font-medium">
                  {account.google_calendar_email}
                </div>
                {refreshing ? (
                  <ArrowPathIcon
                    onClick={refreshCalendars}
                    className={`flex-shrink-0 w-5 text-primary ${
                      refreshing && "animate-spin"
                    }`}
                  />
                ) : (
                  <button
                    className="block px-1 bg-light-gray rounded-lg transition-colors"
                    onClick={() => showAccountMenu(account)}
                  >
                    <EllipsisHorizontalIcon className="w-5 text-darker-gray" />
                  </button>
                )}
              </div>
              {groupedCalendars[account.id]?.map((calendar) => (
                <ConnectedCalendar
                  key={calendar.id}
                  calendar={calendar}
                  updateCalendar={updateCalendar}
                  toggleDefault={toggleDefault}
                />
              ))}
            </div>
          ))}
        </div>
      ) : null}
      <div className="px-4 pt-4 pb-6">
        <Button
          label={t("calendar.settings.google_calendar.add")}
          icon={PlusCircleIcon}
          href="/users/auth/google_calendar"
        />
      </div>
      <ProfileSelect
        value={timeZone}
        onChange={setTimeZone}
        options={timeZoneOptions}
        label={t("calendar.settings.time_zone.title")}
        description={t("calendar.settings.time_zone.description")}
        className="border-t"
      />
      <ProfileSelect
        value={reminder}
        onChange={setReminder}
        options={reminderOptions}
        label={t("calendar.settings.reminder.title")}
        description={t("calendar.settings.reminder.description")}
        className="border-t"
      />
      <ProfileToggle
        label={t("calendar.settings.phone.title")}
        description={t("calendar.settings.phone.description")}
        value={phoneRequired}
        onChange={setPhoneRequired}
      />
      <ProfileToggle
        label={t("calendar.settings.terms.title")}
        description={t("calendar.settings.terms.description")}
        value={termsActive}
        onChange={setTermsActive}
      />
      <div className="px-4 pb-4">
        <InputField
          icon={<LinkIcon className="w-5 h-5 text-neutral-400" />}
          placeholder={t("calendar.settings.terms.input")}
          type="url"
          value={termsUrl}
          onChange={setTermsUrl}
          autoComplete="url"
        />
      </div>
      <div className="h-12" />
    </FullScreen>
  );
}
