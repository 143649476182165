import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import PageHeader from "../shared/PageHeader";
import { appInset } from "../../utils/environment";
import RelativeDate from "../utils/RelativeDate";
import axios from "axios";
import { InboxContext } from "~/contexts/inbox-context";
import { ArchiveBoxArrowDownIcon } from "@heroicons/react/20/solid";
import { UIContext } from "~/contexts/ui-context";

export default function SpamMessages(props) {
  const { back } = props;

  const { t } = useTranslation();

  const { showAlert } = useContext(UIContext);
  const { updateConversations } = useContext(InboxContext);

  const [spamConversations, setSpamConversations] = useState([]);
  useEffect(() => {
    axios
      .get("/api/inbox/conversations?spam=true")
      .then((res) => setSpamConversations(res.data));
  }, []);

  const removeFromSpam = (id) => {
    showAlert({
      title: t("inbox.conversation.remove_from_spam_confirm"),
      confirm: t("shared.confirm"),
      onSubmit: () => {
        updateConversations([id], { spam: false });
        setSpamConversations(spamConversations.filter((c) => c.id !== id));
      },
    });
  };

  return (
    <div
      className={`h-full flex flex-col overflow-hidden`}
      style={{
        paddingTop: appInset.top,
      }}
    >
      <PageHeader title={t("inbox.settings.spam_messages")} back={back} />
      <div className="flex-grow overflow-auto pb-24 sm:pb-12 hide-scrollbar border-t">
        {spamConversations.map((conversation) => (
          <div
            className="w-full border-b border-neutral-100 py-2 px-4 flex justify-between cursor-pointer group sm:hover:bg-neutral-100"
            key={conversation.id}
          >
            <div className="flex flex-shrink items-center flex-grow overflow-hidden">
              <img
                className="w-8 h-8 rounded-full mr-3"
                src={conversation.contact.profile_picture || "false_url"}
              />
              <div className="truncate">
                <div className="text-sm leading-tight flex items-center space-x-2">
                  <div>
                    {conversation.contact.name || conversation.contact.username}
                  </div>
                </div>
                <div className="text-xs text-dark-gray truncate">
                  {conversation.excerpt}
                </div>
              </div>
            </div>
            <ArchiveBoxArrowDownIcon
              className="w-5 text-dark-gray ml-2"
              onClick={() => removeFromSpam(conversation.id)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
