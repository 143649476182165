import React, { useContext } from "react";
import { AutomationContext } from "../../../contexts/automation-context";
import TemplateMessage from "../../messages/TemplateMessage";

export default function SurveyAction(props) {
  const { action } = props;

  const { surveys } = useContext(AutomationContext);

  const survey = surveys.find((s) => s.id === action?.options?.survey_id);

  const templatePayload = {
    title: survey?.title,
    subtitle: survey?.subtitle,
    buttons: survey?.survey_options,
  };

  if (!survey) return null;

  return <TemplateMessage template={templatePayload} />;
}
