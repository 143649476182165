import React, { useCallback, useContext, useEffect, useState } from "react";
import SearchField from "../shared/SearchField";
import ShopifyProductCard from "./ShopifyProductCard";
import { ShopifyContext } from "~/contexts/shopify-context";
import { useTranslation } from "react-i18next";
import Loader from "../utils/Loader";
import InputField from "../shared/InputField";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import Input from "../elements/Input";

export default function ShopifyProductPicker(props) {
  const { selectedProducts, setSelectedProducts, discount } = props;

  const { t } = useTranslation();

  const { loading, loadProducts } = useContext(ShopifyContext);

  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const toggleSelectProduct = (product) => {
    if (selectedProducts.some((p) => p.id === product.id)) {
      setSelectedProducts(selectedProducts.filter((p) => p.id !== product.id));
      return;
    } else {
      setSelectedProducts([...selectedProducts, product]);
    }
  };

  const handleLoadProducts = useCallback(
    async (searchQuery) => {
      const res = await loadProducts(searchQuery);
      setProducts(res);
    },
    [setProducts],
  );

  useEffect(() => handleLoadProducts(searchQuery), [searchQuery]);

  return (
    <div className="flex flex-col items-stretch">
      <div className="px-4">
        <Input
          icon={MagnifyingGlassIcon}
          placeholder={t("commerce.products.search")}
          value={searchQuery}
          onChange={setSearchQuery}
          loading={loading}
          debounce
        />
      </div>
      {products?.length ? (
        <div className="flex overflow-auto flex-nowrap space-x-4 p-4 scrollbar-hide">
          {products.map((product) => (
            <ShopifyProductCard
              key={product.id}
              product={product}
              selected={selectedProducts.some((p) => p.id === product.id)}
              onClick={() => toggleSelectProduct(product)}
              discount={discount}
            />
          ))}
        </div>
      ) : loading ? (
        <div className="p-6 flex items-center justify-center">
          <Loader strokeWidth={8} className="w-8" />
        </div>
      ) : (
        <div className="p-6 text-center text-dark-gray">
          {t("commerce.products.not_found")}
        </div>
      )}
    </div>
  );
}
