import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

export default function InboxTabs(props) {
  const { t } = useTranslation();

  const tabs = [
    {
      name: t("inbox.title"),
      path: "/inbox/conversations",
    },
    {
      name: t("comments.title"),
      path: "/inbox/comments",
    },
    // {
    //   name: t("mentions.title"),
    //   path: "/inbox/mentions",
    // },
  ];

  return (
    <div
      className={`grid grid-cols-${tabs.length} gap-1.5 p-1.5 bg-lighter-gray rounded-xl`}
    >
      {tabs.map((tab, index) => (
        <NavLink key={index} to={tab.path}>
          {({ isActive }) => (
            <div
              className={`rounded-lg h-8 flex items-center justify-center space-x-2 ${
                isActive
                  ? "bg-white"
                  : "hover:bg-white hover:bg-opacity-30 opacity-75 hover:opacity-100 transition-opacity"
              }`}
            >
              <div className="font-medium text-md">{tab.name}</div>
              {tab.count ? (
                <div
                  className={`px-1 py-0.25 bg-light-gray rounded text-2sm transition-colors`}
                >
                  {tab.count}
                </div>
              ) : null}
            </div>
          )}
        </NavLink>
      ))}
    </div>
  );
}
