import { PauseIcon, PlayIcon } from "@heroicons/react/20/solid";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { AutomationContext } from "~/contexts/automation-context";
import { UIContext } from "~/contexts/ui-context";

import validateAction from "../flow/validateAction";

import scenarioStatusIcon from "../../../utils/scenarioStatusIcon";
import Button from "../../elements/Button";
import Toggle from "../../shared/Toggle";
import InvisibleInput from "../../utils/InvisibleInput";
import Loader from "../../utils/Loader";

import { isMobile } from "react-device-detect";
import { DirtyContext } from "~/contexts/dirty-context";
import FlowWrapper from "../flow/FlowWrapper";
import Flow from "../flow/Flow";
import { Panel } from "reactflow";
import validateTrigger from "../flow/triggers/validateTrigger";
import { every } from "lodash";
import { UserContext } from "../../../contexts/user-context";

export default function Scenario(props) {
  const { scenarioId } = props;

  const { t } = useTranslation();

  const { user } = useContext(UserContext);
  const { showPrompt } = useContext(UIContext);
  const { dirty, setDirty } = useContext(DirtyContext);

  const { updateScenario, loadScenario, loading, loadingScenario } =
    useContext(AutomationContext);

  const [initialScenario, setInitialScenario] = useState({});
  const [scenario, setScenario] = useState({});

  const handleScenarioChange = (field, value) => {
    setScenario({ ...scenario, [field]: value });
    setDirty(true);
  };

  const handleInputChange = (evt) => {
    handleScenarioChange(evt.target.name, evt.target.value);
  };

  const handleToggleScenario = (active) => {
    if (!scenarioId) return;

    showPrompt(
      active
        ? t("automation.scenarios.start_confirm")
        : t("automation.scenarios.pause_confirm"),
      async () => {
        setScenario(await updateScenario(scenario.id, { active }));
      },
    );
  };

  const loadScenarioData = useCallback(async () => {
    if (!scenarioId) return;
    const res = await loadScenario(scenarioId);

    setInitialScenario(res);
    setScenario(res);
    setInitialActions(res.actions);
    setActions(res.actions);
    setInitialTriggers(res.triggers);
    setTriggers(res.triggers);
    setDirty(false);
  }, [scenarioId]);

  // Trigger

  const [initialTriggers, setInitialTriggers] = useState([]);
  const [triggers, setTriggers] = useState([]);

  // Actions

  const [initialActions, setInitialActions] = useState([]);
  const [actions, setActions] = useState([]);

  // save scenario

  const handleSave = useCallback(async () => {
    if (!isValid) return;

    const res = await updateScenario(scenarioId, {
      ...scenario,
      actions,
      triggers_attributes: triggers.map((trigger) => {
        if (trigger.id.toString().includes("temp_")) {
          return { ...trigger, id: null };
        } else {
          return trigger;
        }
      }),
    });

    setScenario(res);
    setInitialScenario(res);
    setActions(res.actions);
    setInitialActions(res.actions);
    setTriggers(res.triggers);
    setInitialTriggers(res.triggers);
    setDirty(false);

    if (!scenario.active) {
      handleToggleScenario(true);
    }
  }, [scenario, actions, triggers]);

  const handleCancel = () => {
    setScenario(initialScenario);
    setActions(initialActions);
    setTriggers(initialTriggers);
    setDirty(false);
  };

  // On page change
  useEffect(loadScenarioData, [scenarioId]);

  const validateSave = useCallback((scenario, actions, triggers) => {
    const isValid = every([
      scenario.title?.length,
      triggers.every(validateTrigger),
      actions.every(validateAction),
    ]);
    return isValid;
  }, []);

  const isValid = useMemo(
    () => validateSave(scenario, actions, triggers),
    [scenario, actions, triggers],
  );

  const options = useMemo(
    () =>
      user.admin
        ? ["one_shot", "interrupt_on_message", "is_template"]
        : ["one_shot", "interrupt_on_message"],
    [user],
  );

  const renderOptions = useCallback(
    () => (
      <>
        {options.map((field) => (
          <div className="flex items-center space-x-2" key={field}>
            <Toggle
              value={scenario[field] || false}
              onChange={(value) => handleScenarioChange(field, value)}
            />
            <div className="text-md">
              {t("automation.scenarios.settings." + field)}
            </div>
          </div>
        ))}
      </>
    ),
    [scenario, handleScenarioChange],
  );

  const renderSaveButtons = useCallback(
    () => (
      <>
        <Button label={t("shared.cancel_changes")} onClick={handleCancel} />
        <Button
          label={t("automation.scenarios.save")}
          style="primary"
          disabled={!isValid}
          onClick={handleSave}
          loading={loading}
        />
      </>
    ),
    [handleSave, handleCancel, loading],
  );

  return (
    <div className="w-full flex-grow flex flex-col">
      <div className="p-3 sm:p-4 bg-white border-b flex-shrink-0 flex justify-between items-center">
        <div className="flex space-x-2 items-center">
          {scenarioStatusIcon(scenario.active)}
          <InvisibleInput
            name="title"
            placeholder={t("automation.scenarios.title_placeholder")}
            value={scenario?.title}
            onChange={handleInputChange}
            autoFocus={!scenarioId}
            className="text-medium p-1 placeholder:italic border-none outline-none bg-transparent"
          />
        </div>
        <div className="flex space-x-2">
          {scenarioId ? (
            scenario.active ? (
              <Button
                label={t("automation.scenarios.pause")}
                onClick={() => handleToggleScenario(false)}
                icon={PauseIcon}
                disabled={dirty}
              />
            ) : (
              <Button
                label={t("automation.scenarios.start")}
                onClick={() => handleToggleScenario(true)}
                icon={PlayIcon}
                style="primary"
                disabled={dirty}
              />
            )
          ) : null}
        </div>
      </div>
      <div className="sm:hidden p-3 bg-white border-b flex-shrink-0 space-y-3">
        {renderOptions()}
      </div>
      {loadingScenario ? (
        <div className={`flex-grow flex items-center justify-center`}>
          <Loader width={30} strokeWidth={6} />
        </div>
      ) : (
        <div className={`flex-grow relative`}>
          <FlowWrapper>
            <Flow
              hasTriggers
              triggers={triggers}
              setTriggers={setTriggers}
              actions={actions}
              setActions={setActions}
            >
              <Panel
                position="top-left"
                className="hidden sm:block p-4 rounded-lg bg-white border space-y-3"
              >
                {renderOptions()}
              </Panel>
              {dirty && (
                <Panel
                  position={isMobile ? "bottom-right" : "top-right"}
                  className="hidden sm:flex space-x-2"
                >
                  {renderSaveButtons()}
                </Panel>
              )}
            </Flow>
          </FlowWrapper>
        </div>
      )}
      {dirty && (
        <div className="flex sm:hidden p-3 bg-white border-t flex-shrink-0 justify-between items-center">
          {renderSaveButtons()}
        </div>
      )}
    </div>
  );
}
