import {
  CreditCardIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/20/solid";
import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../elements/Button";

export default function SubscriptionPaymentError() {
  const { t } = useTranslation();

  return (
    <div className="hidden xl:block bg-yellow-500 text-black select-none rounded-2xl rounded-tl-none p-4 space-y-4">
      <div className="space-y-2">
        <div className="flex space-x-2">
          <ExclamationTriangleIcon className="w-5" />
          <div className="font-medium leading-5">
            {t("subscription.payment_error.title")}
          </div>
        </div>
        <div className="text-sm leading-4">
          {t("subscription.payment_error.description")}
        </div>
      </div>
      <Button
        label={t("subscription.payment_error.cta")}
        className="w-full"
        icon={CreditCardIcon}
        href="/stripe/checkout_session"
      />
    </div>
  );
}
