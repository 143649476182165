import React, { useMemo, useRef, useState } from "react";

import Button from "../elements/Button";

import logo from "../../../assets/images/logo_color.svg";
import { Trans, useTranslation } from "react-i18next";
import GoogleIcon from "../shared/icons/GoogleIcon";
import Input from "../elements/Input";
import InputAuthenticityToken from "../utils/InputAuthenticityToken";

export default function SignupForm() {
  const { t } = useTranslation();

  const formRef = useRef(null);
  const [isValid, setIsValid] = useState(false);
  const checkValidity = () => {
    setIsValid(formRef.current.checkValidity());
  };

  const promoCode = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get("promo_code");
  }, []);

  return (
    <div className="max-w-[512px] mx-auto space-y-8">
      <img src={logo} className="w-[112px] h-auto" />

      <div className="space-y-4">
        <h1 className="text-4xl sm:text-5xl text-headings font-black whitespace-pre-line">
          {t("signup.tagline")}
        </h1>
        {promoCode ? (
          <div className="rounded-lg p-4 border space-y-1">
            <div className="flex justify-between items-center">
              <div className="text-lg font-bold text-headings">
                {t("signup.special_offer.title")}
              </div>
              <div className="text-sm font-medium bg-primary text-white px-2 py-0.5 rounded">
                {t("signup.special_offer.offer")}
              </div>
            </div>
            <div className="text-muted">
              {t("signup.special_offer.description")}
            </div>
          </div>
        ) : (
          <p className="text-muted">{t("signup.description")}</p>
        )}
      </div>

      <div className="space-y-6">
        {/* Oauth links */}
        <div className="grid grid-cols-1 gap-2">
          {/* <Button
            href="/users/auth/facebook"
            label={t("signup.facebook")}
            icon={FacebookIcon}
          /> */}
          <Button
            href="/users/auth/google_oauth2"
            label={t("signup.google")}
            icon={GoogleIcon}
          />
        </div>
        {/* Divider */}
        <div className="flex justify-center relative">
          <div className="absolute w-full h-0 border-b top-1/2" />
          <div className="px-2 relative bg-white text-muted">
            {t("signup.email_title")}
          </div>
        </div>
        {/* Email form */}
        <form ref={formRef} className="space-y-4" method="post" action="/users">
          <InputAuthenticityToken />
          <Input
            label={t("signup.email_label")}
            placeholder={t("signup.email_placeholder")}
            onKeyUp={checkValidity}
            type="email"
            autoComplete="email"
            name="user[email]"
            id="user_email"
            required
          />
          <Input
            label={t("signup.name_label")}
            placeholder={t("signup.name_placeholder")}
            onKeyUp={checkValidity}
            autoComplete="name"
            name="user[name]"
            id="user_name"
            description={t("signup.name_description")}
            required
          />
          <Input
            label={t("signup.company_label")}
            placeholder={t("signup.company_placeholder")}
            onKeyUp={checkValidity}
            autoComplete="organization"
            company="user[company]"
            id="user_company"
          />
          <Input
            label={t("signup.password_label")}
            onKeyUp={checkValidity}
            type="password"
            autoComplete="current-password"
            name="user[password]"
            id="user_password"
            minLength="6"
            required
            description={t("signup.password_description")}
          />
          <Button
            className="w-full"
            style="primary"
            label={t("signup.create_your_account")}
            disabled={!isValid}
            loadingOnClick
          />
          <div className="block text-sm text-muted">
            <Trans i18nKey="signup.accept_terms">
              <a
                href="https://www.inro.social/cgu"
                target="_blank"
                className="underline"
              >
                Terms of Service
              </a>
              <a
                href="https://www.iubenda.com/privacy-policy/66567077"
                target="_blank"
                className="underline"
              >
                Privacy Policy
              </a>
            </Trans>
          </div>
        </form>
        <div className="p-4 text-center">
          {t("signup.already_account")}{" "}
          <a href="/users/sign_in" className="text-primary underline">
            {t("signup.log_in")}
          </a>
        </div>
      </div>
    </div>
  );
}
