import React, { useState } from "react";
import classNames from "../../utils/classNames";
import CardIcon from "./CardIcon";

export default function CardsSelect(props) {
  const { label, value, onChange = () => {}, options = [] } = props;

  const [selected, setSelected] = useState(value);

  const handleClick = (option) => {
    setSelected(option.value);
    onChange(option.value);
  };

  const gridColsClass = {
    1: "sm:grid-cols-3",
    2: "sm:grid-cols-3",
    3: "sm:grid-cols-3",
    4: "sm:grid-cols-4",
    5: "sm:grid-cols-5",
    6: "sm:grid-cols-3",
  }[options.length];

  return (
    <div className="space-y-2">
      {label ? (
        <div className="text-base font-medium leading-5 text-headings">
          {label}
        </div>
      ) : null}
      <div className={classNames("grid grid-cols-1 gap-4", gridColsClass)}>
        {options.map((option, index) => (
          <div key={option.value}>
            <div
              className={classNames(
                option.value === selected &&
                  "border-primary ring-1 ring-primary",
                "relative text-left cursor-pointer border rounded-lg bg-white p-4 shadow-sm focus:outline-none",
              )}
              onClick={() => handleClick(option)}
            >
              <div className="absolute right-2 top-1">
                <input
                  type="radio"
                  checked={option.value === selected}
                  readOnly
                  className="text-primary"
                />
              </div>
              <div className="flex flex-col space-y-3 h-full">
                <CardIcon name={option.icon} />
                <div className="flex flex-col space-y-1 flex-grow">
                  <div className="block text-sm font-medium text-gray-900">
                    {option.label}
                  </div>
                  {option.description ? (
                    <div className="flex text-xs text-gray-500 flex-grow">
                      <p>{option.description}</p>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
