import React, { useContext } from "react";
import FullScreen from "../../utils/FullScreen";
import PageHeader from "../../shared/PageHeader";
import { useTranslation } from "react-i18next";
import { UserContext } from "~/contexts/user-context";
import ScreenSlide from "../../utils/ScreenSlide";
import { useParams } from "react-router-dom";
import PaymentAccountDetails from "./PaymentAccountDetails";
import MenuItem from "../../profile/MenuItem";
import { isEmpty } from "lodash";
import Button from "../../elements/Button";
import { PlayCircleIcon } from "@heroicons/react/24/outline";
import PaymentGeneralSettings from "./PaymentGeneralSettings";
import { PaymentsContext } from "~/contexts/payments-context";
import ProfileSectionTitle from "../../profile/ProfileSectionTitle";
import reactNativeMessage from "../../../utils/reactNativeMessage";
import { isApp } from "../../../utils/environment";
import { DocumentArrowDownIcon } from "@heroicons/react/20/solid";
import { UIContext } from "~/contexts/ui-context";
import { DateTime } from "luxon";

export default function PaymentSettings(props) {
  const { back } = props;

  const { t } = useTranslation();

  const { showVideo } = useContext(UIContext);

  const { user, organization } = useContext(UserContext);
  const { payments } = useContext(PaymentsContext);

  const { subpath } = useParams();

  const pages = {
    general: <PaymentGeneralSettings />,
    details: <PaymentAccountDetails />,
  };

  const generalBadge = () => {
    let label, className;
    if (organization.country && organization.currency) {
      label = t("shared.completed");
      className = "bg-light-green text-dark-green-500";
    } else if (organization.country && !organization.currency) {
      label = t("shared.in_progress");
      className = "bg-yellow-500 text-dark-yellow-500";
    } else {
      label = t("shared.not_started");
      className = "bg-light-gray text-dark-gray";
    }
    return (
      <div
        className={`rounded-lg p-2 text-2sm whitespace-nowrap flex-shrink-0 ${className}`}
      >
        {label}
      </div>
    );
  };

  const detailsBadge = () => {
    let label, className;
    if (organization.stripe_account_details_completed) {
      label = t("shared.completed");
      className = "bg-light-green text-dark-green-500";
    } else if (!isEmpty(organization.stripe_account_details)) {
      label = t("shared.in_progress");
      className = "bg-yellow-500 text-dark-yellow-500";
    } else {
      label = t("shared.not_started");
      className = "bg-light-gray text-dark-gray";
    }
    return (
      <div
        className={`rounded-lg p-2 text-2sm whitespace-nowrap flex-shrink-0 ${className}`}
      >
        {label}
      </div>
    );
  };

  const stripeBadge = () => {
    let label, className;
    if (organization.stripe_account_id && organization.stripe_charges_enabled) {
      label = t("shared.completed");
      className = "bg-light-green text-dark-green-500";
    } else if (organization.stripe_account_id) {
      label = t("shared.in_progress");
      className = "bg-yellow-500 text-dark-yellow-500";
    } else {
      label = t("shared.not_started");
      className = "bg-light-gray text-dark-gray";
    }
    return (
      <div
        className={`rounded-lg p-2 text-2sm whitespace-nowrap flex-shrink-0 ${className}`}
      >
        {label}
      </div>
    );
  };

  const downloadPayments = () => {
    reactNativeMessage({
      download: {
        url: document.location.origin + "/export_payments",
        filename: `inro_payments_${DateTime.now().toISODate()}.xlsx`,
      },
    });
  };

  return (
    <FullScreen>
      <PageHeader
        title={t("payments.settings.title")}
        className="mb-4"
        back={back}
      />
      <MenuItem
        label={t("payments.settings.general.title")}
        description={t("payments.settings.general.description")}
        to="/profile/payment_settings/general"
        badge={generalBadge()}
      />
      <MenuItem
        label={t("payments.settings.details.title")}
        description={t("payments.settings.details.description")}
        disabled={!organization.country || !organization.currency}
        to="/profile/payment_settings/details"
        badge={detailsBadge()}
      />
      <MenuItem
        label={t("payments.settings.stripe.title")}
        description={t("payments.settings.stripe.description")}
        disabled={!organization.stripe_account_details_completed}
        className={`border-b`}
        href="/stripe_connect/onboarding"
        badge={stripeBadge()}
      />
      <div className="p-4">
        <div className="flex flex-col items-center p-4 rounded-lg bg-lighter-gray">
          <div className="mb-2">💸</div>
          <div className="text-md font-medium mb-6">
            {t("payments.settings.notice.text")}
          </div>
          <Button
            className="w-full bg-light-secondary"
            label={t("payments.settings.notice.cta")}
            icon={PlayCircleIcon}
            onClick={() =>
              showVideo({
                url: "/videos/discovery_payment_link.mp4",
                title: t("payments.settings.notice.video_text"),
              })
            }
          />
        </div>
      </div>
      {payments.length > 0 && (
        <>
          <ProfileSectionTitle label={t("profile.payments_history")} />
          <div className="px-4 py-2 pb-12">
            {isApp ? (
              <Button
                onClick={downloadPayments}
                label={t("profile.download_payments")}
                className="w-full"
                icon={DocumentArrowDownIcon}
              />
            ) : (
              <Button
                href="/export_payments"
                label={t("profile.download_payments")}
                className="w-full"
                icon={DocumentArrowDownIcon}
              />
            )}
          </div>
        </>
      )}
      <ScreenSlide
        visible={!!pages[subpath]}
        origin="/profile/payment_settings"
        stackDepth={2}
      >
        {pages[subpath]}
      </ScreenSlide>
    </FullScreen>
  );
}
