import React, { useEffect, useState } from "react";
import { appInset } from "../../utils/environment";
import { createPortal } from "react-dom";

export default function BatchActions(props) {
  const { actions } = props;

  const [visible, setVisible] = useState(false);
  useEffect(() => {
    setTimeout(() => setVisible(true), 100);
  }, []);

  const widthClassName = () => {
    switch (actions.length) {
      case 1:
        return "w-full";
      case 2:
        return "w-1/2";
      case 3:
        return "w-1/3";
      default:
        return "w-auto";
    }
  };

  return createPortal(
    <div
      className={`fixed sm:absolute inset-x-0 bottom-0 flex border-t bg-white sm:pb-0 transition-transform ${
        visible ? "translate-y-0" : "translate-y-full"
      }`}
      style={{
        zIndex: 900,
        paddingBottom: 16 + appInset.bottom,
      }}
    >
      {actions.slice(0, 3).map((action, index) => (
        <div
          key={index}
          className={`${widthClassName()} h-12 ${
            index == 0 ? null : "border-l"
          } p-3.5 text-md text-center sm:hover:bg-neutral-50 cursor-pointer`}
          onClick={action.action}
        >
          {action.label}
        </div>
      ))}
    </div>,
    document.body
  );
}
