import { delay, isNumber, throttle } from "lodash";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import Loader from "../utils/Loader";

export default forwardRef(function PullToRefresh(props, ref) {
  const {
    children,
    onRefresh,
    onScroll = () => {},
    disabled,
    wrapperClassName,
    contentClassName,
  } = props;

  const [loading, setLoading] = useState(false);
  const loaderRef = useRef(null);
  const scrollRef = ref || useRef(null);

  const throttledRefresh = throttle(onRefresh, 5000);

  const throttledScroll = throttle((evt) => {
    onScroll(evt);
    if (disabled) return;
    if (!scrollRef?.current) return;
    const scrollPosition = scrollRef.current.scrollTop;
    if (scrollPosition <= 0) {
      loaderRef.current.style.transform = `translateY(${-scrollPosition}px)`; // Pull down
      if (isNumber(scrollPosition) && scrollPosition < -50 && !loading) {
        setLoading(true);
        setTimeout(() => setLoading(false), 1000);
        throttledRefresh();
        scrollRef.current.scroll(0, scrollPosition - 50);
      }
    } else {
      setLoading(false);
    }
  }, 16.67);

  return (
    <div
      className={`overflow-hidden relative ${
        loading && "pt-12"
      } ${wrapperClassName}`}
    >
      <div
        className={`absolute ${
          loading ? "top-0" : "-top-12"
        } inset-x-0 h-12 flex items-center justify-center`}
        ref={loaderRef}
      >
        <Loader  width={20} strokeWidth={8} loading={loading} />
      </div>
      <div
        className={`h-full flex flex-col overflow-auto hide-scrollbar ${contentClassName}`}
        onScroll={throttledScroll}
        ref={scrollRef}
      >
        {children}
      </div>
    </div>
  );
});
