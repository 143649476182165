import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import Tabs from "../../elements/Tabs";
import { AutomationContext } from "~/contexts/automation-context";
import { DateTime } from "luxon";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { set, times, unionBy } from "lodash";
import ContactScenarioExecution from "./executions/ContactScenarioExecution";
import Netsuke from "../../shared/Netsuke";
import Datepicker from "react-tailwindcss-datepicker";
import i18n from "../../../locales/i18n";
import Loader from "../../utils/Loader";

const executionStatuses = [
  "pending",
  "completed",
  "limited",
  "failed",
  "interrupted",
];

export default function ScenarioHistory(props) {
  const { scenarioId } = props;

  const { t } = useTranslation();
  const locale = i18n.language.split("-")[0];

  const [searchParams, setSearchParams] = useSearchParams();
  const { loadScenarioExecutions } = useContext(AutomationContext);

  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState({
    startDate: searchParams.has("start_date")
      ? searchParams.get("start_date")
      : DateTime.now().minus({ days: 30 }).toISODate(),
    endDate: searchParams.has("end_date")
      ? searchParams.get("end_date")
      : DateTime.now().toISODate(),
  });
  const [executions, setExecutions] = useState([]);

  const [activeTab, setActiveTab] = useState("all");

  const tabs = [
    {
      id: "all",
      title: t("shared.all"),
      active: "all" === activeTab,
      onClick: () => setActiveTab("all"),
    },
    ...executionStatuses.map((status) => ({
      id: status,
      title: t(`automation.scenarios.executions.statuses.${status}`),
      active: status === activeTab,
      onClick: () => setActiveTab(status),
    })),
  ];

  const visibleExecutions = useMemo(
    () =>
      activeTab === "all"
        ? executions
        : executions.filter((execution) => execution.status === activeTab),
    [executions, activeTab],
  );

  const [openExecutionId, setOpenExecutionId] = useState(null);

  const handleLoadExecutions = useCallback(
    async (dateRange, activeTab) => {
      setLoading(true);
      const data = await loadScenarioExecutions({
        scenario_id: scenarioId,
        start_date: dateRange.startDate,
        end_date: dateRange.endDate,
        status: activeTab === "all" ? null : activeTab,
        offset: 0,
      });
      setExecutions(data);
      setLoading(false);
    },
    [loadScenarioExecutions, scenarioId],
  );

  useEffect(
    () => handleLoadExecutions(dateRange, activeTab),
    [dateRange, activeTab],
  );

  const updateSearchParams = useCallback(() => {
    setSearchParams({
      start_date: dateRange.startDate,
      end_date: dateRange.endDate,
    });
  }, [setSearchParams, dateRange]);

  useEffect(updateSearchParams, [dateRange]);

  // load more on scroll

  const handleLoadMoreExecutions = useCallback(
    async (offset) => {
      const data = await loadScenarioExecutions({
        scenario_id: scenarioId,
        start_date: dateRange.startDate,
        end_date: dateRange.endDate,
        status: activeTab === "all" ? null : activeTab,
        offset,
      });
      setExecutions([...executions, ...data]);
    },
    [loadScenarioExecutions, scenarioId, dateRange, activeTab, executions],
  );

  const [loadingMore, setLoadingMore] = useState(false);

  const handleScroll = async (evt) => {
    const { scrollTop, scrollHeight, clientHeight } = evt.target;
    if (scrollTop + clientHeight >= scrollHeight) {
      setLoadingMore(true);
      await handleLoadMoreExecutions(executions.length);
      setLoadingMore(false);
    }
  };

  return (
    <div className="flex-grow flex flex-col overflow-hidden">
      <div className="flex items-center py-4 px-6">
        <Tabs tabs={tabs} className="flex-grow" />
        <div className="max-w-xs flex-grow">
          <Datepicker
            i18n={locale}
            primaryColor="indigo"
            separator={t("shared.datepicker.separator")}
            displayFormat={"D MMMM YYYY"}
            showShortcuts={true}
            showFooter={true}
            value={dateRange}
            onChange={setDateRange}
            maxDate={new Date()}
          />
        </div>
      </div>
      <div className="flex-grow overflow-auto px-6" onScroll={handleScroll}>
        {!loading ? (
          visibleExecutions.length > 0 ? (
            <div className="space-y-3 py-4">
              {visibleExecutions.map((execution) => (
                <ContactScenarioExecution
                  key={execution.id}
                  execution={execution}
                  setExecution={(value) =>
                    setExecutions((value) => unionBy([value], executions, "id"))
                  }
                  open={openExecutionId === execution.id}
                  onClick={() =>
                    setOpenExecutionId(
                      openExecutionId === execution.id ? null : execution.id,
                    )
                  }
                />
              ))}
              {loadingMore && (
                <div className="p-4 flex items-center justify-center">
                  <Loader />
                </div>
              )}
            </div>
          ) : (
            <div className="flex flex-col space-y-3 items-center justify-center w-full h-full">
              <Netsuke style="cool" />
              <div className="text-lg text-darker-gray text-center">
                {t("automation.scenarios.executions.no_executions")}
              </div>
            </div>
          )
        ) : (
          <div className="space-y-3 py-4">
            {times(5).map((i) => (
              <ContactScenarioExecution key={i} loading />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
