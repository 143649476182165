import React, { useContext, useState } from "react";
import FullScreen from "../utils/FullScreen";
import PageTitle from "../shared/PageTitle";
import SearchField from "../shared/SearchField";
import Loader from "../utils/Loader";
import { reverse, sortBy } from "lodash";
import { InboxContext } from "~/contexts/inbox-context";
import { useTranslation } from "react-i18next";
import CommentItem from "./CommentItem";
import InboxTabs from "../inbox/InboxTabs";
import { UserContext } from "~/contexts/user-context";

export default function CommentsInbox() {
  const { loading, comments } = useContext(InboxContext);
  const { organization } = useContext(UserContext);

  const { t } = useTranslation();

  // Search
  const [searchQuery, setSearchQuery] = useState("");
  const searchedComments = searchQuery.length
    ? comments.filter((comment) =>
        comment.text.toLowerCase().includes(searchQuery.toLowerCase()),
      )
    : comments;

  const receivedComments = searchedComments.filter(
    (c) => c.contact?.instagram_id != organization?.instagram_id,
  );

  return (
    <div className="sm:h-screen sm:flex sm:flex-grow">
      <FullScreen className="sm:min-w-[20rem] sm:max-w-sm sm:flex-grow sm:flex-shrink-0 sm:border-r flex flex-col">
        <div className="bg-white flex items-center space-x-2 px-3 pt-4">
          <SearchField
            value={searchQuery}
            onChange={setSearchQuery}
            onClear={() => setSearchQuery("")}
          />
        </div>
        <div className="px-3 py-3">
          <InboxTabs />
        </div>
        {loading ? (
          <div className="flex items-center justify-center p-6">
            <Loader width={30} strokeWidth={6} />
          </div>
        ) : (
          <div className="flex-grow overflow-auto">
            {reverse(sortBy(receivedComments, "received_at")).map((comment) => (
              <CommentItem key={comment.id} comment={comment} />
            ))}
          </div>
        )}
      </FullScreen>
    </div>
  );
}
