import React, { useContext } from "react";

import paymentClassName from "../../utils/paymentClassName";
import paymentStatusIcon from "../../utils/paymentStatusIcon";
import formatAmount from "../../utils/formatAmount";
import RelativeDate from "../utils/RelativeDate";
import { useTranslation } from "react-i18next";
import { CalendarContext } from "~/contexts/calendar-context";
import PaymentFeesDetails from "./PaymentFeesDetails";

export default function PaymentDetailsStatus(props) {
  const { payment, canRemove = false } = props;

  const { removeAppointmentPayment } = useContext(CalendarContext);

  const { t } = useTranslation();

  return (
    <div className="p-4 flex flex-col space-y-4">
      <div className="text-md">{t("payments.details.status")}</div>
      {payment.status != "awaiting" && (
        <div className="flex items-center justify-between space-x-4">
          <div
            className={`w-[76px] flex-shrink-0 flex space-x-1 justify-center items-center px-1 py-1.5 rounded-lg text-sm ${paymentClassName(
              payment.status,
            )}`}
          >
            {paymentStatusIcon(payment.status)}
            <div>{formatAmount(payment.total, payment.currency)}</div>
          </div>
          <div className="flex-grow">
            <div className="text-2sm">
              {t("payments.labels." + payment.status)}
            </div>
            {payment.status == "paid" && (
              <div className="text-2xs text-dark-gray mb-1">
                {payment.payment_method_name} **** **** ****{" "}
                {payment.payment_method_digits || "****"}
              </div>
            )}
          </div>
          <div className="text-2sm text-dark-gray">
            <RelativeDate date={payment.updated_at} />
          </div>
        </div>
      )}
      <div
        className={`flex items-center justify-between overflow-hidden space-x-4 ${
          payment.status != "awaiting" ? "opacity-60" : null
        }`}
      >
        <div
          className={`w-[72px] flex-shrink-0 flex space-x-1 justify-center items-center px-1 py-1.5 rounded-lg text-sm ${paymentClassName(
            "sent",
          )}`}
        >
          {paymentStatusIcon("sent")}
          <div>{formatAmount(payment.total, payment.currency)}</div>
        </div>
        <div className="flex-grow flex justify-start items-center space-x-2 whitespace-nowrap overflow-hidden">
          <div className="text-2sm flex-shrink overflow-hidden text-ellipsis">
            {t("payments.labels.sent")}
          </div>
          {canRemove && (
            <div
              className="text-2xs text-dark-gray underline py-1 flex-shrink-0"
              onClick={() => removeAppointmentPayment(payment.id)}
            >
              {t("calendar.details.remove_payment")}
            </div>
          )}
        </div>
        <div className="text-2sm text-dark-gray flex-shrink-0">
          <RelativeDate date={payment.created_at} />
        </div>
      </div>
      {["paid", "awaiting"].includes(payment.status) && (
        <PaymentFeesDetails
          estimated={payment.status == "awaiting"}
          stripeFee={payment.connect ? payment.stripe_fee : null}
          {...payment}
        />
      )}
    </div>
  );
}
