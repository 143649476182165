import React, { useContext, useEffect, useRef, useState } from "react";
import BottomDrawer from "../../shared/BottomDrawer";
import { useTranslation } from "react-i18next";
import { UserContext } from "~/contexts/user-context";
import FolderTag from "./FolderTag";
import { CheckIcon, MinusIcon } from "@heroicons/react/20/solid";
import Button from "../../elements/Button";

export default function FoldersDrawer(props) {
  const {
    conversations: initialConversations,
    onCreateFolder = () => {},
    onSubmit = () => {},
    onClose = () => {},
  } = props;

  const { t } = useTranslation();

  const { organization } = useContext(UserContext);

  const [conversations, setConversations] = useState(initialConversations);

  const foldersCount = useRef(organization.folders.length);

  // Add new folder to folderIds when created
  useEffect(() => {
    if (organization.folders.length <= foldersCount.current) return;
    const newFolder = organization.folders[organization.folders.length - 1];
    if (newFolder) toggleFolder(newFolder.id);
  }, [organization.folders.length]);

  const folderStatus = (folderId) => {
    if (conversations.every((c) => c.folder_ids?.includes(folderId))) {
      return "all";
    } else if (conversations.some((c) => c.folder_ids?.includes(folderId))) {
      return "some";
    } else {
      return "none";
    }
  };

  const folderConversationCount = (folderId) => {
    return conversations.filter((c) => c.folder_ids?.includes(folderId))
      ?.length;
  };

  const toggleFolder = (folderId) => {
    if (folderStatus(folderId) == "none") {
      // Add folder to all conversations
      setConversations(
        conversations.map((conv) => ({
          ...conv,
          folder_ids: conv.folder_ids
            ? [...conv.folder_ids, folderId]
            : [folderId],
        })),
      );
    } else {
      // Remove folder from all conversations
      setConversations(
        conversations.map((conv) => ({
          ...conv,
          folder_ids: conv.folder_ids?.filter((id) => id != folderId) || [],
        })),
      );
    }
  };

  const handleConfirm = () => {
    onSubmit(
      conversations.map((c) => ({
        id: c.id,
        folder_ids: c.folder_ids || [],
      })),
    );
    onClose();
  };

  const renderFolder = (folder) => {
    const status = folderStatus(folder.id);
    const count = folderConversationCount(folder.id);
    return (
      <button
        key={folder.id}
        className={`border-t flex items-center justify-center relative px-10 py-3 ${
          status != "none" ? "bg-light-background" : "bg-white"
        }`}
        onClick={() => toggleFolder(folder.id)}
      >
        <div
          className={`absolute left-5 w-5 h-5 rounded border border-primary ${
            status != "none" ? "bg-primary" : "bg-white"
          } flex items-center justify-center`}
        >
          {status == "all" ? (
            <CheckIcon className="text-white w-4" />
          ) : (
            <MinusIcon className="text-white w-4" />
          )}
        </div>
        <FolderTag folder={folder} />
        {count > 0 && conversations.length > 1 && (
          <div className="absolute right-5 rounded bg-light-gray text-dark-gray px-2 py-1 text-2xs">
            {count}
          </div>
        )}
      </button>
    );
  };

  return (
    <BottomDrawer
      title={
        conversations.length > 1
          ? t("shared.selected", { count: conversations.length })
          : t("inbox.conversation.move_to_folder")
      }
      onClose={onClose}
    >
      {organization.folders.map(renderFolder)}
      <div className="p-4">
        <Button
          label={t("inbox.folders.create_new")}
          className="w-full mb-4"
          onClick={onCreateFolder}
        />
        <Button
          label={t("shared.confirm")}
          className="w-full"
          onClick={handleConfirm}
        />
      </div>
    </BottomDrawer>
  );
}
