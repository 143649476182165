import _, { compact, random, reverse, sortBy, times, unionBy } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Link, useLocation } from "react-router-dom";

import { BoltIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import { useMeasure } from "react-use";

import { InboxContext } from "~/contexts/inbox-context";
import { AutomationContext } from "~/contexts/automation-context";
import { UserContext } from "~/contexts/user-context";

import { appInset } from "../../utils/environment";
import subscribeToChannel from "../../utils/subscribeToChannel";

import ScenarioExecution from "../automation/scenarios/executions/ScenarioExecution";
import ScenarioExecutions from "../automation/scenarios/executions/ScenarioExecutions";
import CampaignExecution from "../campaigns/CampaignExecution";
import MessageDateSeparator from "../messages/MessageDateSeparator";
import Netsuke from "../shared/Netsuke";
import ErrorComponent from "../utils/ErrorComponent";
import Loader from "../utils/Loader";
import SkeletonLoader from "../utils/SkeletonLoader";
import ScreenSlide from "../utils/ScreenSlide";
import ConversationDetails from "./ConversationDetails";
import ConversationHeader from "./ConversationHeader";
import ConversationStatusHeader from "./ConversationStatusHeader";
import Message from "./Message";
import MessageForm from "./MessageForm";
import { CampaignsContext } from "~/contexts/campaigns-context";
import Button from "../elements/Button";
import PendingExecutions from "./PendingExecutions";

export default function Conversation(props) {
  const { conversationId, back } = props;

  const { t } = useTranslation();

  const [loadingConversation, setLoadingConversation] = useState(true);
  const [loadingMessages, setLoadingMessages] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [conversation, setConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [allMessagesLoaded, setAllMessagesLoaded] = useState(false);
  const [pendingScenarioExecutions, setPendingScenarioExecutions] = useState(
    [],
  );
  const [pendingCampaignExecutions, setPendingCampaignExecutions] = useState(
    [],
  );

  const { organization } = useContext(UserContext);
  const { loadConversation, loadMessages, loadMoreMessages } =
    useContext(InboxContext);
  const { loadScenarioExecutions } = useContext(AutomationContext);
  const { loadCampaignExecutions } = useContext(CampaignsContext);

  const cableSubscription = useRef(null);

  const subscribeToConversationChannel = useCallback(
    (conversationId) => {
      cableSubscription.current = subscribeToChannel(
        `MessagesChannel`,
        (message) => {
          setMessages((prev) => unionBy([message], prev, "id"));
        },
        { conversation_id: conversationId },
      );
    },
    [setMessages],
  );

  const handleLoadConversation = useCallback(
    async (conversationId) => {
      const conversation = await loadConversation(conversationId);
      setConversation(conversation);
      setLoadingConversation(false);
    },
    [setConversation, loadConversation],
  );

  const handleLoadMessages = useCallback(
    async (conversationId) => {
      const messages = await loadMessages(conversationId);
      setMessages(messages);
      setLoadingMessages(false);
    },
    [setMessages, loadMessages],
  );

  const handleLoadMoreMessages = async (conversationId, offset) => {
    if (messages.length <= 0) return;
    setLoadingMore(true);

    const newMessages = await loadMoreMessages(conversationId, offset);
    if (newMessages.length == 0) {
      setAllMessagesLoaded(true);
    } else {
      setMessages((prev) => unionBy(newMessages, prev, "id"));
    }

    setLoadingMore(false);
  };

  const setMessage = useCallback(
    (messageId, message) =>
      setMessages((messages) =>
        compact(messages.map((m) => (m.id === messageId ? message : m))),
      ),
    [setMessages],
  );

  const handleLoadPendingExecutions = useCallback(
    async (conversationId) => {
      const campaignExecutions = await loadCampaignExecutions({
        conversation_id: conversationId,
        status: ["pending", "limited"],
      });
      setPendingCampaignExecutions(campaignExecutions);
      const scenarioExecutions = await loadScenarioExecutions({
        conversation_id: conversationId,
        status: ["pending", "limited"],
      });
      setPendingScenarioExecutions(scenarioExecutions);
    },
    [
      setPendingCampaignExecutions,
      setPendingScenarioExecutions,
      loadCampaignExecutions,
      loadScenarioExecutions,
    ],
  );

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Message input
  const messageInputRef = useRef(null);

  // Routing
  const showDetails = location.pathname.includes("/details");
  const showAutomation = location.pathname.includes("/automation");

  // scroll to bottom
  const messagesLength = useRef(messages?.length || 0);
  const lastMessageRef = useRef(null);
  const [scrollZoneRef, { height: scrollZoneHeight }] = useMeasure();
  const [initialScrolled, setInitialScrolled] = useState(false);

  const scrollToBottom = (smooth = false) => {
    if (lastMessageRef) {
      _.delay(
        () =>
          lastMessageRef.current?.scrollIntoView({
            behavior: smooth ? "smooth" : "auto",
          }),
        50,
      );
      setInitialScrolled(true);
    }
  };

  // useEffect(() => {
  //   setTimeout(scrollToBottom, KEYBOARD_OPEN_SPEED);
  // }, [keyboardHeight]);

  // order
  const orderedMessages = useMemo(
    () => reverse(sortBy(messages, ["received_at"])),
    [messages],
  );

  // Load more
  const touchPosition = useRef(null);
  const handleScroll = async (evt) => {
    if (loadingMore || allMessagesLoaded) return;
    // when scrolled nearly to top (less than 25% of the scroll zone height)
    if (evt.target.scrollTop <= evt.target.offsetHeight * 0.25) {
      handleLoadMoreMessages(conversationId, messages?.length);
    }
    document.dispatchEvent(new Event("conversationScroll"));
  };
  // blur input on touch move up
  const handleTouchMove = (evt) => {
    if (evt.changedTouches[0].clientY > touchPosition.current) {
      messageInputRef.current?.blur();
    }
  };

  // Scroll to message
  const anchorMessageId = searchParams.get("messageId");
  const messageHighlight = decodeURI(searchParams.get("messageHighlight"));
  const messageIdToScrollTo = orderedMessages?.find(
    (m) => m.id == anchorMessageId || m.content?.indexOf(messageHighlight) > 0,
  )?.id;

  useEffect(scrollToBottom, [
    conversationId,
    showDetails,
    pendingScenarioExecutions,
    pendingCampaignExecutions,
  ]);
  useEffect(() => {
    // scroll smoothly only if messages already exist
    if (loadingMore) return;
    scrollToBottom(messagesLength.current > 0);
    messagesLength.current = messages?.length;
  }, [messages?.length]);
  useEffect(scrollToBottom, [scrollZoneHeight]);

  useEffect(() => {
    setLoadingConversation(true);
    setLoadingMessages(true);
    setMessages([]);
    setPendingScenarioExecutions([]);
    setPendingCampaignExecutions([]);

    handleLoadConversation(conversationId);
    handleLoadMessages(conversationId);
    handleLoadPendingExecutions(conversationId);

    subscribeToConversationChannel(conversationId);
    () => cableSubscription.current?.disconnect();
  }, [conversationId]);

  if (!conversation && !loadingConversation)
    return (
      <div className="h-full sm:flex-grow flex flex-col items-center justify-center space-y-8">
        <Netsuke style="cool" />
        <div className="text-lg text-darker-gray text-center">
          {t("inbox.conversation.not_found")}
        </div>
      </div>
    );

  return (
    <ErrorBoundary FallbackComponent={ErrorComponent}>
      <div
        className={`h-full sm:flex-grow overflow-hidden flex flex-col justify-between items-stretch select-none sm:select-auto`}
        style={{
          paddingTop: appInset.top,
        }}
        onTouchStart={(evt) =>
          (touchPosition.current = evt.changedTouches[0].clientY)
        }
        onTouchEnd={() => (touchPosition.current = null)}
        onTouchMove={handleTouchMove}
      >
        <ConversationHeader
          conversation={conversation}
          loading={loadingConversation}
          back={back}
        >
          {!showDetails && (
            <div className="flex items-center space-x-3 px-3">
              <Button size="small" label={t("shared.details")} to={`details`} />
              {organization.features.automation && (
                <Button
                  size="small"
                  label={t("inbox.conversation.automation_history")}
                  icon={BoltIcon}
                  to={showAutomation ? "" : "automation"}
                />
              )}
            </div>
          )}
        </ConversationHeader>
        <div className="flex-grow flex overflow-hidden">
          <div className="flex-grow flex flex-col max-w-full">
            {organization.dev && (
              <ConversationStatusHeader conversation={conversation} />
            )}
            <div
              className="flex-grow overflow-y-auto hide-scrollbar"
              ref={scrollZoneRef}
              onScroll={handleScroll}
            >
              {loadingMore && (
                <div className="p-4 flex flex-col justify-center items-center space-y-2">
                  <Loader width={32} strokeWidth={8} />
                  <div className="text-dark-gray text-sm text-center">
                    {t("inbox.conversation.loading_messages")}
                  </div>
                </div>
              )}
              <div
                className={`pt-10 pb-4 px-4 sm:pr-5 flex flex-col-reverse space-y-2 justify-end transition-opacity ${
                  initialScrolled ? "opacity-100" : "opacity-0"
                }`}
              >
                <div ref={lastMessageRef}></div>
                {loadingMessages &&
                  times(3, (i) => (
                    <div className="flex justify-end" key={i}>
                      <SkeletonLoader
                        width={200 - i * 30}
                        height={70 - i * 10}
                        className="!rounded-2.5xl"
                      />
                    </div>
                  ))}
                {pendingScenarioExecutions?.length > 0 && (
                  <div className="self-end pt-2 w-72">
                    <PendingExecutions
                      executions={pendingScenarioExecutions}
                      setExecutions={setPendingScenarioExecutions}
                      executionComponent={ScenarioExecution}
                    />
                  </div>
                )}
                {pendingCampaignExecutions?.length > 0 && (
                  <div className="self-end pt-2 w-72">
                    <PendingExecutions
                      executions={pendingCampaignExecutions}
                      setExecutions={setPendingCampaignExecutions}
                      executionComponent={CampaignExecution}
                    />
                  </div>
                )}
                {conversation?.marked_replied ? (
                  <div className="mt-2 flex justify-end space-x-1.5 text-neutral-400">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-3.5 w-3.5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6"
                      />
                    </svg>
                    <div className="text-sm">
                      {t("inbox.conversation.marked_as_replied")}
                    </div>
                  </div>
                ) : null}
                {orderedMessages.map((message, index) => (
                  <div className="flex flex-col space-y-2" key={message.id}>
                    <MessageDateSeparator
                      previous={orderedMessages[index + 1]}
                      current={message}
                    />
                    <Message
                      key={message.id}
                      message={message}
                      setMessage={(m) => setMessage(message.id, m)}
                      conversation={conversation}
                      isAnchor={messageIdToScrollTo == message.id}
                      highlight={messageHighlight}
                      contact={conversation?.contact}
                      last={index == 0}
                    />
                  </div>
                ))}
              </div>
            </div>
            <MessageForm
              conversationId={conversationId}
              messages={messages}
              setMessages={setMessages}
              contact={conversation?.contact}
              onSend={scrollToBottom}
              inputRef={messageInputRef}
            />
          </div>
          {showAutomation && (
            <ScenarioExecutions conversationId={conversationId} />
          )}
        </div>
      </div>
      <ScreenSlide
        visible={showDetails}
        hideTabBar
        origin={`/inbox/conversations/${conversationId}`}
        stackDepth={2}
      >
        <ConversationDetails conversation={conversation} messages={messages} />
      </ScreenSlide>
    </ErrorBoundary>
  );
}
