import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { isApp } from "../../utils/environment";

import Button from "../elements/Button";
import { useLocation, useNavigate } from "react-router-dom";
import ContactHeader from "../contacts/ContactHeader";
import ContactDetails from "../contacts/ContactDetails";
import BottomDrawer from "../shared/BottomDrawer";
import PaymentDetailsStatus from "./PaymentDetailsStatus";
import { DocumentArrowDownIcon } from "@heroicons/react/20/solid";
import { UserContext } from "~/contexts/user-context";
import { PaymentsContext } from "~/contexts/payments-context";
import reactNativeMessage from "../../utils/reactNativeMessage";

export default function PaymentDetails(props) {
  const { payment, onClose } = props;

  const { t } = useTranslation();

  const { organization } = useContext(UserContext);
  const { showPaymentMenu, archivePayments, refreshPayment } =
    useContext(PaymentsContext);

  const navigate = useNavigate();
  const location = useLocation();

  // Menu

  const goToConversation = () => {
    navigate(`/inbox/conversations/${payment.conversation_id}`, {
      state: { from: location.pathname },
    });
  };

  const handleArchivePayment = async () => {
    archivePayments([payment.id], onClose);
  };

  const downloadInvoice = () => {
    reactNativeMessage({
      download: {
        url:
          document.location.origin +
          "/payment_links/" +
          payment.id +
          "/invoice",
        filename: `invoice_${moment().format("YYYY-MM-DD")}.pdf`,
      },
    });
  };

  return (
    <BottomDrawer title={t("payments.details.title")} onClose={onClose}>
      <div className="w-full flex flex-col overflow-hidden justify-center items-stretch pb-4">
        <div className="p-4">
          <ContactHeader
            contact={payment.conversation?.contact}
            conversation={payment.conversation}
            showFolder="right"
          />
        </div>
        {payment.conversation?.contact && (
          <ContactDetails
            contact={payment.conversation?.contact}
            className="mb-4"
          />
        )}
        <PaymentDetailsStatus payment={payment} />
        <div className="p-4 space-y-4">
          {payment.conversation && (
            <Button
              className="w-full"
              label={t("payments.details.goToConversation")}
              onClick={goToConversation}
            />
          )}
          {payment.status == "paid" &&
            payment.invoice_address &&
            organization.stripe_account_business_type == "company" &&
            (isApp ? (
              <Button
                className="w-full mt-4"
                icon={<DocumentArrowDownIcon className="w-4 mr-1.5" />}
                label={t("payments.page.download_invoice")}
                onClick={downloadInvoice}
                external
              />
            ) : (
              <Button
                className="w-full mt-4"
                icon={<DocumentArrowDownIcon className="w-4 mr-1.5" />}
                label={t("payments.page.download_invoice")}
                href={`/payment_links/${payment.id}/invoice`}
                external
              />
            ))}
          {!["cancelled", "refunded"].includes(payment.status) && (
            <Button
              className="w-full"
              label={t("payments.actions.edit", { count: 1 })}
              onClick={() => showPaymentMenu(payment)}
            />
          )}
          {!payment.archived && (
            <Button
              className="w-full"
              label={t("payments.actions.archive", { count: 1 })}
              onClick={handleArchivePayment}
            />
          )}
          {payment.status == "awaiting" && (
            <Button
              className="w-full"
              label={t("payments.actions.refresh")}
              onClick={() => refreshPayment(payment.id)}
            />
          )}
        </div>
      </div>
    </BottomDrawer>
  );
}
