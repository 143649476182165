import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { reverse, sortBy } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { GroupedVirtuoso } from "react-virtuoso";

import { InboxContext } from "~/contexts/inbox-context";
import { UIContext } from "~/contexts/ui-context";

import ConversationItem from "./ConversationItem";
import EmptyInbox from "./EmptyInbox";
import InboxBatchActions from "./InboxBatchActions";
import Loader from "../utils/Loader";
import { UserContext } from "~/contexts/user-context";
import SelectHeader from "../shared/SelectHeader";
import ConversationScroller from "./ConversationScroller";
import { useParams } from "react-router-dom";
import classNames from "../../utils/classNames";

const MAX_SELECTION = 20;

export default function ConversationList(props) {
  const {
    conversations,
    onRefresh,
    onReachEnd,
    activeFilter,
    activeFolder,
    scrollRef,
    className,
  } = props;

  const { conversationId } = useParams();

  const { t } = useTranslation();

  const { showAlert } = useContext(UIContext);
  const { organization } = useContext(UserContext);
  const { loading, loadingMore } = useContext(InboxContext);

  const maxSelection = organization.batch_message_limit || MAX_SELECTION;

  // Scrolling
  const scrollingRef = useRef(false);

  // Pull to refresh
  const handlePullToRefresh = (callback) => {
    onRefresh();
  };

  // Paging & scrolling
  const [scrollDisabled, setScrollDisabled] = useState(false);
  const disableScroll = () => {
    setScrollDisabled(true);
  };
  const enableScroll = () => {
    setScrollDisabled(false);
  };

  // Grouping
  const conversationGroups = [
    {
      label: t("time.today"),
      count:
        conversations.filter((conv) =>
          moment(conv.last_message_at).isAfter(moment().startOf("day")),
        )?.length || 0,
    },
    {
      label: t("time.this_week"),
      count:
        conversations.filter((conv) =>
          moment(conv.last_message_at).isBetween(
            moment().startOf("week"),
            moment().startOf("day"),
          ),
        )?.length || 0,
    },
    {
      label: t("time.earlier"),
      count:
        conversations.filter((conv) =>
          moment(conv.last_message_at).isBefore(moment().startOf("week")),
        )?.length || 0,
    },
  ].filter((g) => g.count > 0);

  // Multi select
  const [selecting, setSelecting] = useState(false);
  const [selectedConversationIds, setSelectedConversationIds] = useState([]);
  const clearSelection = () => {
    setSelectedConversationIds([]);
  };
  const cancel = () => {
    clearSelection();
    setSelecting(false);
  };
  const setSelected = (id) => {
    if (selectedConversationIds.includes(id)) {
      setSelectedConversationIds(
        selectedConversationIds.filter((c) => c !== id),
      );
    } else {
      if (selectedConversationIds.length < maxSelection) {
        setSelectedConversationIds([...selectedConversationIds, id]);
      } else {
        showAlert({
          title: t("inbox.selection_limit", { count: maxSelection }),
          confirm: t("shared.ok"),
        });
      }
    }
  };
  const selectAll = () => {
    if (selectedConversationIds.length > 0) {
      clearSelection();
    } else {
      setSelectedConversationIds(
        conversations.slice(0, maxSelection).map((conv) => conv.id),
      );
    }
  };
  const selectedConversations = conversations.filter((conv) =>
    selectedConversationIds.includes(conv.id),
  );
  // Cancel on folder/filter change
  useEffect(cancel, [activeFolder, activeFilter]);

  // sorted conversations
  const sortedConversations = reverse(
    sortBy(conversations, ["last_message_at", "id"]),
  );

  return (
    <>
      {conversations.length > 0 && (
        <SelectHeader
          selecting={selecting}
          setSelecting={setSelecting}
          selectAll={selectAll}
          selectedCount={selectedConversationIds.length}
          maxCount={maxSelection}
          onCancel={cancel}
        />
      )}
      {conversations.length > 0 && (
        <GroupedVirtuoso
          ref={scrollRef}
          className={"flex-grow scrollbar-hide pb-10"}
          endReached={onReachEnd}
          style={{
            overflowY: scrollDisabled ? "hidden" : "auto",
          }}
          groupCounts={conversationGroups.map((group) => group.count)}
          groupContent={(groupIndex) => (
            <div className="flex items-center justify-between bg-white sticky top-0 z-30">
              <div className="px-3 py-2 text-2xs">
                {conversationGroups[groupIndex]?.label}
              </div>
            </div>
          )}
          itemContent={(index) => (
            <ConversationItem
              key={sortedConversations[index].id}
              conversation={sortedConversations[index]}
              active={conversationId == sortedConversations[index].id}
              activeFolder={activeFolder}
              scrollingRef={scrollingRef}
              selecting={selecting}
              setSelecting={setSelecting}
              selected={selectedConversationIds.includes(
                sortedConversations[index].id,
              )}
              onSelect={() => setSelected(sortedConversations[index].id)}
              onSwipeMove={disableScroll}
              onSwipeEnd={enableScroll}
            />
          )}
          context={{
            onRefresh: handlePullToRefresh,
            selecting,
            scrollingRef,
          }}
          components={{
            Footer: () =>
              loadingMore ? (
                <div className="flex items-center justify-center p-6 pb-40">
                  <Loader width={24} strokeWidth={8} />
                </div>
              ) : (
                <div className="h-40" />
              ),
            Scroller: ConversationScroller,
          }}
        />
      )}
      {/* No filter active and no results = refresh */}
      {!loading && !activeFolder && conversations.length == 0 ? (
        <EmptyInbox
          emoji="🤔"
          title={t("inbox.empty")}
          caption={t("inbox.inbox_empty")}
        />
      ) : null}
      {/* No filter, tab other than All and no results = no conversation tagged */}
      {!loading && activeFolder && conversations.length == 0 ? (
        <EmptyInbox
          emoji="😮"
          title={t("inbox.empty")}
          caption={t("inbox.no_messages")}
        />
      ) : null}
      {selecting && selectedConversationIds.length > 0 ? (
        <InboxBatchActions
          conversations={selectedConversations}
          clearSelection={clearSelection}
          cancel={cancel}
        />
      ) : null}
    </>
  );
}
