import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";

import { UserContext } from "~/contexts/user-context";

import AppointmentEvent from "./AppointmentEvent";
import { InboxContext } from "~/contexts/inbox-context";
import appointmentClassName from "../../utils/appointmentClassName";
import { CalendarContext } from "~/contexts/calendar-context";
import { UIContext } from "~/contexts/ui-context";
import { useNavigate } from "react-router-dom";
import { sortBy } from "lodash";
import { DateTime } from "luxon";

export default function AppointmentMessage(props) {
  const { message } = props;

  const { showMenu, showAlert } = useContext(UIContext);
  const { user } = useContext(UserContext);
  const {
    events,
    appointments,
    cancelAppointment,
    removeAppointmentPayment,
    confirmEvent,
    removeEvent,
  } = useContext(CalendarContext);

  const appointment =
    appointments.find((a) => a.id == message.appointment_id) ||
    message.appointment;

  const { t } = useTranslation();

  const navigate = useNavigate();

  if (!appointment) return null;

  const payment = appointment.payment;

  const appointmentEvents = events.filter(
    (evt) => evt.appointment_id == appointment.id,
  );

  const sortedEvents = sortBy(appointmentEvents, "start_time");
  const availableEvents = sortedEvents.filter(
    (event) => event.status == "sent",
  );
  const confirmedEvent = sortedEvents.find((e) => e.status == "confirmed");

  const isConfirmed = appointment.status == "confirmed";

  const isCancelled = appointment.status == "cancelled";

  const isAwaiting = appointment.status == "awaiting";

  const isExpired =
    isAwaiting &&
    (appointment.status == "expired" ||
      availableEvents.every(
        (event) => DateTime.fromISO(event.start_time) < DateTime.now(),
      ));

  const statusClassName = () => {
    if (isExpired) return "bg-light-gray text-dark-gray";
    return appointmentClassName(appointment.status);
  };

  const fullPayment = payment
    ? parseFloat(payment.amount) + parseFloat(payment.fee)
    : null;

  const appointmentActions = [
    isConfirmed
      ? {
          label: t("calendar.message.view_details"),
          action: () => navigate(`/calendar/${confirmedEvent?.id}`),
        }
      : null,
    {
      label: t("calendar.message.cancel"),
      action: () => cancelAppointment(appointment.id),
      className: "text-red-500",
    },
  ];

  if (fullPayment && isAwaiting) {
    appointmentActions.unshift({
      label: t("calendar.message.remove_payment"),
      action: () => removeAppointmentPayment(appointment.id),
    });
  }

  const showAppointmentMenu = () => {
    showMenu({
      title: t("calendar.message.title"),
      actions: appointmentActions,
    });
  };

  // Event actions

  const handleConfirmEvent = async (event) => {
    await confirmEvent(appointment.id, event.id);
  };

  const handleRemoveEvent = async (event) => {
    removeEvent(event.id);
    const newAppointment = {
      ...message,
      calendar_events: appointmentEvents.map((e) =>
        e.id == event.id
          ? {
              ...e,
              status: "deleted",
            }
          : e,
      ),
    };
  };

  const handleClickEvent = (event) => {
    if (!isAwaiting) return;
    showMenu({
      title: DateTime.fromISO(event.start_time)
        .setZone(user.time_zone)
        .toFormat("EEEE, MMMM d - HH:mm"),
      actions: [
        {
          label: t("calendar.details.confirm_slot"),
          action: () => handleConfirmEvent(event),
        },
        availableEvents.length > 1
          ? {
              label: t("calendar.details.remove_slot"),
              action: () => handleRemoveEvent(event),
              className: "text-red-500",
            }
          : null,
      ],
    });
  };

  return (
    <div className="flex flex-col items-center space-y-2">
      <div
        className={`w-72 h-auto rounded-3xl bg-white border select-none overflow-hidden flex flex-col`}
      >
        <div className="p-4">
          <div className="text-md mb-2">{t("calendar.message.title")}</div>
          <div className="text-2sm text-dark-gray mb-4">
            {fullPayment
              ? t("calendar.message.label_with_payment", {
                  name: `@${user.username}`,
                  amount: fullPayment,
                })
              : t("calendar.message.label", { name: `@${user.username}` })}
          </div>
          {isAwaiting && payment?.status == "attempted" && (
            <div className="text-2xs p-2 rounded-lg bg-yellow-500 text-dark-yellow-500 mb-4">
              {t("calendar.message.payment_attempted")}
            </div>
          )}
          <div className="flex flex-col space-y-2">
            {sortedEvents.map((event, index) => (
              <AppointmentEvent
                key={event.id}
                appointmentCancelled={isCancelled}
                event={event}
                onClick={() => handleClickEvent(event)}
              />
            ))}
          </div>
          <div className="mt-4 flex items-center justify-between">
            <div
              className={`inline-block py-2 px-3 text-sm rounded-lg ${statusClassName()}`}
            >
              {t(
                `calendar.message.${isExpired ? "expired" : appointment.status}`,
              )}
            </div>
            {!isCancelled && (
              <button
                className="block px-2 bg-neutral-200 rounded-lg hover:bg-neutral-300 transition-colors"
                onClick={showAppointmentMenu}
              >
                <EllipsisHorizontalIcon className="w-5 h-5 text-neutral-400" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
