import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { keys } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";

import { UserContext } from "~/contexts/user-context";
import { appInset, isApp } from "../../utils/environment";
import { DURATIONS } from "../calendar/constants";
import Alert from "../shared/Alert";
import TimePicker from "../shared/TimePicker";
import AppointmentCalendar from "./AppointmentCalendar";
import AppointmentConfirm from "./AppointmentConfirm";

export default function AppointmentModal(props) {
  const {
    maxSlots = null,
    slotTitle,
    initialDuration = "1h",
    onClose,
    onSubmit,
  } = props;

  const { t } = useTranslation();

  const { user } = useContext(UserContext);

  const [visible, setVisible] = useState(false);

  // animate appear
  useEffect(() => {
    setTimeout(() => setVisible(true), 50);
  }, []);

  const handleClose = () => {
    if (!visible) return;
    setVisible(false);
    setTimeout(onClose, 300);
  };

  // Payment
  const [paymentAmount, setPaymentAmount] = useState("");

  // submit
  const handleSubmit = () => {
    const events = slots.map((slot) => ({
      ...slot,
      status: "sent",
    }));
    onSubmit(events, paymentAmount);
    handleClose();
  };

  // modal step
  const [modalPage, setModalPage] = useState(0);

  // Slots

  // duration state is in string and value is in constant
  const [duration, setDuration] = useState(initialDuration);
  const [showDurationAlert, setShowDurationAlert] = useState(false);
  const [alertDuration, setAlertDuration] = useState(duration);
  const openDurationAlert = () => {
    setAlertDuration(duration);
    setShowDurationAlert(true);
  };

  const durationInMinutes = DURATIONS[duration];

  const [slots, setSlots] = useState([]);

  const addSlot = (startTime) => {
    if (maxSlots && slots.length >= maxSlots) return;
    const existingSlot = slots.find((slot) => slot.start_time == startTime);
    if (existingSlot) {
      removeSlot(existingSlot);
      return;
    }
    setSlots((slots) => [
      ...slots,
      {
        id: Math.round(Math.random() * 1000),
        status: "selected",
        title: slotTitle,
        start_time: startTime,
        duration: durationInMinutes,
      },
    ]);
  };

  const removeSlot = (slot) => {
    setSlots((slots) => slots.filter((s) => s.id !== slot.id));
  };

  return createPortal(
    <div
      className="fixed inset-0 flex flex-col justify-end pt-20"
      style={{
        zIndex: 1000,
      }}
    >
      <div
        className={`absolute inset-0 bg-black/30 duration-300 transition-opacity ${
          visible ? "opacity-100" : "opacity-0"
        }`}
        onClick={handleClose}
      />
      <div
        className={`sm:max-w-2xl sm:mx-auto flex flex-col overflow-hidden bg-white rounded-t-2xl duration-300 transform-gpu transition-all ${
          visible ? "translate-y-0 opacity-100" : "translate-y-full opacity-0"
        }`}
        style={{
          marginBottom: isApp,
          paddingBottom: appInset.bottom,
        }}
      >
        {modalPage == 0 && (
          <>
            <div className="flex-shrink-0 flex justify-between text-md p-4">
              <div className="font-medium">
                {t("calendar.slots.select.title")}
              </div>
              <div className="text-red cursor-pointer" onClick={handleClose}>
                {t("shared.cancel")}
              </div>
            </div>
            <AppointmentCalendar
              slots={slots}
              addSlot={addSlot}
              removeSlot={removeSlot}
              duration={duration}
              openDurationAlert={openDurationAlert}
              handleSubmit={() => setModalPage(1)}
            />
          </>
        )}
        {modalPage == 1 && (
          <>
            <div className="flex-shrink-0 flex justify-between text-md p-4">
              <div className="flex items-center space-x-1">
                <ChevronLeftIcon
                  className="w-6"
                  onClick={() => setModalPage(0)}
                />
                <div className="font-medium">
                  {t("calendar.slots.confirm.title")}
                </div>
              </div>
              <div className="text-red cursor-pointer" onClick={handleClose}>
                {t("shared.cancel")}
              </div>
            </div>
            <AppointmentConfirm
              slots={slots}
              handleSubmit={handleSubmit}
              paymentAmount={paymentAmount}
              setPaymentAmount={setPaymentAmount}
            />
          </>
        )}
      </div>
      {showDurationAlert && (
        <Alert
          title={t("calendar.duration.title")}
          onSubmit={() => setDuration(alertDuration)}
          onClose={() => setShowDurationAlert(false)}
        >
          <TimePicker
            options={keys(DURATIONS)}
            value={alertDuration}
            onChange={setAlertDuration}
            className="mx-16"
          />
        </Alert>
      )}
    </div>,
    document.body,
  );
}
