import React, { useContext, useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { tabBarHeight } from "../../utils/environment";
import Tabs from "../elements/Tabs";
import PageTitle from "../shared/PageTitle";
import AgentSettings from "./AgentSettings";
import AgentTraining from "./AgentTraining";
import { AgentContext } from "../../contexts/agent-context";
import Toggle from "../shared/Toggle";
import classNames from "../../utils/classNames";

export default function AgentPage() {
  const { t } = useTranslation();

  const { tab } = useParams();

  const navigate = useNavigate();

  const activeTab = tab || "training";

  const tabs = ["training", "settings"].map((tab) => ({
    title: t(`agent.${tab}.title`),
    active: activeTab === tab,
    onClick: () => navigate(`/agent/${tab}`),
  }));

  const { agent, updateAgent } = useContext(AgentContext);

  const toggleActive = () => {
    updateAgent({ active: !agent.active });
  };

  return (
    <div
      className="flex-grow h-screen px-4 sm:px-6 lg:px-8 pt-3 sm:pt-6 flex flex-col overflow-hidden space-y-4 sm:space-y-4"
      style={{
        paddingBottom: isMobile ? tabBarHeight : "2rem",
      }}
    >
      <div className="flex justify-between items-center">
        <PageTitle text={t("agent.title")} />
        <div
          className="flex items-center space-x-3 border rounded-lg p-3 cursor-pointer"
          onClick={toggleActive}
        >
          <div
            className={classNames(
              "text-sm font-medium",
              agent?.active ? "text-black" : "text-darker-gray",
            )}
          >
            <span
              className={classNames(
                "inline-block w-2 h-2 rounded-full mr-2",
                agent?.active ? "bg-green-500" : "bg-medium-gray",
              )}
            />
            {agent?.active ? t("agent.on") : t("agent.off")}
          </div>
          <Toggle value={agent?.active} className="pointer-events-none" />
        </div>
      </div>
      <Tabs tabs={tabs} className="w-full" />
      <div className="overflow-auto flex-grow">
        {activeTab === "training" && <AgentTraining />}
        {activeTab === "settings" && <AgentSettings />}
      </div>
    </div>
  );
}
