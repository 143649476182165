import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "~/contexts/user-context";
import PageHeader from "../shared/PageHeader";
import FullScreen from "../utils/FullScreen";

import InstagramAccount from "./accounts/InstagramAccount";
import ShopifyAccount from "./accounts/ShopifyAccount";
import CalendlyAccount from "./accounts/CalendlyAccount";
import Button from "../elements/Button";
import SubscriptionStatus from "../subscription/SubscriptionStatus";

export default function OrganizationAccountPage(props) {
  const { back } = props;

  const { user, organization, leaveOrganization, deleteOrganization } =
    useContext(UserContext);

  const { t } = useTranslation();

  const organizationUser = user.organization_users.find(
    (ou) => ou.organization.id === organization.id,
  );

  return (
    <FullScreen>
      <PageHeader
        title={t("profile.organization_account")}
        back={back}
        className="mb-4"
      />
      <div className="p-4 space-y-4">
        <SubscriptionStatus />
        <InstagramAccount />
        {organization.features.ecommerce && <ShopifyAccount />}
        <CalendlyAccount />
        {user.organization_users.length > 1 ? (
          organizationUser?.role === "owner" ? (
            <Button
              style="danger"
              label={t("profile.delete_organization")}
              className="w-full"
              onClick={deleteOrganization}
            />
          ) : (
            <Button
              style="danger"
              label={t("profile.leave_organization")}
              className="w-full"
              onClick={leaveOrganization}
            />
          )
        ) : null}
      </div>
    </FullScreen>
  );
}
