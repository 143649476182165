import React, { useContext, useEffect, useState } from "react";

import { createPortal } from "react-dom";
import classNames from "../../utils/classNames";
import { UIContext } from "~/contexts/ui-context";
import { DirtyContext } from "~/contexts/dirty-context";
import { useTranslation } from "react-i18next";
import { XCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import addPropsToChildren from "../../utils/addPropsToChildren";

export default function Modal(props) {
  const { t } = useTranslation();

  const { onClose, checkDirty, children, zIndex = 800, className = "" } = props;

  const { dirty, setDirty } = useContext(DirtyContext);
  const { showPrompt } = useContext(UIContext);

  const [visible, setVisible] = useState(false);

  // animate appear
  useEffect(() => {
    const escapeKey = (evt) => {
      if (evt.key == "Escape") handleClose(evt);
    };
    setTimeout(() => setVisible(true), 50);
    window.addEventListener("keydown", escapeKey);
    return () => window.removeEventListener("keydown", escapeKey);
  }, []);

  const handleClose = (evt = null) => {
    evt?.preventDefault();
    if (checkDirty && dirty) {
      showPrompt(t("shared.unsaved_changes"), () => {
        setDirty(false);
        setVisible(false);
        setTimeout(onClose, 150);
      });
    } else {
      setVisible(false);
      setTimeout(onClose, 150);
    }
  };

  return createPortal(
    <div
      className={`absolute inset-0 flex flex-col items-center justify-end sm:justify-center pt-4 sm:p-4 select-none transition-all ${
        visible ? "pointer-events-auto" : "pointer-events-none"
      }`}
      style={{
        zIndex,
      }}
    >
      <div
        className={`absolute inset-0 bg-black/20 transition-opacity ${
          visible ? "opacity-100" : "opacity-0"
        }`}
        onClick={handleClose}
      />
      <div
        className={classNames(
          "w-full sm:w-auto bg-white shadow-lg rounded-t-xl sm:rounded-xl relative transition-opacity flex flex-col overflow-auto",
          visible ? "opacity-100" : "opacity-0",
          className,
        )}
      >
        <div className="flex sm:hidden justify-end items-center px-3 pt-3 pb-1 flex-shrink-0">
          <button onClick={handleClose}>
            <XMarkIcon className="w-6" />
          </button>
        </div>
        {addPropsToChildren(children, { handleClose })}
      </div>
    </div>,
    document.body,
  );
}
