import React from "react";
import paymentLabel from "../../utils/paymentLabel";
import paymentClassName from "../../utils/paymentClassName";
import PaymentLinkPreview from "../payments/PaymentLinkPreview";
import { useTranslation } from "react-i18next";
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";

const PaymentLinkFullCard = (props) => {
  const {
    organization,
    total,
    currency,
    status,
    disableStatusInfo = false,
    handleMenu,
  } = props;
  const paymentStatusLabel = paymentLabel(status);
  const paymentStatusClass = paymentClassName(status);
  const { t } = useTranslation();

  return (
    <div
      className={`w-72 h-auto rounded-3xl bg-white border select-none overflow-hidden flex flex-col`}
    >
      <PaymentLinkPreview
        organization={organization}
        total={total}
        currency={currency}
        background={organization.payment_background}
      />
      <div className="p-4">
        <div className="text-md mb-2">{t("payments.image.title")}</div>
        <div className="text-sm text-neutral-500">
          {t("payments.image.subtitle", { name: organization.username })}
        </div>
        {!disableStatusInfo && (
          <div className="mt-4 flex items-center justify-between">
            <div
              className={`inline-block py-2 px-3 text-sm rounded-lg ${paymentStatusClass}`}
            >
              {paymentStatusLabel}
            </div>
            {handleMenu && (
              <button
                className="block px-2 bg-neutral-200 rounded-lg hover:bg-neutral-300 transition-colors"
                onClick={handleMenu}
              >
                <EllipsisHorizontalIcon className="w-5 h-5 text-neutral-400" />
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentLinkFullCard;
