import React, { useContext } from "react";
import {
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
} from "react-router-dom";

import ConversionLinksPage from "../automation/conversion_links/ConversionLinksPage";
import ScenariosPage from "../automation/scenarios/ScenariosPage";
import CalendarPage from "../calendar/CalendarPage";
import CampaignsPage from "../campaigns/CampaignsPage";
import CommentsInbox from "../comments/CommentsInbox";
import ContactsPage from "../contacts/ContactsPage";
import ElementsPage from "../elements/ElementsPage";
import InboxPage from "../inbox/InboxPage";
import MentionsInbox from "../mentions/MentionsInbox";
import ComingSoon from "./ComingSoon";
import Layout from "./Layout";
import PaymentsPage from "../payments/PaymentsPage";
import ProfilePage from "../profile/ProfilePage";
import ErrorPage from "../utils/ErrorPage";
import SurveysPage from "../automation/surveys/SurveysPage";
import AgentPage from "../agent/AgentPage";
import { UserContext } from "../../contexts/user-context";
import OnboardingScreen from "../onboarding/OnboardingScreen";

function Root() {
  return (
    <Routes>
      <Route path="/" element={<Layout />} errorElement={<ErrorPage />}>
        {/* Inbox Settings */}
        <Route path="inbox/settings" element={<InboxPage settings />} />
        <Route path="inbox/settings/:path" element={<InboxPage settings />} />
        {/* Comments Inbox */}
        <Route path="inbox/comments" element={<CommentsInbox />} />
        <Route path="inbox/comments/:commentId" element={<CommentsInbox />} />
        {/* Mentions Inbox */}
        <Route path="inbox/mentions" element={<MentionsInbox />} />
        <Route path="inbox/mentions/:mentionId" element={<MentionsInbox />} />
        {/* Inbox Conversation */}
        {["inbox", "inbox/folders/:folderId", "inbox/segments/:segmentId"].map(
          (root, index) => (
            <React.Fragment key={index}>
              <Route
                path={`${root}/conversations/:conversationId/details/:path`}
                element={<InboxPage />}
              />
              <Route
                path={`${root}/conversations/:conversationId/details`}
                element={<InboxPage />}
              />
              <Route
                path={`${root}/conversations/:conversationId/automation`}
                element={<InboxPage />}
              />
              <Route
                path={`${root}/conversations/:conversationId`}
                element={<InboxPage />}
              />
              <Route path={`${root}/conversations`} element={<InboxPage />} />
              <Route path={root} element={<InboxPage />} />
            </React.Fragment>
          ),
        )}

        {/* Contacts */}
        <Route path="contacts" element={<ContactsPage />} />
        <Route path="contacts/:conversationId" element={<ContactsPage />} />
        <Route
          path="contacts/:conversationId/automation"
          element={<ContactsPage />}
        />
        <Route
          path="contacts/:conversationId/details"
          element={<ContactsPage />}
        />

        {/* Campaigns */}
        <Route path="campaigns" element={<CampaignsPage />} />
        <Route path="campaigns/:campaignId" element={<CampaignsPage />} />

        {/* AI Agent */}
        <Route path="agent" element={<AgentPage />} />
        <Route path="agent/:tab" element={<AgentPage />} />

        {/* Calendar */}
        <Route path="calendar" element={<CalendarPage />} />
        <Route
          path="calendar/settings"
          element={<CalendarPage showSettings />}
        />
        <Route
          path="calendar/settings/terms_of_sale"
          element={<CalendarPage showSettings />}
        />
        <Route path="calendar/:appointmentId" element={<CalendarPage />} />
        <Route
          path="calendar/:appointmentId/details"
          element={<CalendarPage />}
        />
        <Route
          path="calendar/:appointmentId/details/:path"
          element={<CalendarPage />}
        />

        {/* Payments */}
        <Route path="payments" element={<PaymentsPage />} />
        <Route path="payments/balance" element={<PaymentsPage showBalance />} />
        <Route
          path="payments/settings"
          element={<PaymentsPage showSettings />}
        />
        <Route
          path="payments/settings/:subpath"
          element={<PaymentsPage showSettings />}
        />
        <Route path="payments/:paymentId" element={<PaymentsPage />} />

        {/* Automation */}
        <Route
          path="automation/conversion_links/:linkId"
          element={<ConversionLinksPage />}
        />
        <Route
          path="automation/conversion_links"
          element={<ConversionLinksPage />}
        />
        <Route path="automation/surveys/:surveyId" element={<SurveysPage />} />
        <Route path="automation/surveys" element={<SurveysPage />} />
        <Route
          path="automation/scenarios/:scenarioId/history"
          element={<ScenariosPage showResults />}
        />
        <Route
          path="automation/scenarios/:scenarioId"
          element={<ScenariosPage />}
        />
        <Route path="automation/scenarios" element={<ScenariosPage />} />
        <Route path="automation" element={<ScenariosPage />} />

        {/* Profile */}
        <Route path="profile" element={<ProfilePage />} />
        <Route path="profile/:path" element={<ProfilePage />} />
        <Route path="profile/:path/:subpath" element={<ProfilePage />} />

        {/* Elements */}
        <Route path="elements" element={<ElementsPage />} />

        <Route path="*" element={<ComingSoon />} />
      </Route>
    </Routes>
  );
}

// TODO switch to BrowserRouter with object notation instead of components </> notation
export default function MainRouter() {
  const router = createBrowserRouter([
    {
      path: "*",
      element: <Root />,
    },
  ]);

  const { organization } = useContext(UserContext);

  if (!organization.onboarding_completed) return <OnboardingScreen />;

  return <RouterProvider router={router} />;
}
