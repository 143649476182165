import React, { useEffect } from "react";

export default function TableRow(props) {
  const { item, columns, selected, onClick, onRender = () => {} } = props;

  useEffect(() => onRender(item), [item.id]);

  return (
    <tr
      key={item.id}
      onClick={() => onClick(item)}
      className={`${selected ? "bg-neutral-100" : "hover:bg-neutral-50"}`}
    >
      {columns.map((column, index) => (
        <td
          key={index}
          className={`whitespace-nowrap py-3 pl-4 pr-3 text-sm text-gray-500
           ${column.className || ""} ${column.cellClassName || ""}`}
        >
          {column.accessor && column.accessor(item)}
        </td>
      ))}
    </tr>
  );
}
