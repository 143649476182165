import React, { useContext, useState } from "react";
import {
  ExclamationCircleIcon,
  FireIcon,
  PaperAirplaneIcon,
} from "@heroicons/react/20/solid";
import { Trans, useTranslation } from "react-i18next";
import { AutomationContext } from "~/contexts/automation-context";
import { SubscriptionContext } from "~/contexts/subscription-context";
import Button from "~/components/elements/Button";

export default function ScenarioLimitReached(props) {
  const { t } = useTranslation();

  const { scenario } = props;

  const { limited_executions } = scenario;

  const { limitReached } = useContext(SubscriptionContext);
  const { triggerLimited } = useContext(AutomationContext);

  const [resending, setResending] = useState(false);

  const handleResend = async () => {
    setResending(true);
    await triggerLimited(scenario.id);
    setResending(false);
  };

  if (!limited_executions) return null;

  return (
    <div className="bg-warning p-4 flex flex-col lg:flex-row lg:items-center justify-between gap-4">
      <div className="flex space-x-2">
        <ExclamationCircleIcon className="w-5" />
        <div className="text-md">
          <Trans
            i18nKey="automation.scenarios.limited_executions"
            count={limited_executions}
          >
            <strong>1 scenario</strong> was not triggered because you have
            reached your monthly limit
          </Trans>
        </div>
      </div>
      <div className="space-x-2">
        {!limitReached && (
          <Button
            label={t("automation.scenarios.trigger_executions")}
            icon={PaperAirplaneIcon}
            onClick={handleResend}
            loading={resending}
          />
        )}
        <Button
          label={t("subscription.upgrade_to_pro")}
          icon={FireIcon}
          style="inverse"
          href="/stripe/checkout_session"
        />
      </div>
    </div>
  );
}
