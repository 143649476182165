import React, { useState } from "react";
import PublicBackground from "../shared/PublicBackground";
import ContactHeader from "./ContactHeader";
import Button from "../elements/Button";
import { Trans, useTranslation } from "react-i18next";
import PublicFormInput from "../forms/PublicFormInput";
import axios from "axios";
import headers from "../../utils/headers";
import Alert from "../shared/Alert";

export default function ContactInformationForm(props) {
  const { organization, contact, eventDate = null, onSubmit } = props;

  const { t } = useTranslation();

  const [name, setName] = useState(contact?.real_name || contact?.name || "");
  const [email, setEmail] = useState(contact?.email || "");
  const [phone, setPhone] = useState(contact?.phone || "");

  const [acceptTerms, setAcceptTerms] = useState(!organization?.terms_active);

  const handleSubmit = (evt) => {
    evt.preventDefault();

    axios
      .patch(
        `/public/contacts/${contact.id}`,
        { real_name: name, email, phone },
        headers(),
      )
      .then((res) => onSubmit());
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <PublicBackground className="justify-end">
          <div className="bg-white p-4 rounded-xl flex flex-col space-y-4 w-full max-w-md">
            <ContactHeader contact={organization} large />
            {eventDate && (
              <div className="text-md font-medium text-center border-y p-4 mb-4">
                {eventDate}
              </div>
            )}
            <div className="text-md text-darker-gray">
              {t("calendar.message.information_notice")}
            </div>
            <PublicFormInput
              name="name"
              label={t("inbox.details.real_name")}
              placeholder={t("inbox.details.real_name_placeholder")}
              autoComplete="name"
              value={name}
              onChange={setName}
              required
            />
            <PublicFormInput
              name="email"
              type="email"
              label={t("inbox.details.email_address")}
              placeholder={t("inbox.details.email_placeholder")}
              autoComplete="email"
              value={email}
              onChange={setEmail}
              required
            />
            {organization?.appointment_phone_required && (
              <PublicFormInput
                name="phone"
                type="tel"
                label={t("inbox.details.phone_number")}
                placeholder={t("inbox.details.phone_placeholder")}
                autoComplete="tel"
                value={phone}
                onChange={setPhone}
                required
              />
            )}
            {organization?.terms_active && organization?.terms_url?.length && (
              <div className="pt-2 px-1 flex items-center">
                <input
                  type="checkbox"
                  id="terms"
                  name="terms"
                  className="w-4 h-4 mr-2"
                  onChange={(evt) => setAcceptTerms(evt.target.checked)}
                  required
                />
                <label for="terms" className="text-md text-dark-gray">
                  <Trans i18nKey="payments.page.accept_terms">
                    <span>I accept the </span>
                    <a
                      className="underline"
                      href={organization?.terms_url}
                      target="_blank"
                    >
                      terms of sale
                    </a>
                  </Trans>
                </label>
              </div>
            )}
          </div>
          <Button
            label={t("calendar.message.confirm_information")}
            className="w-full max-w-md"
            disabled={!acceptTerms}
          />
        </PublicBackground>
      </form>
    </>
  );
}
