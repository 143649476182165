import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import BatchActions from "../shared/BatchActions";
import BatchMessage from "../inbox/BatchMessage";
import { unionBy, uniq } from "lodash";
import BottomMenu from "../shared/BottomMenu";
import axios from "axios";
import headers from "../../utils/headers";
import { UserContext } from "~/contexts/user-context";
import { LoadingContext } from "~/contexts/loading-context";
import { PaymentsContext } from "~/contexts/payments-context";

export default function PaymentsBatchActions(props) {
  const { t } = useTranslation();

  const { setLoading } = useContext(LoadingContext);
  const { cancelPayments, markAsPaid, archivePayments } =
    useContext(PaymentsContext);

  const { payments, cancel } = props;
  const paymentIds = payments.map((c) => c.id);

  // Mark as
  const [editPanel, setEditPanel] = useState(false);
  const editActions = [
    {
      label: t("payments.actions.mark_as_paid"),
      action: () => {
        markAsPaid(paymentIds);
        cancel();
      },
    },
    {
      label: t("payments.actions.cancel", { count: payments.length }),
      action: () => {
        cancelPayments(paymentIds);
        cancel();
      },
      className: "text-red-500",
    },
  ];

  // Send message
  const [messagePanel, setMessagePanel] = useState(false);
  const conversationIds = uniq(payments.map((c) => c.conversation_id));

  const actions = [
    {
      label: t("payments.actions.edit", { count: payments.length }),
      action: () => setEditPanel(true),
    },
    {
      label: t("payments.actions.archive", { count: payments.length }),
      action: () => archivePayments(paymentIds, cancel),
    },
    {
      label: t("inbox.actions.send_message"),
      action: () => setMessagePanel(true),
    },
  ];

  return (
    <>
      <BatchActions actions={actions} />
      {editPanel && (
        <BottomMenu
          title={t("payments.actions.payments_selected", {
            count: payments.length,
          })}
          actions={editActions}
          onClose={() => setEditPanel(false)}
        />
      )}
      {messagePanel && (
        <BatchMessage
          conversationIds={conversationIds}
          onClose={() => setMessagePanel(false)}
          onSuccess={cancel}
        />
      )}
    </>
  );
}
