import {
  BoltIcon,
  CreditCardIcon,
  HeartIcon,
  UsersIcon,
} from "@heroicons/react/20/solid";
import React, { useContext } from "react";
import { Trans, useTranslation } from "react-i18next";
import Button from "../../../elements/Button";
import { SubscriptionContext } from "../../../../contexts/subscription-context";

export default function ProPlan() {
  const { t } = useTranslation();

  const { startSubscription } = useContext(SubscriptionContext);

  return (
    <div className="p-6 space-y-6 border border-primary ring-1 ring-primary rounded-2xl text-headings">
      <div className="space-y-2">
        <HeartIcon className="w-8 h-8" />
        <div className="text-lg leading-6 font-medium">
          {t("onboarding.free_trial.pro_plan.title")}
        </div>
        <div className="leading-5">
          <Trans i18nKey="onboarding.free_trial.pro_plan.description" />
        </div>
      </div>
      <div className="font-black text-4xl leading-none">
        {t("onboarding.free_trial.pro_plan.price")}
      </div>
      <Button
        style="primary"
        label={t("onboarding.free_trial.pro_plan.cta")}
        className="w-full"
        onClick={startSubscription}
        loadingOnClick
      />
      <div className="space-y-4">
        <div className="flex space-x-2">
          <CreditCardIcon className="w-5 flex-shrink-0" />
          <div className="font-semibold text-sm leading-5">
            {t("onboarding.free_trial.pro_plan.per_month")}
          </div>
        </div>
        <div className="flex space-x-2">
          <UsersIcon className="w-5 flex-shrink-0" />
          <div className="font-semibold text-sm leading-5">
            {t("onboarding.free_trial.pro_plan.contacts_limit")}
          </div>
        </div>
        <div className="flex space-x-2">
          <BoltIcon className="w-5 flex-shrink-0" />
          <div className="font-semibold text-sm leading-5">
            {t("onboarding.free_trial.pro_plan.automation_limit")}
          </div>
        </div>
      </div>
    </div>
  );
}
