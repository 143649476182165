import { CalendarIcon, MapPinIcon } from "@heroicons/react/20/solid";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { UIContext } from "~/contexts/ui-context";

import Color from "color";
import calendarPosition from "../utils/calendarPosition";
import { CELL_HEIGHT } from "./constants";
import { DateTime } from "luxon";
import { UserContext } from "~/contexts/user-context";

export default function ConnectedCalendarEvent(props) {
  const { event, active } = props;

  const { showAlert } = useContext(UIContext);
  const { user } = useContext(UserContext);

  const { t } = useTranslation();

  const startTime = DateTime.fromISO(event.start_time).setZone(user.time_zone);
  const endTime = DateTime.fromISO(event.end_time).setZone(user.time_zone);
  const startPosition = Math.max(calendarPosition(startTime), 0);
  const length = Math.min(
    endTime.diff(startTime).as("minutes") / 60,
    23 - startTime.hour,
  );

  const handleClick = () => {
    showAlert({
      title: event.title,
      message: alertContent(event),
      confirm: t("shared.ok"),
      hideCancel: true,
    });
  };

  const alertContent = (event) => (
    <div className="text-2sm text-left space-y-3">
      <div>
        {startTime.toLocaleString(DateTime.DATE_FULL)} ∙{" "}
        <span className="lowercase">{duration(event)}</span>
      </div>
      <div className="flex items-center space-x-2">
        <CalendarIcon
          className="w-5 h-5 flex-shrink-0"
          style={{
            color: event.color,
          }}
        />
        <div>{event.calendar}</div>
      </div>
      {event.location && (
        <div className="flex items-center space-x-2">
          <MapPinIcon
            className="w-5 h-5 flex-shrink-0"
            style={{
              color: event.color,
            }}
          />
          <div className="break-words overflow-hidden">{event.location}</div>
        </div>
      )}
    </div>
  );

  const duration = (event) =>
    event.all_day
      ? t("shared.all_day")
      : startTime.toLocaleString(DateTime.TIME_24_SIMPLE) +
        " - " +
        endTime.toLocaleString(DateTime.TIME_24_SIMPLE);

  const isPast = startTime < DateTime.now();

  // Overlapping events
  // const width = 100 / ((overlappingEvents?.length || 0) + 1);
  const width = 100;

  const backgroundColor = isPast
    ? Color(event.color).lighten(0.22).string()
    : Color(event.color).lighten(0.08).string();

  const textColor = isPast
    ? Color(event.color).darken(0.3).desaturate(0.4).string()
    : Color(event.color).darken(0.8).desaturate(0.2).string();

  if (event.all_day) {
    return (
      <div
        className={`w-full p-1 rounded-sm`}
        onClick={handleClick}
        style={{
          backgroundColor,
        }}
      >
        <div className="w-full h-full truncate text-4xs leading-tight font-medium break-all">
          {event.title}
        </div>
      </div>
    );
  } else {
    return (
      <button
        className={`absolute z-20 w-full right-0 pt-1 px-1 pb-2 rounded-sm ${
          !active ? "pointer-events-none" : ""
        }`}
        onClick={handleClick}
        style={{
          width: `calc(${width}% - 1px)`,
          top: `${startPosition}px`,
          height: `${length * CELL_HEIGHT - 2}px`,
          backgroundColor,
          color: textColor,
        }}
      >
        <div className="w-full h-full overflow-hidden text-ellipsis text-left text-4xs leading-tight font-medium break-all">
          {event.title}
        </div>
      </button>
    );
  }
}
