import React, { useContext } from "react";
import ConversionLinkMessage from "../../messages/ConversionLinkMessage";
import { AutomationContext } from "../../../contexts/automation-context";

export default function ConversionLinkAction(props) {
  const { action } = props;

  const { conversionLinks } = useContext(AutomationContext);

  const conversionLink = conversionLinks.find(
    (link) => link.id === action.options?.conversion_link_id,
  );

  return (
    <ConversionLinkMessage
      message={{ conversion_link: conversionLink }}
      preview
    />
  );
}
