import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "~/contexts/user-context";
import {
  EnvelopeIcon,
  CurrencyEuroIcon,
  CalendarIcon,
} from "@heroicons/react/24/outline";
import formatAmount from "../../utils/formatAmount";
import { ContactsContext } from "~/contexts/contacts-context";
import { DateTime } from "luxon";

export default function ContactInsights(props) {
  const { t } = useTranslation();

  const { contact } = props;

  const { organization } = useContext(UserContext);
  const { loadContactInsights } = useContext(ContactsContext);

  const [contactInsights, setContactInsights] = useState(null);

  const insights = contactInsights
    ? [
        [
          t(
            contact.is_follower
              ? "inbox.details.engagement_follow"
              : "inbox.details.engagement_no_follow",
            { name: contact.organizationname },
          ),
        ],
        [
          {
            icon: <EnvelopeIcon className="w-4" />,
            text: t("inbox.details.engagement_dm", {
              count: contactInsights.dm,
            }),
          },
          `🔥 ${t("inbox.details.engagement_story", {
            count: contactInsights.story,
          })}`,
        ],
        [
          {
            icon: <CurrencyEuroIcon className="w-4" />,
            text: t("inbox.details.engagement_payments", {
              count: contactInsights.payments,
            }),
          },
          `${t("shared.total")}: ${formatAmount(
            contactInsights.total_paid,
            organization.currency,
          )}`,
        ],
        [
          {
            icon: <CalendarIcon className="w-4" />,
            text: t("inbox.details.engagement_appointments", {
              count: contactInsights.appointments,
            }),
          },
          contactInsights.last_appointment
            ? t("inbox.details.engagement_last_appointment", {
                date: DateTime.fromISO(
                  contactInsights.last_appointment,
                ).toLocaleString(DateTime.DATE_FULL),
              })
            : null,
        ],
      ]
    : [];

  useEffect(() => {
    if (contact.id && !contactInsights)
      loadContactInsights(contact.id).then(setContactInsights);
  });

  return (
    <div className="p-4 border-b">
      <div className="text-md mb-4">{t("inbox.details.engagement")}</div>

      <div className="text-xs text-dark-gray mb-4">
        {t("inbox.details.engagement_date", {
          date: DateTime.fromISO(
            organization.instagram_linked_at,
          ).toLocaleString(DateTime.DATE_FULL),
        })}
      </div>

      <div className="flex flex-col space-y-4 text-sm">
        {insights.map((line, i) => (
          <div className="flex justify-between" key={i}>
            {line.map((item, j) =>
              item?.icon ? (
                <div className="flex space-x-1.5 items-center" key={j}>
                  {item.icon}
                  <div>{item.text}</div>
                </div>
              ) : (
                <div key={j}>{item}</div>
              ),
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
