import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../contexts/user-context";
import { ArrowPathIcon } from "@heroicons/react/20/solid";
import Button from "../elements/Button";

export default function FacebookError() {
  const { t } = useTranslation();

  const { organization } = useContext(UserContext);

  return (
    <div className="flex flex-col space-y-4">
      <div className="font-medium">
        {t("inbox.permissions.facebook_error_title")}
      </div>
      <p className="text-sm">{t("inbox.permissions.facebook_error_text")}</p>
      {organization.facebook_error_message?.length > 0 && (
        <div className="self-stretch bg-neutral-100 px-3 py-2 rounded text-2sm text-left text-neutral-600">
          {organization.facebook_error_message}
        </div>
      )}
      <Button
        href="/users/auth/instagram"
        label={t("inbox.permissions.reauthorise")}
        icon={ArrowPathIcon}
      />
    </div>
  );
}
