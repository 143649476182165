import React, { useContext, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Checkbox from "../../shared/Checkbox";
import InputCheckbox from "../../elements/InputCheckbox";
import Button from "../../elements/Button";
import FacebookIcon from "../../shared/icons/FacebookIcon";
import { PlayIcon } from "@heroicons/react/20/solid";
import ConnectInstagramChecklist from "./connect_instagram/ConnectInstagramChecklist";
import { UserContext } from "../../../contexts/user-context";
import NoPage from "../../errors/NoPage";
import ManyPages from "../../errors/ManyPages";
import NoInstagram from "../../errors/NoInstagram";
import ConnectInstagramHelp from "./connect_instagram/ConnectInstagramHelp";

export default function ConnectInstagram() {
  const { t } = useTranslation();

  const { organization } = useContext(UserContext);

  const connectInstagramSection = () => {
    if (organization.facebook_error_code == "onboarding") {
      switch (organization.facebook_error_message) {
        case "No page selected":
          return <NoPage />;
        case "Multiple pages selected":
          return <ManyPages />;
        case "No Instagram account":
          return <NoInstagram />;
        default:
          return <ConnectInstagramChecklist />;
      }
    } else {
      return <ConnectInstagramChecklist />;
    }
  };

  return (
    <div className="max-w-[912px] mx-auto space-y-8">
      <div className="space-y-4">
        <div className="font-black text-3xl sm:text-5xl text-headings whitespace-pre-line">
          {t("onboarding.connect_instagram.title")}
        </div>
        <div className="leading-6 text-muted">
          {t("onboarding.connect_instagram.description")}
        </div>
      </div>
      <div className="rounded-2xl border shadow-sm bg-white grid grid-cols-2">
        <div className="p-8">{connectInstagramSection()}</div>
        <ConnectInstagramHelp />
      </div>
    </div>
  );
}
