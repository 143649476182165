import React, { useContext, useEffect } from "react";
import { UIContext } from "~/contexts/ui-context";
import reactNativeMessage from "../../../utils/reactNativeMessage";
import Button from "../../elements/Button";
import { useTranslation } from "react-i18next";
import { CogIcon } from "@heroicons/react/20/solid";
import { isApp } from "../../../utils/environment";

export default function PermissionCheck() {
  const { t } = useTranslation();

  const { checkNotifications, notificationsGranted } = useContext(UIContext);

  useEffect(checkNotifications, []);

  const openAppSettings = () => {
    reactNativeMessage({ settings: true });
  };

  if (notificationsGranted) return null;

  if (!isApp) return null;

  return (
    <div className="px-4 mb-4">
      <div className="bg-light-primary rounded-2xl p-4">
        <div className="text-2sm font-semibold mb-1">
          {t("notifications.permissions_missing_title")}
        </div>
        <div className="text-2sm mb-2">
          {t("notifications.permissions_missing_text")}
        </div>
        <Button
          className="w-full"
          label={t("notifications.permissions_missing_action")}
          icon={<CogIcon className="w-4 mr-1" />}
          onClick={openAppSettings}
        />
      </div>
    </div>
  );
}
