import React from "react";
import Button from "./Button";
import {
  CheckCircleIcon,
  DocumentDuplicateIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";
import Badge from "./Badge";
import { capitalize } from "lodash";
import ButtonWithDropdown from "./ButtonWithDropdown";
import ButtonGroup from "./ButtonGroup";
import Input from "./Input";

export default function ElementsPage() {
  return (
    <div className="p-4 space-y-4">
      <h1 className="text-2xl font-semibold">Elements</h1>

      <h2 className="text-xl font-semibold">Buttons</h2>
      <div className="flex items-center space-x-2">
        <Button label="Default Extra-small" size="extra-small" />
        <Button label="Default Small" size="small" />
        <Button label="Default Base" size="base" />
        <Button label="Default Primary" size="base" style="primary" />
      </div>
      <div className="flex items-center space-x-2">
        <Button label="Disabled" disabled />
        <Button label="Primary" style="primary" />
        <Button label="Transparent" style="transparent" />
        <Button label="With icon" icon={CheckCircleIcon} />
        <Button label="Loading" loading />
        <Button label="Loading primary" loading style="primary" />
        <Button label="Loading small" loading size="small" />
        <Button label="Loading on click" loadingOnClick style="primary" />
      </div>
      <div className="flex items-center space-x-2">
        <ButtonWithDropdown
          label="Button + dropdown"
          actions={[
            {
              label: "Action 1",
            },
            {
              label: "Action 2",
            },
          ]}
        />
        <ButtonWithDropdown
          label="Primary style"
          style="primary"
          actions={[
            {
              label: "Action 1",
            },
            {
              label: "Action 2",
            },
          ]}
        />
        <ButtonWithDropdown
          label="With icons"
          icon={PencilIcon}
          actions={[
            {
              label: "Action 1",
              icon: DocumentDuplicateIcon,
            },
            {
              label: "Action 2",
              icon: TrashIcon,
            },
          ]}
        />
      </div>

      <ButtonGroup
        buttons={[
          {
            label: "Button 1",
            icon: PencilIcon,
          },
          {
            label: "Button 2",
          },
          {
            label: "Button 3",
            style: "primary",
          },
        ]}
      />

      <h2 className="text-xl font-semibold">Inputs</h2>
      <div className="flex items-center space-x-2">
        <Input placeholder="Default" />
      </div>

      <h2 className="text-xl font-semibold">Badges</h2>
      <div className="flex items-center space-x-2">
        {["green", "blue", "gray", "yellow", "red"].map((color) => (
          <Badge key={color} color={color} label={capitalize(color)} />
        ))}
      </div>
      <div className="flex items-center space-x-2">
        {["green", "blue", "gray", "yellow", "red"].map((color) => (
          <Badge key={color} color={color} label={capitalize(color)} rounded />
        ))}
      </div>
    </div>
  );
}
