import React, { useContext, useEffect, useState } from "react";
import axios from "axios";

import { ContentContext } from "~/contexts/content-context";
import { UserContext } from "~/contexts/user-context";

export default function ContentProvider(props) {
  const [loading, setLoading] = useState(true);

  const { organization } = useContext(UserContext);

  // Medias

  const [medias, setMedias] = useState([]);

  const loadMedias = async () => {
    setLoading(true);
    const res = await axios.get("/api/content/medias");
    setMedias(res.data);
    setLoading(false);
    return res.data;
  };

  useEffect(loadMedias, [organization.id]);

  const refreshMedias = () => {
    setLoading(true);
    axios.get("/api/content/medias/refresh").then((res) => {
      setMedias(res.data);
      setLoading(false);
    });
  };

  // Stories

  const [stories, setStories] = useState([]);

  const loadStories = () => {
    setLoading(true);
    axios.get("/api/content/stories").then((res) => {
      setStories(res.data);
      setLoading(false);
    });
  };

  const mediaValues = {
    loading,
    medias,
    loadMedias,
    refreshMedias,
    stories,
    loadStories,
  };

  return (
    <ContentContext.Provider value={mediaValues}>
      {props.children}
    </ContentContext.Provider>
  );
}
