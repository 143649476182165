import { Link } from "react-router-dom";
import ContactPicture from "../shared/ContactPicture";
import {
  ArrowTopRightOnSquareIcon,
  Bars3BottomLeftIcon,
} from "@heroicons/react/20/solid";
import ContactFolders from "../inbox/folders/ContactFolders";
import Badge from "../elements/Badge";
import ContactFollowerGrowth from "./ContactFollowerGrowth";
import InlineTooltip from "../shared/InlineTooltip";
import RelativeDate from "../utils/RelativeDate";
import formatMessageText from "../../utils/formatMessageText";
import { useTranslation } from "react-i18next";

export default function contactsTableColumns(handleSort) {
  const { t } = useTranslation();

  return [
    {
      label: t("contacts.columns.username"),
      sort_name: "username",
      className: "!pl-0",
      accessor: (contact) => (
        <div className="flex items-center font-medium text-black">
          <ContactPicture
            contact={contact}
            className="w-6 h-6 mr-2"
            linkToProfile
          />
          {contact.username}
        </div>
      ),
      sort: handleSort("username"),
    },
    {
      label: t("contacts.columns.name"),
      sort_name: "name",
      accessor: (contact) => contact.real_name || contact.name,
      sort: handleSort("name"),
    },
    {
      label: t("contacts.columns.email"),
      sort_name: "email",
      accessor: (contact) => contact.email,
      sort: handleSort("email"),
    },
    {
      label: t("contacts.columns.phone"),
      sort_name: "phone",
      accessor: (contact) => contact.phone,
      sort: handleSort("phone"),
    },
    {
      label: t("contacts.columns.folders"),
      sort_name: "folders",
      accessor: (contact) => <ContactFolders folderIds={contact.folder_ids} />,
      sort: handleSort("folders"),
    },
    {
      label: t("contacts.columns.follower"),
      accessor: (contact) =>
        contact.is_follower ? (
          <Badge color="green" label={"Follower"} className="inline-block" />
        ) : null,
    },
    {
      label: t("contacts.columns.language"),
      accessor: (contact) =>
        contact.language ? t("languages." + contact.language) : null,
    },
    {
      label: t("contacts.columns.follower_count"),
      accessor: (contact) => (
        <div className="font-medium">{contact.follower_count}</div>
      ),
      sort_name: "follower_count",
      sort: handleSort("follower_count"),
    },
    {
      label: t("contacts.columns.weekly_growth"),
      accessor: (contact) => (
        <ContactFollowerGrowth value={contact.follower_growth} />
      ),
    },
    {
      label: t("contacts.columns.bio"),
      accessor: (contact) =>
        contact.bio ? (
          <InlineTooltip align="right" text={formatMessageText(contact.bio)}>
            <div className="p-2 rounded bg-lighter-gray">
              <Bars3BottomLeftIcon className="w-4" />
            </div>
          </InlineTooltip>
        ) : null,
    },
    {
      label: t("contacts.columns.last_message"),
      sort_name: "last_message_at",
      className: "text-right",
      accessor: (contact) => <RelativeDate date={contact.last_message_at} />,
      sort: handleSort("last_message_at"),
    },
  ];
}
