import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import TablePagination from "./TablePagination";
import Loader from "../../utils/Loader";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";

const perPageOptions = [10, 25, 50, 100];

export default function Table(props) {
  const {
    loading,
    columns = [],
    items = [],
    emptyState,
    isSelected = () => false,
    onRowClick = () => {},
    onRowRender = () => {},
    children,
    isSortable,
    defaultPerPage = 50,
  } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const [perPage, setPerPage] = useState(
    parseInt(searchParams.get("per_page")) || defaultPerPage,
  );

  const [currentPage, setCurrentPage] = useState(
    parseInt(searchParams.get("page")) || 1,
  );

  const paginatedItems = items.slice(
    (currentPage - 1) * perPage,
    currentPage * perPage,
  );

  const scrollRef = useRef();

  // Scroll to top on page change
  useEffect(() => {
    scrollRef.current.scrollTo(0, 0);
  }, [currentPage]);

  // Update url search params
  useEffect(() => {
    let params = {
      page: currentPage,
      per_page: perPage,
    };
    setSearchParams(params);
  }, [currentPage, perPage]);

  return (
    <div className="w-full flex-grow overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
      <div className="h-full flex flex-col overflow-hidden divide-y divide-gray-300">
        <div
          className="flex-grow w-full overflow-x-auto relative"
          ref={scrollRef}
        >
          <table className="min-w-full divide-y divide-gray-200 bg-white">
            <TableHeader columns={columns} isSortable={isSortable} />
            <tbody className={`divide-y divide-gray-200 bg-white `}>
              {paginatedItems.map((item) => (
                <TableRow
                  key={item.id}
                  item={item}
                  columns={columns}
                  selected={isSelected(item)}
                  onRender={onRowRender}
                  onClick={onRowClick}
                />
              ))}
            </tbody>
          </table>
          {paginatedItems.length === 0 && emptyState}
          {loading && (
            <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50">
              <Loader stroke="#8D8D8D" width={24} strokeWidth={8} />
            </div>
          )}
          {children}
        </div>
        <TablePagination
          {...{
            currentPage,
            setCurrentPage,
            perPage,
            setPerPage: (perPage) => {
              setPerPage(perPage);
              setCurrentPage(1);
            },
            perPageOptions,
            total: items.length,
          }}
        />
      </div>
    </div>
  );
}
