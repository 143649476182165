import React from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import classNames from "../../utils/classNames";
import { loaderColors, buttonLoaderSizes } from "./Button";
import Dropdown from "./Dropdown";
import Loader from "../utils/Loader";

export default function ButtonWithDropdown(props) {
  const {
    label,
    onClick,
    disabled = false,
    icon: Icon = null,
    actions = [],
    style = "default",
    size = "base",
    loading = false,
    direction = "bottom",
  } = props;

  return (
    <div className={"inline-flex items-center"}>
      <button
        type="button"
        className={classNames(
          "button",
          `button-${size}`,
          `button-${style}`,
          actions.length > 0 && "!rounded-r-none",
          Icon || loading ? "inline-flex items-center justify-center" : null,
        )}
        onClick={onClick}
        disabled={disabled}
      >
        {loading && (
          <Loader
            stroke={loaderColors[style]}
            width={buttonLoaderSizes[size]}
            strokeWidth={8}
          />
        )}
        {!loading && Icon && (
          <Icon className="button-icon" aria-hidden="true" />
        )}
        {label}
      </button>
      {actions.length > 0 && (
        <Dropdown
          actions={actions}
          direction={direction}
          wrapperClassName="-ml-px block"
          buttonClassName={classNames(
            "button relative !rounded-l-none !px-1.5 focus:z-10 text-opacity-50",
            `button-${style}`,
            `button-${size}`,
            style == "primary" && "border-l border-l-white/20",
          )}
        >
          <ChevronDownIcon className="button-icon" aria-hidden="true" />
        </Dropdown>
      )}
    </div>
  );
}
